import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {  of } from 'rxjs';
import { Coupon, PaymentService } from '../../../../api/client';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'sebu-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CouponComponent),
    multi: true
  }]
})
export class CouponComponent implements OnInit, ControlValueAccessor {
  public couponForm: UntypedFormGroup;
  public couponApplied = false;

  private onChangeCallback: (coupon: Coupon) => void;

  constructor(private readonly paymentService: PaymentService) {
  }

  public ngOnInit(): void {
    this.couponForm = new UntypedFormGroup({couponName: new UntypedFormControl('')});
  }

  public applyCoupon(): void {
    if (!this.couponForm.controls.couponName.value) {
      this.couponForm.controls.couponName.setErrors({ invalidCoupon: true });
      return;
    }
    // Send the coupon to the backend for validation
    this.paymentService.validateCoupon(this.couponForm.controls.couponName.value.toString().trimEnd()).pipe(
        take(1),
      // eslint-disable-next-line
        catchError((value) => {
          return of(null);
        }))
      .subscribe(coupon => {
        if (coupon && coupon.isValid) {
          this.couponForm.controls.couponName.setErrors({});
          this.couponForm.controls.couponName.disable();
          this.couponApplied = true;

          if (this.onChangeCallback) {
            this.onChangeCallback(coupon);
          }
        } else {
          this.couponForm.controls.couponName.setErrors({ invalidCoupon: true });
        }
      });
  }

  public registerOnChange(fn: (coupon: Coupon) => void): void {
    this.onChangeCallback = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched(fn: (coupon: Coupon) => void): void {
  }

  // eslint-disable-next-line
  public setDisabledState(isDisabled: boolean): void {
  }

  public writeValue(coupon: Coupon): void {
    if (!coupon) {
      this.couponForm.controls.couponName.setValue(undefined);
      if (this.onChangeCallback) {
        this.onChangeCallback(undefined);
        this.couponForm.controls.couponName.enable();
        this.couponApplied = false;
      }
    }
  }
}
