  <div class="overview-wrapper content"
       *ngIf="(formModels$ | async) as formModels">
    <ng-container *ngIf="formModels.product && formModels.company && formModels.offerOrder">
      <sebu-status-page-product-component [productModel]="formModels.product"></sebu-status-page-product-component>

      <sebu-company-summary [isStepEditable]="false"
                            [companyData]="formModels.company"></sebu-company-summary>

      <sebu-offer-summary [isStepEditable]="false"
                          [offerOrderData]="formModels.offerOrder"
                          [productControlConfiguration]="formModels.product.productConfiguration?.formConfiguration"></sebu-offer-summary>
      <sebu-html-template-summary
        [previewUrl]="previewUrl$ | async"
        [previewIsLoading]="(uiStateWizard$ | async).previewLoadingSpinner.isLoading"
        [isStepEditable]="false"
        *ngIf="templatedProducts.includes(formModels.product.productEnum)">
      </sebu-html-template-summary>
      <div class="action-buttons" *ngIf="(allowedActions$ | async) as actions">
        <button *ngIf="actions.includes(ORDER_ACTIONS.Delete)" mat-raised-button color="warn" (click)="showDeleteDialog()">{{ 'actionButtonDelete' | TenantTextPipe }}
        </button>
        <button *ngIf="actions.includes(ORDER_ACTIONS.Deactivate)" mat-raised-button color="primary" (click)="showDeactivateDialog()">{{ 'actionButtonDeactivate' | TenantTextPipe }}
        </button>
        <button *ngIf="actions.includes(ORDER_ACTIONS.Activate)" mat-raised-button color="primary" (click)="activateOfferOrder()">{{ 'actionButtonActivate' | TenantTextPipe }}
        </button>
      </div>
    </ng-container>
  </div>


