import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  SebuBookedFeaturePrices,
  SebuOfferOrderCreate,
  SebuProduct,
  SebuResellerData
} from '../../../api/client';
import { ProductConfiguration } from '../../../wizard/models/product-configuration/product-configuration';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { RefreshDatesHelper } from '../../../shared/helper/refresh-dates-helper';
import { CheckoutData } from '../checkout/checkout.model';
import { CouponDiscountHelper } from '../../../wizard/components/summary/coupon/coupon-discount.helper';

@Component({
  selector: 'sebu-confirmation-product-summary',
  templateUrl: './confirmation-page-product.component.html',
  styleUrls: ['./confirmation-page-product.component.scss']
})
export class ConfirmationProductComponent implements OnChanges, OnInit {
  @Input()
  public productConfiguration: ProductConfiguration;

  @Input()
  public productName: string;

  @Input()
  public offerOrder: SebuOfferOrderCreate;

  @Input()
  public sebuResellerData: SebuResellerData;

  @Input()
  public checkoutData: CheckoutData;

  public publicationDate: Date;
  public showAsteriscPriceHint: boolean;
  public amountOfRefreshes: number;
  public priceSum: number;
  public vatAmount: number;
  public hasResellerRefreshEnabledForProduct: boolean;
  public hasResellerTableEntryForProduct: boolean;
  public paymentEnabled: boolean;
  public discount?: number;

  ngOnInit(): void {
    // do not show asterisc Hint if VAT is defined, else use tenant settings.
    this.showAsteriscPriceHint = (!this.offerOrder.bookedFeaturePrices.sebuVatRate) && TenantSettingsHelper.getSettings().showAsteriscPriceHint;
    this.amountOfRefreshes = this.offerOrder && (this.offerOrder.refreshOption == null) ? 0 : this.calculateAmountOfRefreshes();
    this.paymentEnabled = this.sebuResellerData && this.sebuResellerData.paymentEnabled;
    this.calculateProductSpecificFeaturesEnabled();
    this.calculateSum();
    this.calculateDiscountAndUpdateSum();
    this.calculateVatAmount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offerOrder && changes.offerOrder.currentValue) {
      this.publicationDate = new Date(changes.offerOrder.currentValue.publicationDate);
    }
    if (changes.offerOrder || changes.sebuResellerData) {
      this.calculateProductSpecificFeaturesEnabled();
    }
  }

  private calculateAmountOfRefreshes(): number {
    return RefreshDatesHelper.calculatePeriodicRefreshDates(
      this.offerOrder.refreshOption, new Date(), this.offerOrder.duration).length;
  }


  private calculateProductSpecificFeaturesEnabled(): void {
    const chosenProduct: SebuProduct = this.sebuResellerData &&
      this.sebuResellerData.products &&
      this.sebuResellerData.products.find(p => p.productId === this.offerOrder.product);

    // to compare for null or undefined we use single "="
    this.hasResellerRefreshEnabledForProduct = chosenProduct && chosenProduct.refreshDatePrice != null;
    this.hasResellerTableEntryForProduct = chosenProduct && chosenProduct.isTableEntryAllowed;
  }

  private calculateSum(): void {
    const prices: SebuBookedFeaturePrices = this.offerOrder.bookedFeaturePrices;

    this.priceSum = prices.sebuProductPrice
      + (prices.sebuTopJobPrice || 0)
      + (prices.sebuSocialMediaPrice || 0)
      + (prices.sebuTableEntryPrice || 0);
    if (this.offerOrder.refreshOption) {
      this.priceSum += this.amountOfRefreshes * prices.sebuRefreshDatePrice;
    }
  }

  private calculateDiscountAndUpdateSum(): void {
    if (!this.paymentEnabled) {
      return;
    }
    const prices: SebuBookedFeaturePrices = this.offerOrder.bookedFeaturePrices;

    this.discount = CouponDiscountHelper.calculateDiscount(prices.couponAmount, prices.couponPercent, this.priceSum);
    this.priceSum = this.priceSum - (this.discount || 0);
  }

  private calculateVatAmount(): void {
    const prices: SebuBookedFeaturePrices = this.offerOrder.bookedFeaturePrices;

    if (!prices.sebuVatRate) {
      return;
    }
    this.vatAmount = Number((this.priceSum * prices.sebuVatRate).toFixed(2));
  }
}
