import { NgModule } from '@angular/core';
import { TinyEditorComponent } from './tiny-editor.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    QuillModule
  ],
  declarations: [TinyEditorComponent],
  exports: [TinyEditorComponent],
})
export class TinyEditorModule {
}
