import { ProductEnum } from '../../api/client';

export class Product {
  productEnum?: ProductEnum;
  productName?: string;

  constructor(value: ProductEnum, name: string) {
    this.productEnum = value || undefined;
    this.productName = name || '';
  }
}
