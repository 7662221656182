<form [formGroup]="locationForm">
  <div fxLayout="column" fxLayoutGap="1em">
    <div fxLayout="column" fxLayoutGap="1.3em">
      <label>{{'location' | TenantTextPipe}}</label>
      <mat-radio-group  formControlName="type" class="full-width-input" fxLayoutGap="1em">
        <mat-radio-button  *ngFor="let type of locationTypes" [value]="type">{{type}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="showCountryList()"
         fxLayout="row">
      <mat-form-field class="full-width-input">
        <mat-select formControlName="country"
                    placeholder="{{'locationCountry' | TenantTextPipe}}" required>
          <mat-option *ngFor="let country of (countries$ |async)" [value]="country">{{country.valueToDisplay}}</mat-option>
        </mat-select>
        <mat-error sebuTenantText>required</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="showFederalStateList()"
         fxLayout="row">
      <mat-form-field class="full-width-input">
        <mat-select formControlName="state"
                    placeholder="{{'locationState' | TenantTextPipe}}"
                    [compareWith]= compare  required>
          <mat-option class="full-width" *ngFor="let state of (federalStates$ |async)" [value]="state">{{state.valueToDisplay}}</mat-option>
        </mat-select>

        <mat-error sebuTenantText>required</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="showCitySearch()">
      <mat-form-field class="full-width-input">
        <input matInput
               formControlName="city"
               placeholder="{{'locationCity' | TenantTextPipe}}"
               [matAutocomplete]="cityAutocomplete"
               required />
        <mat-error sebuTenantText>required</mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-autocomplete #cityAutocomplete="matAutocomplete" [displayWith]="displayCity" (optionSelected)="handleBlurCity()" (closed)="handleBlurCity()">
    <mat-option  *ngFor="let cityOption of resolvedCities" [value]="cityOption">
      {{cityOption.valueToDisplay}}
    </mat-option>
  </mat-autocomplete>
</form>
