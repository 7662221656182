import { Directive, ElementRef, OnInit } from '@angular/core';
import { TenantTextPipe } from './tenant-text.pipe';

@Directive(
  {
    selector: '[sebuTenantText]'
  }
)
export class TenantTextDirective implements OnInit {
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {
    this.elementRef.nativeElement.textContent = TenantTextPipe.transform(this.elementRef.nativeElement.textContent);
  }

}
