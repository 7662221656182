import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';

export  class TextareaControlConfiguration extends ControlConfiguration {

  constructor(options: ControlConfigurationArgs) {
    super(ControlType.TEXTAREA, options);
  }
}

