<form [formGroup]="formGroup" *ngIf="formGroup && priceModel?.refreshPrice != null">
  <mat-radio-group formControlName="selectedOption" class="full-width-input">
    <div class="refresh-option" *ngFor="let option of availableOptions; let i = index">
      <label class="cost-name" fxLayoutGap="0.5em">
        <span class="title">{{option.name | TenantTextPipe}}</span>
        <sebu-info-tooltip *ngIf="displayRefreshTooltips"
                           [content]="option.tooltipText | TenantTextPipe"></sebu-info-tooltip>
      </label>
      <mat-radio-button class="price" #radioButton [value]="option.value" labelPosition="before"
                        (click)="uncheckWhenChecked(radioButton.checked)">
        <div>
          {{(priceModel?.refreshPrice | currency:'EUR')?.concat(!!showAsteriscPriceHint ? option.asteriscPriceHint : '')}}
        </div>
      </mat-radio-button>
    </div>
  </mat-radio-group>
</form>

<div *ngIf="availableOptions.length > 0 && (priceModel?.tableEntryPrice != null || priceModel?.topJobPrice != null)">
  <hr/>
</div>
