import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { WizardState } from './wizard-state.model';
import { WizardFormModel } from '../../models/wizard-form-models/wizard-form.model';
import {
  getAvailableProductEntities,
  getFormEntities,
  getPreviewUrlEntity,
  getProductConfigurationEntity, getSelectedProductEntity,
  getUiStateEntities
} from './reducer';
import { AvailableProduct } from '../../models/available-product.model';
import { UiStateModel } from '../../models/ui-state.model';
import { ProductConfiguration } from '../../models/product-configuration/product-configuration';
import { ProductEnum } from '../../../api/client';

// feature selector
export const getWizardFeatureState: MemoizedSelector<Object, WizardState> = createFeatureSelector<WizardState>('wizard');

export const getProductConfigurationSelector: MemoizedSelector<WizardState, ProductConfiguration> = createSelector(getWizardFeatureState, getProductConfigurationEntity);

export const getSelectedProductSelector: MemoizedSelector<WizardState, ProductEnum> = createSelector(getWizardFeatureState, getSelectedProductEntity);

export const getFormModelSelector: MemoizedSelector<WizardState, WizardFormModel> = createSelector(getWizardFeatureState, getFormEntities);

export const getAvailableProducts: MemoizedSelector<WizardState, AvailableProduct[]> = createSelector(getWizardFeatureState, getAvailableProductEntities);

export const getUiStateSelector: MemoizedSelector<WizardState, UiStateModel>
  = createSelector(getWizardFeatureState, getUiStateEntities);

export const getPreviewUrlSelector: MemoizedSelector<WizardState, string>
  = createSelector(getWizardFeatureState, getPreviewUrlEntity);

