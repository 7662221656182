import { Injectable } from '@angular/core';
import { CategoryData, ServicesService } from '../../../api/client';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoriesCachingService {
  private categories$: Observable<CategoryData[]>;

  constructor(private servicesApi: ServicesService) {
  }

  public getCategories(): Observable<CategoryData[]> {
    if (!this.categories$) {
      this.categories$ = this.servicesApi.categories().pipe(shareReplay(1));
    }

    return this.categories$;
  }
}
