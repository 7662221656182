/**
 * This helper class is used to reduce the complexity when dealing with Typescript enums
 */
export class EnumHelper {

  /**
   * Retrieves an array of strings with all the names of the values contained in the provided enum. For example:
   * {EnumHelper.getAllNames(OfferOrderSalesTypeEnum)} will return {['Unbekannt', 'Verkauft', 'Kostenlos']}
   * @param enumeration The enum you want to extract the names from.
   * @returns {string[]}
   */
  public static getAllNames(enumeration: Object): string[] {
    const objValues: Object[] = Object.keys(enumeration).map(k => enumeration[k]);
    const names: string[] = objValues.filter(v => typeof v === 'string') as string[];
    return names;
  }

  /**
   * Retrieves an array of strings with all the names of the values provided in the {enumValues} array when they are
   * contained in the provided enum. For example:
   * {EnumHelper.getNames(StatusEnum, [StatusEnum.Offen, StatusEnum.Geschaltet])} will return {['Offen', 'Geschaltet']}
   * @param enumValues The array of values from which you want to extract the names
   * @param enumeration The enum you want to extract the names from.
   * @returns {string[]}
   */
  public static getNames(enumValues: number[], enumeration: Object): string[] {
    const names: string[] = enumValues.map<string>(k => EnumHelper.getName(k, enumeration));
    return names;
  }

  /**
   * Retrieves a string with the name of the value provided in {enumValue} when it is contained in the provided enum.
   * For example:
   * {EnumHelper.getName(StatusEnum, StatusEnum.Offen)} will return {'Offen'}
   * @param enumValue The enum value from which you want to extract the name
   * @param enumeration The enum you want to extract the name from.
   * @returns {string}
   */
  public static getName(enumValue: number, enumeration: Object): string {
    return enumeration[enumValue] as string;
  }

  /**
   * Retrieves the value of an enum given its name as string. For example:
   * {let openState: StatusEnum = EnumHelper.getValue<StatusEnum>('Offen', StatusEnum)} will return {StatusEnum.Offen}
   * @param valueName The name of the value you want to get
   * @param enumeration The enum you want to extract the value from.
   * @returns {T}
   */
  public static getValue<T>(valueName: string, enumeration: Object): T {
    return enumeration[valueName] as T;
  }

  /**
   * Returns an array containing all possible values of the given enum. For example:
   * {let enumArray: OfferOrderSalesTypeEnum[] = EnumHelper.getAllValues<OfferOrderSalesTypeEnum>(OfferOrderSalesTypeEnum)}
   * will return {[OfferOrderSalesTypeEnum.Unbekannt, OfferOrderSalesTypeEnum.Verkauft, OfferOrderSalesTypeEnum.Kostenlos]}
   * @param enumeration enumeration The enum you want to extract the values from.
   * @returns {T[]}
   */
  public static getAllValues<T>(enumeration: Object): T[] {
    const values: T[] = EnumHelper.getAllNames(enumeration).map<T>(n => enumeration[n]);
    return values;
  }

  /**
   * Returns the value of the enum specified in FinalEnum which has the same name that the originalValue provided
   * has inside the originalEnum
   * @param {number} originalValue
   * @param {Object} originalEnum
   * @param {Object} finalEnum
   * @returns {FinalEnum}
   */
  public static castValue<FinalEnum>(originalValue: number, originalEnum: Object, finalEnum: Object): FinalEnum {
    return EnumHelper.getValue<FinalEnum>(EnumHelper.getName(originalValue, originalEnum), finalEnum);
  }
}
