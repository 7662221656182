import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { CategoryDisplayComponent } from './components/category-display/category-display.component';
import { CategoryInputComponent } from './components/category-input/category-input.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LocationInputComponent } from './components/location-input/location-input.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageCropperModule } from '../shared/image-cropper/image-cropper.module';
import { TinyEditorModule } from './components/tiny-editor/tiny-editor.module';
import { RefreshOptionInputComponent } from './components/refresh-option-input/refresh-option-input.component';
@NgModule({
  imports: [
    ImageCropperModule,
    SharedModule,
    ReactiveFormsModule,
    ColorPickerModule,
    UiComponentsModule,
    TinyEditorModule
  ],
  declarations: [
    FileUploadComponent,
    LocationInputComponent,
    CategoryInputComponent,
    CategoryDisplayComponent,
    ImageUploadComponent,
    ColorPickerComponent,
    RefreshOptionInputComponent
  ],
    exports: [
        FileUploadComponent,
        ReactiveFormsModule,
        LocationInputComponent,
        CategoryInputComponent,
        CategoryDisplayComponent,
        ImageUploadComponent,
        ColorPickerComponent,
        TinyEditorModule,
        RefreshOptionInputComponent
    ]
})

export class SebuFormComponentsModule { }


