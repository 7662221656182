import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { SebuFormComponentsModule } from '../form-components/sebu-form-components.module';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StatusPageEffects } from './ngrx/effects';
import { statusPageReducer } from './ngrx/reducer/reducer';
import { StatusPageComponent } from './components/status-page.component';
import { StatusPageProductComponent } from './components/product/status-page-product.component';
import { ProductSelectionModule } from '../wizard/components/product-selection/product-selection.module';
import { SummaryModule } from '../wizard/components/summary/summary.module';
import { DeactivateComponent } from './components/deactivate/deactivate.component';

@NgModule({
    imports: [
        SebuFormComponentsModule,
        SharedModule,
        CoreModule,
        ProductSelectionModule,
        SummaryModule,
        StoreModule.forFeature('statusPage', statusPageReducer),
        EffectsModule.forFeature([StatusPageEffects])
    ],
    declarations: [
        StatusPageComponent,
        StatusPageProductComponent,
        DeactivateComponent
    ],
    exports: [
        StatusPageComponent
    ]
})

export class StatusPageModule {

}
