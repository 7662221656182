import { NgModule, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoggerInterceptor } from './http-logger.interceptor';

const interceptorProviders: Provider[] = [
  // Example { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];


interceptorProviders.push({ provide: HTTP_INTERCEPTORS, useClass: HttpLoggerInterceptor, multi: true });


@NgModule({
  providers: interceptorProviders
})
export class HttpInterceptorsModule {}
