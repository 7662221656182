/**
 * Util class used to transform an object into an array where the keys of its properties are the indexes of the array
 */

export class ArrayHelper {

  /**
   * Returns an associative array with string indexes which are the names of the provided object's properties amd its
   * values on the corresponding index.
   * NOTE: The properties with string index of an associative array are not accessible by regular array functions such
   * as forEach, filer or map and doesn't show the length through Array.length property,
   * use Object.keys(associativeArray).length instead
   * @param object to be converted to an associative array
   * @returns {{}[]}
   */
  public static toAssociativeArray(object: Object): {}[] {
    const values: {}[] = [];
    for (const key in object) {
      // eslint-disable-next-line
      if (object.hasOwnProperty(key)) { // To avoid iterating over prototyped properties
        const value: {} = object[key];
        values[key] = value;
      }
    }
    return values;
  }

  /**
   * Returns an array of key/value pairs corresponding to the properties of the provided object
   * @param object
   * @returns {{key: string, value: []}[]}
   */
  public static toKeyValueArray(object: Object): KeyValuePair[] {
    const values: { key: string, value: {} }[] = [];
    for (const key in object) {
      // eslint-disable-next-line
      if (object.hasOwnProperty(key)) { // To avoid iterating over prototyped properties
        const arrayEntry: { key: string, value: {} } = { key: key, value: object[key] };
        values.push(arrayEntry);
      }
    }
    return values;
  }

  public static filterUnique (element: Object, index: number, arr: Object[]): boolean {
    return arr.indexOf(element) === index;
  }

  /**
   * Returns a sorter function that sort the elements according to a model provided array. If any of the comparison
   * elements isn't in the provided array, will be sorted as less than the other if it is present (-1).
   * @param {T[]} orderedModel
   * @returns {(a: T, b: T) => number}
   */
  public static sorterByCustomOrder<T>(orderedModel: T[]): (a: T, b: T) => number {
    return (a: T, b: T) => orderedModel.indexOf(a) - orderedModel.indexOf(b);
  }
}

export class KeyValuePair {
  key: string;
  value: Object;
}
