import { PayloadAction } from '../../../core/models/payload-action.model';
import { ProductMapperHelper } from '../../../core/utils/mapping-helper/product-mapper.helper';
import { AvailableProduct } from '../../models/available-product.model';
import { WizardFormModel } from '../../models/wizard-form-models/wizard-form.model';
import * as Actions from '../actions';
import { OfferOrderWizardInitialState } from './initial-states';
import { WizardState } from './wizard-state.model';
import { ProductEnum } from '../../../api/client';
import { UiStateModel } from '../../models/ui-state.model';
import { ProductConfiguration } from '../../models/product-configuration/product-configuration';

export function wizardReducer(state: WizardState = OfferOrderWizardInitialState, action: PayloadAction): WizardState {

  switch (action.type) {

    case Actions.SAVE_PRODUCT_STEP_ON_SUMMARY_PAGE:
      return {
        ...state,
        formModel: {
          ...state.formModel,
          product: action.payload
        }
      };

    /*eslint-disable no-case-declarations*/
    case Actions.LOAD_PRODUCTS_AND_ADDONS_SUCCESS:
      const result = action.payload;
      const products = ProductMapperHelper.mapResellerDataToAvailableProducts(result);
      return {
        ...state,
        availableProducts: products
      };

    case Actions.SAVE_ORDER_FORM_CONFIGURATION:
      return {
        ...state,
        orderFormConfiguration: action.payload
      };

    case Actions.SAVE_ALL_STEPS:
      return {
        ...state,
        formModel: action.payload
      };

    case Actions.FETCH_PREVIEW:
      return {
        ...state,
        uiState: { ...state.uiState, previewLoadingSpinner: { isLoading: true } }
      };

    case Actions.FETCH_PREVIEW_SUCCESS:
      return {
        ...state,
        uiState: { ...state.uiState, previewLoadingSpinner: { isLoading: false } },
        previewUrl: action.payload
      };

    default:
      return state;
  }
}

export const getFormEntities: (_: WizardState) => WizardFormModel = (state: WizardState) => state.formModel;
export const getProductConfigurationEntity: (_: WizardState) => ProductConfiguration = (state: WizardState) => state.orderFormConfiguration;
export const getSelectedProductEntity: (_: WizardState) => ProductEnum = (state: WizardState) => state.orderFormConfiguration ? state.orderFormConfiguration.productEnum : null;
export const getAvailableProductEntities: (_: WizardState) => AvailableProduct[] = (state: WizardState) => state.availableProducts;
export const getUiStateEntities: (_: WizardState) => UiStateModel = (state: WizardState) => state.uiState;
export const getPreviewUrlEntity: (_: WizardState) => string = (state: WizardState) => state.previewUrl;
