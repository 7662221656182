import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { merge, Observable, Subject } from 'rxjs';
import { CategoryData, ProductEnum, Subcategory } from '../../../api/client';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { CategoryHelper } from '../../helper/category.helper';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { TenantTextHelper } from '../../../tenant-texts/tenant-text.helper';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { getSelectedProductSelector } from '../../../wizard/ngrx/reducer/selectors';
import { WizardState } from '../../../wizard/ngrx/reducer/wizard-state.model';
import { CategoriesCachingService } from '../../../core/services/categories-caching-service/categories-caching.service';

@Component({
  selector: 'sebu-category-input',
  templateUrl: 'category-input.component.html',
  styleUrls: ['category-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryInputComponent),
      multi: true
    }]
})

export class CategoryInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public categories$: Observable<CategoryData[]>;
  public maxLength = 5;
  public categoryForm: UntypedFormGroup;
  public selectedSubCategories: Subcategory[] = [];
  public subcategoryOptions$: Observable<Subcategory[]>;
  public displayCategoryInfo: boolean;

  private componentDestroyed$: Subject<void> = new Subject<void>();
  private valueChanged$: Subject<Subcategory[]> = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
              private readonly storeWizard: Store<WizardState>, private categoriesCachingService: CategoriesCachingService) {
  }

  ngOnInit(): void {
    this.displayCategoryInfo = TenantSettingsHelper.getSettings().tooltips.categories
      && TenantTextHelper.hasValue('categories');

    this.categoryForm = this.formBuilder.group({
      'selectedCategory': [''],
      'selectedSubCategory': [{ value: '', disabled: true }]
    });

    this.establishStreams();
  }

  public writeValue(obj: Subcategory[]): void {
    if (obj instanceof Array) {
      this.selectedSubCategories = obj;
      if (this.categoriesOpenToAdd() === 0) {
        this.categoryForm.get('selectedCategory').disable();
      }
    } else {
      this.selectedSubCategories = [];
    }
  }

  public setFormDisabled(): void {
    this.categoryForm.get('selectedCategory').disable();
    this.categoryForm.get('selectedSubCategory').disable();
  }

  public setFormEnabled(): void {
    this.categoryForm.get('selectedCategory').enable();
    this.categoryForm.get('selectedSubCategory').enable();
  }

  public registerOnChange(fn: (_: Subcategory[]) => {}): void {
    this.valueChanged$.pipe(takeUntil(this.componentDestroyed$)).subscribe(fn);
  }

  // eslint-disable-next-line
  public registerOnTouched(): void {
  }

  public removeSubCategoryAction(subCategory: Subcategory): void {
    this.removeSubCategory(subCategory);
  }

  public getSubcategoryPlaceholder(): string {
    return this.categoryForm.get('selectedSubCategory').enabled
      ? TenantTextPipe.transform('subCategoriesPlaceholder') as string
      : this.categoriesOpenToAdd()
        ? TenantTextPipe.transform('firstChooseCategoryPlaceholder') as string
        : TenantTextPipe.transform('maxAmountReachedPlaceholder') as string;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  private establishStreams(): void {
    const selectedProductObservable: Observable<ProductEnum> = this.storeWizard.select(getSelectedProductSelector);
    let selectedProduct: ProductEnum = null;
    selectedProductObservable.subscribe(prod => selectedProduct = prod);

    this.categories$ = this.categoriesCachingService.getCategories();
    if (selectedProduct === ProductEnum.RegioOnlineOnlyAzubi) {
      this.categories$ = this.categories$.pipe(map(cat => cat.filter((c => c.isTrainee))));
    }

    const selectedCategory$: Observable<CategoryData> = this.categoryForm.get('selectedCategory').valueChanges;

    // When a category is (de)selected, the subcategory input should be enabled/disabled
    selectedCategory$.pipe(takeUntil(this.componentDestroyed$)).subscribe((cat) => cat
      ? this.categoryForm.get('selectedSubCategory').enable()
      : this.categoryForm.get('selectedSubCategory').disable());

    // Reload the subcategory list when the category has changed or when any subcategory has been selected or deselected
    // We build an intermediate array with the selected category and the selected subcategories for both cases
    this.subcategoryOptions$ = merge(
      // Category changed, we get the new Category from the changes and the subcategories from the stored value
      selectedCategory$.pipe(map((category) => [category, this.selectedSubCategories])),

      // Subcategory selected or deleted, we get the categoryId from the stored value if any and the subcategories from the changes
      this.valueChanged$.pipe(map((subcategories) => [this.categoryForm.get('selectedCategory').value, subcategories]))
    )

    // We now get a fresh category with filtered subcategories and use them to populate the options
      .pipe(
        switchMap(value => {
          if (value[0] && value[1]) {
            const category: CategoryData = value[0];
            const selectedSubCategories: Subcategory[] = value[1];
            return CategoryHelper.getCategoryById(this.categories$, category.id, selectedSubCategories.map((sc) => sc.categoryGroupId));
          }
          return undefined;
        }),
        map((category: CategoryData) => category ? category.subcategories : [])
      );

    const subCategoryChanges$: Observable<Subcategory> = this.categoryForm.get('selectedSubCategory').valueChanges;
    subCategoryChanges$.pipe(filter((sc: Subcategory) => !!sc))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((sc: Subcategory) => {
        if (this.categoriesOpenToAdd()) {
          this.addSubCategory(sc);
        }
      });

    // When the maximum number of subcategories have been reached, both inputs should be disabled
    this.valueChanged$.pipe(takeUntil(this.componentDestroyed$)).subscribe((newValue: Subcategory[]) => {
      newValue.length < this.maxLength ?
        this.setFormEnabled() : this.setFormDisabled();
    });
  }

  private categoriesOpenToAdd(): number {
    return this.maxLength - this.selectedSubCategories.length;
  }

  private addSubCategory(subCategory: Subcategory): void {
    this.selectedSubCategories.push(subCategory);
    this.categoryForm.get('selectedSubCategory').reset();
    this.valueChanged$.next(this.selectedSubCategories);
  }

  private removeSubCategory(subCategory: Subcategory): void {
    this.selectedSubCategories.splice(this.selectedSubCategories.indexOf(subCategory), 1);
    this.valueChanged$.next(this.selectedSubCategories);
  }
}
