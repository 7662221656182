import { NgModule } from '@angular/core';
import { TenantTextDirective } from './tenant-text.directive';
import { TenantTextPipe } from './tenant-text.pipe';

@NgModule({
  declarations: [
    TenantTextDirective,
    TenantTextPipe
  ],
  exports: [
    TenantTextDirective,
    TenantTextPipe
  ]
})
export class TenantTextsModule {
}
