import { FileExtension } from '../../wizard/models/file-extension';
import { EnumHelper } from './enum.helper';

export class FileExtensionHelper {

  public static ANY: FileExtension[] = [
    FileExtension.ZIP, FileExtension.JPG, FileExtension.JPEG, FileExtension.GIF, FileExtension.TIF, FileExtension.PDF, FileExtension.BMP, FileExtension.CSV, FileExtension.RAR,
    FileExtension.DOCX, FileExtension.ODS, FileExtension.ODT, FileExtension.DOC, FileExtension.DOT, FileExtension.TXT, FileExtension.XLS, FileExtension.XLSX, FileExtension.EPS,
    FileExtension.PS, FileExtension.TAR, FileExtension.PSD, FileExtension.PNG, FileExtension.SVG
  ];

  public static RESTRICTED: FileExtension[] = [
     FileExtension.JPG, FileExtension.JPEG, FileExtension.PNG, FileExtension.GIF, FileExtension.PDF, FileExtension.DOCX, FileExtension.ODT, FileExtension.ZIP
  ];

  public static BITMAP: FileExtension[] = [
    FileExtension.JPG, FileExtension.JPEG, FileExtension.GIF, FileExtension.BMP, FileExtension.PNG];

  public static includesFileType(allowedFileTypes: FileExtension[], fileName: string): boolean {
    const fileType: FileExtension = FileExtensionHelper.getFileType(fileName);
    return allowedFileTypes.includes(fileType);
  }

  public static getFileType(fileName: string): FileExtension {
    const splitFileName: string[] = fileName.split('.');
    const extension: string = splitFileName[splitFileName.length - 1].toUpperCase();
    return EnumHelper.getValue(extension, FileExtension);
  }

  public static getFileTypesAsString(fileTypes: FileExtension[]): string {
    const extensions: string [] = [];
    fileTypes.forEach(type => extensions.push('.' + EnumHelper.getName(type, FileExtension).toLowerCase()));
    return fileTypes ? extensions.join(',  ') : '';
  }

  public static getFileTypesAsStringList(fileTypes: FileExtension[]): string[] {
    const extensions: string [] = [];
    fileTypes.forEach(type => extensions.push('.' + EnumHelper.getName(type, FileExtension).toLowerCase()));
    return extensions;
  }
}
