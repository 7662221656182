import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { TenantSettingsHelper } from '../tenant-settings/tenant-settings.helper';

@Component({
  selector: 'sebu-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit {
  public showHomeButton: boolean;
  public thankYouOverSvg: boolean = TenantSettingsHelper.getSettings().showThankYouOverSvg;
  public customMailIconName?: string;
  public customWeekendIconName?: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.showHomeButton = TenantSettingsHelper.getSettings().showHomeButtonOnThankYouPage;
    this.customMailIconName = TenantSettingsHelper.getSettings().customMailIconName;
    this.customWeekendIconName = TenantSettingsHelper.getSettings().customWeekendIconName;
  }

  public getSVG(pictureName: string): string {
    return environment.pathToSVGs + pictureName;
  }

  public goToStartPage(): void {
    this.router.navigate(['']);
  }
}
