import { AvailableProduct } from '../available-product.model';
import { AvailableProductDuration } from '../available-product-duration.model';
import { AddonType, AvailableAddon } from '../available-addon.model';

export class OrderPrice {
  public durationPrice: number;
  public topJobPrice: number;
  public tableEntryPrice: number;
  public refreshPrice: number;
  public socialMediaPrice: number;
  public vatRate: number;

  public static createFromProductConfig(product: AvailableProduct, selectedDuration: string): OrderPrice {
    const duration: AvailableProductDuration = product.availableDurations.find(d => d.value.duration === selectedDuration);
    const tableEntryAddon: AvailableAddon = product.availableProductAddons.find(addon => addon.type === AddonType.TableEntry);
    const topJobAddon: AvailableAddon = product.availableProductAddons.find(addon => addon.type === AddonType.TopJob);
    const refreshAddon: AvailableAddon = product.availableProductAddons.find(addon => addon.type === AddonType.Refresh);
    const socialMediaAddon: AvailableAddon = product.availableProductAddons.find(addon => addon.type === AddonType.SocialMedia);
    const vatRate: number = product.vatRate;

    return {
      durationPrice: duration && duration.price,
      tableEntryPrice: tableEntryAddon ? tableEntryAddon.price : undefined,
      topJobPrice: topJobAddon ? topJobAddon.price : undefined,
      refreshPrice: refreshAddon ? refreshAddon.price : undefined,
      socialMediaPrice: socialMediaAddon ? socialMediaAddon.price : undefined,
      vatRate: vatRate
    };
  }
}
