<sebu-header-with-button [showEditButton]="false">{{ "summaryProductAndOptionsTitle" | TenantTextPipe }}
</sebu-header-with-button>
<div class="product-information">

  <div class="cost-wrapper">
    <div fxLayout="row" fxLayoutAlign="space-between" class="row">
      <h4>{{productModel.productName}}</h4>
      <h4 class="price">{{productModel.bookedFeaturePrices?.sebuProductPrice | currency:'EUR' }} <span *ngIf="showAsteriscPriceHint">*</span>
      </h4>
    </div>
    <hr class="divider"/>
    <div fxLayout="row" class="row">
      <div sebuTenantText fxFlex>offerOrderNumber</div>
      <div class="price" fxFlex>{{productModel.offerOrderNumber}}</div>
    </div>
    <div fxLayout="row" class="row">
      <div sebuTenantText fxFlex>status</div>
      <div class="price" fxFlex>{{productModel.status}}</div>
    </div>
    <div fxLayout="row" class="row" *ngIf="productModel.paymentMethod">
      <div sebuTenantText fxFlex>statusPaymentMethod</div>
      <div class="price" fxFlex>{{'statusPaymentMethod' + productModel.paymentMethod | TenantTextPipe}}</div>
    </div>
    <div fxLayout="row" class="row" *ngIf="productModel.paymentStatus">
      <div sebuTenantText fxFlex>paymentStatus</div>
      <div class="price" fxFlex>{{'paymentStatus' + productModel.paymentStatus | TenantTextPipe}}</div>
    </div>
    <div fxLayout="row" class="row">
      <div sebuTenantText fxFlex>duration</div>
      <div class="price" fxFlex>{{productModel.duration || 'P0D' | DurationIsoPipe}}</div>
    </div>
    <div fxLayout="row" class="row">
      <div sebuTenantText fxFlex>publicationDate</div>
      <div class="price" fxFlex>{{publicationDate | date: 'dd.MM.yyyy'}}</div>
    </div>
    <div *ngIf="productModel.features.topJob" fxLayout="row" class="row top-job-feature">
      <div sebuTenantText fxFlex="50">topJob</div>
      <div sebuTenantText fxFlex>isBooked</div>
      <div class="price" fxFlex
           fxLayoutAlign="end">{{(productModel.bookedFeaturePrices.sebuTopJobPrice || '0') | currency: 'EUR'}}
        <span *ngIf="showAsteriscPriceHint">&nbsp;*</span></div>
    </div>
    <div *ngIf="productModel.features.socialMedia" fxLayout="row" class="row top-job-feature">
      <div sebuTenantText fxFlex="50">socialMedia</div>
      <div sebuTenantText fxFlex>isBooked</div>
      <div class="price" fxFlex
           fxLayoutAlign="end">{{(productModel.bookedFeaturePrices.sebuSocialMediaPrice || '0') | currency: 'EUR'}}
        <span *ngIf="showAsteriscPriceHint">&nbsp;*</span></div>
    </div>
    <div *ngIf="productModel.features.tableEntry" fxLayout="row" class="row table-entry-feature">
      <div sebuTenantText fxFlex="50">tableEntry</div>
      <div sebuTenantText fxFlex>isBooked</div>
      <div class="price" fxFlex
           fxLayoutAlign="end">{{(productModel.bookedFeaturePrices.sebuTableEntryPrice || '0') | currency: 'EUR'}}
        <span *ngIf="showAsteriscPriceHint">&nbsp;*</span></div>
    </div>
    <div *ngIf="productModel.features.refreshOption" fxLayout="row" class="row refresh-option-feature">
      <div fxFlex="50">{{('refresh' | TenantTextPipe) + ' x '+  amountOfRefreshes}}</div>
      <div sebuTenantText fxFlex>isBooked</div>
      <div class="refresh-option" fxFlex fxLayout="column">
        <div class="price" fxFlex fxLayoutAlign="end">
          {{((productModel.bookedFeaturePrices.sebuRefreshDatePrice * amountOfRefreshes) || '0') |currency:'EUR'}}
          <span *ngIf="showAsteriscPriceHint">&nbsp;*</span>
        </div>
      </div>
    </div>
    <div *ngIf="discount" fxLayout="row" class="row discount-display">
      <div sebuTenantText fxFlex="50">appliedDiscount</div>
      <div class="price" fxFlex
           fxLayoutAlign="end">- {{discount | currency: 'EUR'}}
      </div>
    </div>
    <hr/>
    <div *ngIf="vatRate" fxLayout="row" fxLayoutAlign="space-between" class="row">
      <h4 sebuTenantText fxFlex>summaryPriceSum</h4>
      <h4 class="price">{{priceSum |currency:'EUR'}}</h4>
    </div>
    <div *ngIf="vatRate" fxLayout="row" >
      <div fxFlex>{{'summaryVAT' | TenantTextPipe }} ({{vatRate | percent:'1.0-2'}})</div>
      <div class="price" fxFlex fxLayoutAlign="end">{{vatPrice | currency:'EUR'}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="row">
      <h4 sebuTenantText>summaryTotalPrice</h4>
      <h4 class="price">{{totalPrice || '0' | currency: 'EUR'}} <span *ngIf="showAsteriscPriceHint">*</span></h4>
    </div>
    <div *ngIf="showAsteriscPriceHint" fxLayout="row" class="row">
      <div sebuTenantText>summaryPriceDisclaimer</div>
    </div>
  </div>
</div>
