import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import Quill from "quill";
import { Subject } from 'rxjs/internal/Subject';
import { EDITOR_STYLES } from './editor-styles';

@Component({
  selector: 'sebu-tiny-editor',
  templateUrl: 'tiny-editor.component.html',
  styleUrls: ['tiny-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TinyEditorComponent),
      multi: true
    }
  ]
})
export class TinyEditorComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() initialValue: string;
  @Input() elementId: string;
  @Input() height: string;
  @Input() showToolbar: boolean;
  @Input() inputType: string;

  public formControl: UntypedFormControl;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  config = {}

  constructor() {
    this.formControl = new UntypedFormControl();
  }

  public ngAfterViewInit(): void {
    if (this.inputType == 'text'){
      this.writeValue(this.initialValue); //text input type has a prefilled value
    }
  }

  public ngOnInit(): void {
    //set QuillJS Configuration to use in HTML Style
    this.setInlineMode()
    //Disable toolbar for text input type and no-formatting
    if (this.inputType !== 'text' && this.inputType !== 'no-formatting'){
      this.config = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote'],
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                         // remove formatting button
          ['link']                         // link and image, video
        ]
      }
    }
    else {
      this.config = {
        "toolbar": false
      };
    }

    this.formControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => { if (this.getOutputValue() != this.initialValue || this.inputType != 'text') {
        return this.propagateChange(this.getOutputValue());
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public registerOnChange(fn: (htmlString: string) => void): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched(fn: () => void): void {
  }

  public writeValue(text: string): void {
    this.formControl.setValue(text);
  }

  // eslint-disable-next-line
  public setDisabledState(isDisabled: boolean): void {
  }

  public getOutputValue(): any {
    //replace Unicode Character making problems publishing offers
    const output: string = this.formControl.value.replace('\ufeff', '');
    this.formControl.value.nodeValue

    //For Headlines (like Company Name) no formatting should be made
    if (this.inputType == 'no-formatting' || this.inputType == 'text')
    {
      return output.replace(/(<([^>]+)>)/gi, "")
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
    }
    else if(this.formControl.value != ''){
      return EDITOR_STYLES + output;
    }
    else {
      return output;
    }
  }

  // eslint-disable-next-line
  private propagateChange: (htmlString: string) => void = (value) => {
  }

  private setInlineMode(): void {
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
  }
}
