import {
  Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';
import { ProductFormModel } from '../../../models/wizard-form-models/product-form.model';
import { SummaryProductModel } from '../../../models/wizard-form-models/summary-product.model';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { OrderPrice } from '../../../models/wizard-form-models/order-price.model';
import { TenantSettingsHelper } from '../../../../tenant-settings/tenant-settings.helper';
import { map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { RefreshDatesHelper } from '../../../../shared/helper/refresh-dates-helper';
import { OfferOrderRefreshOptionEnum, SebuResellerData } from '../../../../api/client';
import { ResellerCachingService } from '../../../../core/services/reseller-caching-service/reseller-caching.service';
import { CouponDiscountHelper } from '../coupon/coupon-discount.helper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'sebu-product-summary',
  templateUrl: 'product-summary.component.html',
  styleUrls: ['product-summary.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductSummaryComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ProductSummaryComponent,
      multi: true
    }]
})
export class ProductSummaryComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Output()
  public edit: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public priceModel: OrderPrice;

  @Input()
  public productPageModel: ProductFormModel;

  public summaryProduct: UntypedFormGroup;
  public addOnPrice$: Observable<number>;
  public totalPriceWithoutDiscount$: Observable<number>;
  public discountAmount$: Observable<number>;
  public totalPrice$: Observable<number>;
  public vatPrice$: Observable<number>;
  public totalWithVatPrice$: Observable<number>;

  public productPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public topJobPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public socialMediaPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public tableEntryPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public refreshPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public hasVat$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sebuResellerData$: Observable<SebuResellerData>;
  public minDate: Date;
  public displayTopJobTooltip: boolean;
  public displaySocialMediaTooltip: boolean;
  public displayTableEntryTooltip: boolean;
  public showAsteriscPriceHint: boolean;
  public displayRefreshPriceDisclaimer: boolean;
  public paymentEnabled: boolean;
  public hasAddonActivated: boolean;
  public addonSelected: boolean;

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private elementReference: ElementRef,
              private resellerCachingService: ResellerCachingService) {
    this.summaryProduct = this.formBuilder.group(new SummaryProductModel());
    this.minDate = this.summaryProduct.getRawValue().publicationDate;
  }

  public propagateChange = (_: SummaryProductModel) => {
    this.displayTopJobTooltip = TenantSettingsHelper.getSettings().tooltips.topJob;
    this.displaySocialMediaTooltip = TenantSettingsHelper.getSettings().tooltips.socialMedia;
    this.displayTableEntryTooltip = TenantSettingsHelper.getSettings().tooltips.tableEntry;
  }

  public ngOnInit(): void {
    this.showAsteriscPriceHint = TenantSettingsHelper.getSettings().showAsteriscPriceHint;
    const summaryProductFormChanges$: Observable<SummaryProductModel> = this.summaryProduct.valueChanges;

    summaryProductFormChanges$.pipe(takeUntil(this.componentDestroyed$)).subscribe((changes: SummaryProductModel) => {
      // need to add publication date here, as value change won't get values of disabled fields
      const modelToPropagate: SummaryProductModel = { ...this.summaryProduct.getRawValue(), ...changes };
      this.propagateChange(modelToPropagate);

    });

    this.addOnPrice$ = combineLatest([summaryProductFormChanges$, this.topJobPrice$, this.tableEntryPrice$, this.refreshPrice$, this.socialMediaPrice$])
      .pipe(
        map(([changes, topJobPrice, tableEntryPrice, refreshPrice, socialMeidiaPrice]:
               [SummaryProductModel, number, number, number, number]) => {
          const refreshDates: Date[] = RefreshDatesHelper.calculatePeriodicRefreshDates(changes.refreshOption, new Date(), this.productPageModel.duration);
          return (changes.tableEntry ? tableEntryPrice : 0) +
            (changes.topJob ? topJobPrice : 0) +
            (changes.socialMedia ? socialMeidiaPrice : 0) +
            (changes.refreshOption ? refreshDates.length * refreshPrice : 0);
        }),
        shareReplay(1),
        startWith(0)
      );

    this.totalPriceWithoutDiscount$ = combineLatest([this.addOnPrice$, this.productPrice$])
      .pipe(map(([addOn, product]: [number, number]) => addOn + product));

    this.discountAmount$ = combineLatest([summaryProductFormChanges$, this.totalPriceWithoutDiscount$]).pipe(
        map(([formChanges, totalPrice]: [SummaryProductModel, number]) =>
        CouponDiscountHelper.calculateDiscountFromCoupon(formChanges.coupon, totalPrice) || 0),
        shareReplay(1), // late subscriber from inside of ngIf
        startWith(0)); // starting value, this field is only calculated after changes are done.

    this.totalPrice$ = combineLatest([this.totalPriceWithoutDiscount$, this.discountAmount$])
      .pipe(map(([priceWithoutDiscount, discount]: [number, number]) => priceWithoutDiscount - discount));
    this.refreshPrices(this.priceModel, this.productPageModel);

    this.vatPrice$ = combineLatest([this.hasVat$, this.totalPrice$])
      .pipe(
        map(([hasVat, totalPrice]: [boolean, number]) =>
          hasVat ? Number((totalPrice * this.priceModel.vatRate * 100).toFixed(2)) / 100 : 0));

    this.totalWithVatPrice$ = combineLatest(
      [this.totalPrice$, this.vatPrice$]).pipe(map(([total, vat]: [number, number]) => total + vat));

    this.sebuResellerData$ = this.resellerCachingService.getReseller();
    this.sebuResellerData$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(reseller => {
        this.paymentEnabled = reseller.paymentEnabled;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes.productPageModel && changes.productPageModel.currentValue) {
      this.refreshPrices(this.priceModel, changes.productPageModel.currentValue);
    }
    if (changes.priceModel && changes.priceModel.currentValue) {
      this.refreshPrices(changes.priceModel.currentValue, this.productPageModel);
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public writeValue(summaryProductModel: SummaryProductModel): void {
    if (summaryProductModel) {
      this.summaryProduct.setValue(summaryProductModel);
    } else {
      this.summaryProduct.reset();
    }
  }

  public registerOnChange(fn: (_: SummaryProductModel) => {}): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched(fn: (_: SummaryProductModel) => {}): void {
  }

  public clickEdit(): void {
    this.edit.emit();
  }

  /**
   * If the user chooses a date via the material date picker the click event is not propagated to the parent component,
   * so we need to re-trigger it in order to get the card selected
   */
  public propagateDateChanged(): void {
    this.elementReference.nativeElement.click();
  }

  /**
   * Material Datepicker does not use the controlValueAccessor interface until now.
   * It is important to use the getRawValue here as the datepicker is set to disabled and therefore getValue
   * won't deliver publication date
   * */
  // eslint-disable-next-line
  public setDate(event: MatDatepickerInputEvent<Date>): void {
    this.propagateChange(this.summaryProduct.getRawValue());
  }

  // eslint-disable-next-line
  public validate(control: AbstractControl): ValidationErrors | null {
    return this.summaryProduct.invalid ? { refreshDates: false } : null;
  }

  public clearDiscount(): void {
    this.summaryProduct.controls.coupon.setValue(undefined);
  }

  private refreshPrices(priceModel: OrderPrice, productPageModel: ProductFormModel): void {
    this.productPrice$.next(productPageModel.duration && productPageModel.productEnum ? priceModel.durationPrice : 0);
    this.topJobPrice$.next(priceModel.topJobPrice || 0);
    this.socialMediaPrice$.next(priceModel.socialMediaPrice || 0);
    this.tableEntryPrice$.next(priceModel.tableEntryPrice || 0);
    this.refreshPrice$.next(priceModel.refreshPrice || 0);
    this.hasVat$.next(!!priceModel.vatRate);
    this.displayRefreshPriceDisclaimer = this.displayRefreshDisclaimer(priceModel.refreshPrice);
    this.hasAddonActivated = this.productPageModel.duration && this.priceModel.refreshPrice != null
      || this.priceModel.topJobPrice != null || this.priceModel.socialMediaPrice != null || this.priceModel.tableEntryPrice != null;
  }

  private displayRefreshDisclaimer(refreshPrice?: number): boolean {
    // Check if Refresh is enabled for reseller and if multiple Refreshs are contained in option
    return this.productPageModel.duration && (refreshPrice !== undefined) && RefreshDatesHelper.calculatePeriodicRefreshDates(
      OfferOrderRefreshOptionEnum.Alle30Tage, new Date(), this.productPageModel.duration).length > 1;
  }

}
