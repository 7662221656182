import { ITexts } from './ITexts';

export const defaultTexts: ITexts = {

  sebuHeader: 'Buchen Sie jetzt Ihre Anzeige!',
  sebuDomain: undefined, // Please set this value to fill in placeholders

  sebuHeaderLogoLink: '../unternehmen', // overwirte value if differnt. Overwrite with empty string if no link.

  support: `<h3>Kontakt und Buchung</h3>
  <p>
    Unser Support bei {domain}:
  </p>
  <div class="tooltip-support">
    <p>
      <i class="material-icons">email</i>
      Per E-Mail: <a href="mailto://default@test.de">default@test.de</a>
    </p>
    <p>
      <i class="material-icons">phone</i>
      Telefonisch: 030 / 222 278 60
    </p>
  </div>`,

  supportLabel: 'HILFE',

  product_selection: 'Produkt',
  company_details: 'Unternehmen',
  offer_order_details: 'Anzeigendetails',
  htmlTemplateDetails: 'Stellenbeschreibung',
  summary: 'Überprüfung',
  payment: 'Zahlungsart',
  finalize: 'Abschluss',
  buttonNext: 'Weiter',
  buttonBack: 'Zurück',
  buttonCheck: 'Bestellung prüfen',
  buttonToPaymentMethods: 'Weiter zur Zahlungsart',
  buttonSend: 'Bestellung abschicken',
  buttonOrder: 'Bestellung abschließen',
  buttonProductInfo: 'Jetzt über die Produkte informieren',
  buttonGoToStartPage: 'Zurück zur Startseite',
  colorPickerInfo: 'Wählen Sie hier optional eine Farbe. Diese wird in der Anzeige für individuelle Gestaltungsmerkmale verwendet.',

  product: 'Produkt',
  product_type: 'Typ',
  product_variant: 'Default Variante',

  /* Product Cards */
  priceDisclaimer: '* Der Preis bezieht sich auf eine Position je Stellenanzeige (zzgl. MwSt.)',

  /* You can use {domain} as placeholder for the domain name (specified in this config) */

  premiumOnlineOnlyProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  premiumOnlineOnlyProductProductCardPlaceholder2: 'Ihr individuelles Anzeigenlayout',
  premiumOnlineOnlyProductProductCardPlaceholder3: 'Zusätzliche Veröffentlichung auf stellenanzeigen.de und Partnerwebsites',
  premiumOnlineOnlyProductProductCardPlaceholder4: '',

  premiumOnlineOnlyAzubiProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  premiumOnlineOnlyAzubiProductProductCardPlaceholder2: 'Ihr individuelles Anzeigenlayout',
  premiumOnlineOnlyAzubiProductProductCardPlaceholder3: 'Zusätzliche Veröffentlichung auf stellenanzeigen.de und Partnerwebsites',
  premiumOnlineOnlyAzubiProductProductCardPlaceholder4: '',

  portalOnlineOnlyProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  portalOnlineOnlyProductProductCardPlaceholder2: 'Ihr individuelles Anzeigenlayout',
  portalOnlineOnlyProductProductCardPlaceholder3: 'Wir erstellen Ihre professionelle Anzeige',
  portalOnlineOnlyProductProductCardPlaceholder4: '',

  regioOnlineOnlyProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  regioOnlineOnlyProductProductCardPlaceholder2: 'Darstellung in mobil optimiertem Template ',
  regioOnlineOnlyProductProductCardPlaceholder3: 'Heute erstellen, morgen online',
  regioOnlineOnlyProductProductCardPlaceholder4: '',

  regioOnlineOnlyAzubiProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  regioOnlineOnlyAzubiProductProductCardPlaceholder2: 'Darstellung in mobil optimiertem Template ',
  regioOnlineOnlyAzubiProductProductCardPlaceholder3: 'Heute erstellen, morgen online',
  regioOnlineOnlyAzubiProductProductCardPlaceholder4: '',

  portalOnlineOnlyAzubiProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  portalOnlineOnlyAzubiProductProductCardPlaceholder2: 'Ihr individuelles Anzeigenlayout',
  portalOnlineOnlyAzubiProductProductCardPlaceholder3: 'Wir erstellen Ihre professionelle Anzeige',
  portalOnlineOnlyAzubiProductProductCardPlaceholder4: '',

  regioOnlineOnlyMinijobsProductProductCardPlaceholder1: 'Ihre Anzeige auf {domain}',
  regioOnlineOnlyMinijobsProductProductCardPlaceholder2: 'Darstellung in mobil optimiertem Template ',
  regioOnlineOnlyMinijobsProductProductCardPlaceholder3: 'Heute erstellen, morgen online',
  regioOnlineOnlyMinijobsProductProductCardPlaceholder4: '',

  regioOnlineOnlyMinijobsDescriptionWhatYouGetSubText: [],

  topJob: 'Top Job',
  topJobInfo: `
  <p>
    Eine zusätzliche Platzierung Ihrer Anzeige als Top-Job auf {domain} bietet Ihnen die Möglichkeit,
    die Klicks auf die Stellenanzeige sowie den Rücklauf der Bewerbungen potenziell zu erhöhen.</p>
  <p>
    Alle Vorteile auf einen Blick:
  </p>
  <ul>
    <li>Anzeige Ihres Unternehmens inkl. Firmenlogo auf der Startseite von {domain}</li>
    <li>Erhöhte Sichtbarkeit Ihrer Anzeigen. Platzierung Ihrer Anzeigen unter den Top-3 Ergebnissen auf der jeweils relevanten Ergebnisliste.</li>
    <li>Erhöhte Wahrnehmung und Aufmerksamkeit Ihrer Arbeitgebermarke</li>
    <li>Prominentere Darstellung im Vergleich zu anderen Stellenanzeigen</li>
  </ul>`,

  socialMedia: 'Social Media',
  socialMediaInfo: `
  <p>
  Steigern Sie die Reichweite Ihrer Stellenanzeige durch eine Social Media Anzeige und erreichen Sie 
  eine zusätzliche Zielgruppe – passive Kandidaten. Passive Kandidaten sind Arbeitnehmer, die zwar nicht aktiv 
  auf der Suche nach einer neuen Stelle sind, jedoch durchaus bereit wären, den Arbeitsplatz zu wechseln. Diese potenziellen Bewerber erreichen Sie über eine Social Media Anzeige. Wir spielen Ihre Stellenanzeige auf diversen
   Social Media Plattformen (z. Bsp. Facebook und Instagram) aus und erhöhen so Ihre Reichweite.
  </p>
  `,

  tableEntry: 'Tabelleneintrag',
  tableEntryInfo: `
  <p>
  Eine zusätzliche Platzierung Ihrer Anzeige als Tabelleneintrag bietet Ihnen die Möglichkeit,
  die Klicks auf die Stellenanzeige sowie den Rücklauf der Bewerbungen potenziell zu erhöhen.</p>
  <p>
    Alle Vorteile auf einen Blick:
  </p>
  <ul>
    <li>Teaser-Zeile Ihrer Anzeige in der Print-Ausgabe</li>
    <li>Die Teaser-Zeile ist mit einer Position, dem Unternehmen, Einsatzort und einer Online-Job-ID versehen</li>
    <li>Er erhöht zum einen die Reichweite Ihres digitalen Stellenangebotes, zum anderen wird dieses speziell hervorgehoben</li>
  </ul>`,

  refresh: 'Refresh',
  refreshOption15DaysTitle: 'Refresh nach 15 Tagen',
  refreshOption30DaysTitle: 'Refresh alle 30 Tage (nach 15 Tagen)',
  refreshOption15DaysTooltip: `
  <p>
    Alle Anzeigen mit einer höheren Laufzeit als 30 Tage erhalten immer nach dem 30. Tag automatisch eine kostenfreie Datumsaktualisierung (Refresh).
    Das Datum Ihres Jobangebots wird innerhalb der gebuchten Laufzeit immer nach 15 Tagen aktualisiert.
    Damit rutscht Ihre Stellenanzeige durch eine Datumsaktualisierung (Refresh) wieder in der Ergebnisliste nach oben und Sie erhalten noch mehr Sichtbarkeit.
  </p>
  <p>
    Beispiel:<br>
    Bei der Buchung „Refresh alle 30 Tage“ bei einer Anzeige mit Laufzeit von 60 Tagen wird die Anzeige am 15. Tag und am 45. Tag aktualisiert.
    Bei dieser Buchung würden zweimal die Kosten je Refresh anfallen.
    Sie haben die Auswahl den Refresh einmalig zu buchen oder immer nach dem 15. Tag über die gesamte Laufzeit (entsprechend oft wird der Refresh wiederholt).
  </p>
  <p>
    Ihre Vorteile:
  </p>
  <ul>
    <li>Datumsaktualisierung Ihrer Stellenanzeige</li>
    <li>Verbesserte Platzierung in den Ergebnislisten</li>
    <li>Erhöhte Wahrnehmung Ihres Angebots</li>
  </ul>`,

  refreshOption30DaysTooltip: `
  <p>
    Alle Anzeigen mit einer höheren Laufzeit als 30 Tage erhalten immer nach dem 30. Tag automatisch eine kostenfreie Datumsaktualisierung (Refresh).
    Das Datum Ihres Jobangebots wird innerhalb der gebuchten Laufzeit immer nach 15 Tagen aktualisiert.
    Damit rutscht Ihre Stellenanzeige durch eine Datumsaktualisierung (Refresh) wieder in der Ergebnisliste nach oben und Sie erhalten noch mehr Sichtbarkeit.
  </p>
  <p>
    Beispiel:<br>
    Bei der Buchung „Refresh alle 30 Tage“ bei einer Anzeige mit Laufzeit von 60 Tagen wird die Anzeige am 15. Tag und am 45. Tag aktualisiert.
    Bei dieser Buchung würden zweimal die Kosten je Refresh anfallen.
    Sie haben die Auswahl den Refresh einmalig zu buchen oder immer nach dem 15. Tag über die gesamte Laufzeit (entsprechend oft wird der Refresh wiederholt).
  </p>
  <p>
    Ihre Vorteile:
  </p>
  <ul>
    <li>Datumsaktualisierung Ihrer Stellenanzeige</li>
    <li>Verbesserte Platzierung in den Ergebnislisten</li>
    <li>Erhöhte Wahrnehmung Ihres Angebots</li>
  </ul>`,

  duration: 'Laufzeit',
  totalAmount: 'Gesamtbetrag',

  /* Product descriptions */

  /* Product names are configured in the CMS */
  /* You can use "{}" as placeholders for the product name in the heading and subHeadline. */

  productDescriptionAnkerLinkText: 'mehr Informationen',

  regioOnlineOnlyDescriptionHeading: 'Online-{}',
  regioOnlineOnlyDescription: [
    `Veröffentlichen Sie Ihre Stellenanzeige auf {domain}. Durch die hohe Relevanz des Stellenportals
    in der Region können Sie verschiedenste Zielgruppen optimal erreichen und so Ihre offenen Stellen schnellstmöglich besetzen.`
  ],
  regioOnlineOnlyDescriptionSubHeadline: 'Leistungen Online-{}',
  regioOnlineOnlyDescriptionWhatYouGet: [
    'Ihre Anzeige auf {domain}',
    'Darstellung in mobil optimiertem Template',
    'Heute erstellen, morgen online'
  ],
  regioOnlineOnlyDescriptionWhatYouGetSubText: [],

  regioOnlineOnlyAzubiDescriptionHeading: 'Online-{}',
  regioOnlineOnlyAzubiDescription: [
    `Veröffentlichen Sie Ihre Stellenanzeige auf {domain}. Durch die hohe Relevanz des Stellenportals
      in der Region können Sie verschiedenste Zielgruppen optimal erreichen und so Ihre offenen Stellen schnellstmöglich besetzen.`
  ],
  regioOnlineOnlyAzubiDescriptionSubHeadline: 'Leistungen Online-{}',
  regioOnlineOnlyAzubiDescriptionWhatYouGet: [
    'Ihre Anzeige auf {domain}',
    'Darstellung in mobil optimiertem Template',
    'Heute erstellen, morgen online'
  ],
  regioOnlineOnlyAzubiDescriptionWhatYouGetSubText: [],

  portalOnlineOnlyDescriptionHeading: 'Online-{}',
  portalOnlineOnlyDescription: [
    `Veröffentlichen Sie Ihre Stellenanzeige auf {domain}. Durch die hohe Relevanz des Stellenportals
        in der Region können Sie verschiedenste Zielgruppen optimal erreichen und so Ihre offenen Stellen schnellstmöglich besetzen.`
  ],
  portalOnlineOnlyDescriptionSubHeadline: 'Leistungen Online-{}',
  portalOnlineOnlyDescriptionWhatYouGet: [
    'HTML individuelle Gestaltung',
    'Erstellung in Ihrem individuellen Anzeigenlayout inkl. Logo',
    'Ihr Firmenlogo erscheint in der Ergebnisliste',
    'Kostenlose "Jobs per Mail" an registrierte Bewerber'
  ],
  portalOnlineOnlyDescriptionWhatYouGetSubText: [],

  portalOnlineOnlyAzubiDescriptionHeading: 'Online-{}',
  portalOnlineOnlyAzubiDescription: [
    `Veröffentlichen Sie Ihre Stellenanzeige auf {domain}. Durch die hohe Relevanz des Stellenportals
      in der Region können Sie verschiedenste Zielgruppen optimal erreichen und so Ihre offenen Stellen schnellstmöglich besetzen.`
  ],
  portalOnlineOnlyAzubiDescriptionSubHeadline: 'Leistungen Online-{}',
  portalOnlineOnlyAzubiDescriptionWhatYouGet: [
    'HTML individuelle Gestaltung',
    'Erstellung in Ihrem individuellen Anzeigenlayout inkl. Logo',
    'Ihr Firmenlogo erscheint in der Ergebnisliste',
    'Kostenlose "Jobs per Mail" an registrierte Bewerber'
  ],
  portalOnlineOnlyAzubiDescriptionWhatYouGetSubText: [],

  premiumOnlineOnlyDescriptionHeading: 'Online-{}',
  premiumOnlineOnlyDescription: [
    `Nutzen Sie die enorme Reichweite von {domain} und stellenanzeigen.de,
      um noch mehr Bewerber sowohl aus der Region und auch überregional zielgruppenspezifisch bestmöglich zu erreichen.`,
    `Ihre Anzeige erscheint zusätzlich auf stellenanzeigen.de und einer optimalen Auswahl von fachspezifischen
      und regionalen Partnerwebsites aus deren Mediennetzwerk.`
  ],
  premiumOnlineOnlyDescriptionSubHeadline: 'Leistungen Online-{}',
  premiumOnlineOnlyDescriptionWhatYouGet: [
    `Schaltung Ihrer Anzeige auf {domain} und stellenanzeigen.de
      und einer optimalen Auswahl von fachspezifischen und regionalen Partnerwebsites`,
    'Erstellung in Ihrem individuellen Anzeigenlayout inkl. Logo',
    'Ihr Firmenlogo erscheint in der Ergebnisliste',
    'Ihre Anzeige profitiert zusätzlich von SmartReach 2.0, der intelligenten Reichweitentechnologie von stellenanzeigen.de',
    'Kostenlose "Jobs per Mail" an registrierte Bewerber'
  ],
  premiumOnlineOnlyDescriptionWhatYouGetSubText: [],

  premiumOnlineOnlyAzubiDescriptionHeading: 'Online-{}',
  premiumOnlineOnlyAzubiDescription: [
    `Nutzen Sie die enorme Reichweite von {domain} und stellenanzeigen.de,
      um noch mehr Bewerber sowohl aus der Region und auch überregional zielgruppenspezifisch bestmöglich zu erreichen.`,
    `Ihre Anzeige erscheint zusätzlich auf stellenanzeigen.de und einer optimalen Auswahl von fachspezifischen
      und regionalen Partnerwebsites aus deren Mediennetzwerk.`
  ],
  premiumOnlineOnlyAzubiDescriptionSubHeadline: 'Leistungen Online-{}',
  premiumOnlineOnlyAzubiDescriptionWhatYouGet: [
    `Schaltung Ihrer Anzeige auf {domain} und stellenanzeigen.de
      und einer optimalen Auswahl von fachspezifischen und regionalen Partnerwebsites`,
    'Erstellung in Ihrem individuellen Anzeigenlayout inkl. Logo',
    'Ihr Firmenlogo erscheint in der Ergebnisliste',
    'Ihre Anzeige profitiert zusätzlich von SmartReach 2.0, der intelligenten Reichweitentechnologie von stellenanzeigen.de',
    'Kostenlose "Jobs per Mail" an registrierte Bewerber'
  ],
  premiumOnlineOnlyAzubiDescriptionWhatYouGetSubText: [],

  /* Company Data */
  resellerOrCompanyHeader: 'Über Ihr Unternehmen',
  companyName: 'Vollständiger Unternehmensname',
  telephoneNumber: 'Telefonnummer für Rückfragen',
  homepage: 'Homepage',
  street: 'Straße und Hausnummer',
  postOffice: 'Postfach',
  city: 'Ort',
  postcode: 'PLZ',
  country: 'Land',
  contactPersonHeader: 'Über Ihren Ansprechpartner',
  emailContactPerson: 'E-Mail Adresse des Ansprechpartners',
  salutation: 'Anrede',
  personalTitle: 'Titel (max. 10 Zeichen)',
  firstName: 'Vorname',
  lastName: 'Nachname',

  /* Order Data */
  allowedFileTypes: 'Zulässige Dateiformate: ',

  offerOrderTitle: 'Titel Ihrer Anzeige',
  alternativeCompany: 'Abw. Unternehmensname für diese Anzeige',
  comment: 'Sie können uns hier einen Kommentar hinterlassen',
  positionAddition: 'Positionszusatz',
  searchTermAndRunningText: 'Text für Volltextsuche',
  searchKeywords: 'Suchbegriffe für Position (durch Leerzeichen getrennt)',
  attachment: 'Laden Sie hier Ihre Anzeige hoch',
  upload: 'Anzeige hochladen',
  categoriesHeading: 'Wählen Sie Berufs- und Unterkategorien (max. Auswahl: 5)',
  categoriesTooltip: '',
  applicationEmail: 'Ihre Bewerbungs-E-Mail-Adresse',
  applicationUrl: 'Link zu Ihrer Online-Bewerbung',

  /* Summary */
  summaryProductAndOptionsTitle: 'Produktdetails',
  summaryCompanyDetailsTitle: 'Firmendetails',
  summaryOfferDetailsTitle: 'Auftragsdetails',
  summaryHtmlTemplateDetailsTitle: 'Stellenbeschreibung',
  summaryStartDate: 'Voraussichtliches Startdatum',
  summaryAddExtras: 'Wählen Sie Zusatzoptionen',
  summaryCouponHeadline: 'Haben Sie einen Gutschein Code?',
  summaryPrivacyStatementTitle: 'Datenschutzerklärung & AGB',
  summaryPrivacyStatementText: 'Mit Klick auf „Bestellung abschließen“ bestätigen Sie, unsere <a href="[DATENSCHUTZ]" target="_blank" rel="noopener">Datenschutzerklärung</a> ' +
    '& <a href="[AGB]" target="_blank" rel="noopener">AGB</a>  gelesen zu haben.',
  summaryPriceDisclaimer: '* Preis zzgl. MwSt.',
  summaryRefreshPriceDisclaimer: '** Preis pro Refresh',
  summaryTotalPrice: 'Gesamtbetrag',
  summaryPriceSum: 'Zwischensumme',
  summaryVAT: 'MwSt.',
  summaryAddOns: 'Zusatzoptionen',
  summaryProductTitle: 'Produkt + Laufzeit',

  summaryOfferOrderTitle: 'Anzeigentitel',
  summaryOfferOrderStartDate: 'Erscheinungstermin',
  summaryOfferOrderAlternativeCompany: 'Unternehmensbezeichnung',
  summaryOfferOrderMail: 'E-Mail',
  summaryOfferOrderComment: 'Kommentar / abweichende Rechnungsanschrift',
  summaryOfferOrderPositionAddition: 'Positionszusatz',
  summaryOfferOrderSearchTermAndRunningText: 'Anzeigetext',
  summaryOfferOrderSearchKeywords: 'Suchbegriffe',
  summaryOfferOrderAttachment: 'Anhang',
  summaryOfferOrderUpload: 'Herunterladen',
  summaryOfferOrderCategoriesHeading: 'Tätigkeitsfelder',
  summaryOfferOrderLocation: 'Einsatzort',
  summaryApplicationEmail: 'Bewerbungs-E-Mail',
  summaryApplicationUrl: 'Bewerbungslink',
  summaryPreviewButtonPortal: 'Desktop',
  summaryPreviewButtonTablet: 'Tablet',
  summaryPreviewButtonMobile: 'Mobil',

  summaryInfoHeadline: 'Was passiert jetzt - Die Veröffentlichung in 3 Schritten: ',
  summaryInfoSubtitle: '1. Die Stellenanzeige wird bei uns im System angelegt.<br/> ' +
    '2. Zur Kontrolle der Anzeige senden wir Ihnen einen Freigabe-Link.<br/> ' +
    '3. Nach der Freigabe wird die Stellenanzeige auf unserer Plattform veröffentlicht. ',

  summaryPaymentMethod: 'Zahlungsart',
  summaryPaymentMethodCard: 'Kreditkarte',
  summaryPaymentMethodSepa: 'SEPA Lastschrift',
  summaryPaymentMethodSepaMandat: 'Ich ermächtige (Wir ermächtigen) [ZAHLUNGSEMPFÄNGER], Zahlungen von meinem (unserem) ' +
    'Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein (weisen wir unser) Kreditinstitut an, die von ' +
    '[ZAHLUNGSEMPFÄNGER] auf mein (unser) Konto gezogenen Lastschriften einzulösen.<br/>' +
    'Hinweis: Dieses Lastschriftmandat dient nur dem Einzug von Lastschriften, die auf Konten von Unternehmen gezogen sind. ' +
    'Ich bin (Wir sind) nicht berechtigt, nach der erfolgten Einlösung eine Erstattung des belasteten Betrages zu verlangen. ' +
    'Ich bin (Wir sind) berechtigt, mein (unser) Kreditinstitut bis zum Fälligkeitstag anzuweisen, Lastschriften nicht einzulösen.',
  summaryPaymentMethodInvoice: 'Rechnung',
  summaryPaymentMethodTitle: 'Zahlungsart wählen',
  summaryPaymentDataTitle: 'Zahlungsdaten eingeben',
  summaryInvoiceInfo: 'Sie erhalten eine Rechnung per E-Mail.',
  summaryCoupon: 'Gutscheincode eingeben',
  summaryCouponApply: 'Anwenden',
  summaryDiscount: 'Rabatt',

  /* Dialogs */
  dialogChangeProductHeader: 'Produkt ändern',
  dialogChangeProductDescription: 'Wenn Sie das Produkt wechseln, gehen alle Eingaben von Schritt 2 verloren.',
  dialogDeleteOfferOrder: 'Auftrag stornieren',
  dialogDeleteOfferOrderDescription: 'Wollen Sie diesen Auftrag wirklich stornieren?',
  dialogConfirm: 'Ändern',
  dialogConfirmDelete: 'Stornieren',
  dialogAbort: 'Abbrechen',

  successCreateOffer: 'Der Auftrag wurde erfolgreich erstellt.',
  successConfirmOffer: 'Der Auftrag wurde erfolgreich bestellt.',
  successConfirmPayment: 'Der Auftrag wurde erfolgreich bestellt und bezahlt.',

  /* ActionButtons */
  actionButtonDelete: 'Stornieren',
  actionButtonDeactivate: 'Deaktivieren',
  actionButtonActivate: 'Aktivieren',

  /* Errors */
  confirmError: 'Der Auftrag konnte nicht bestätigt werden.',
  paymentError: 'Die Bezahlung konnte nicht abgeschlossen werden.',
  linkExpiredError: 'Der Link ist abgelaufen.',
  loadingSummaryError: 'Die Daten des Auftrags konnten nicht geladen werden.',
  deleteOfferOrderError: 'Die Daten des Auftrags konnten leider nicht gelöscht werden.',
  deactivateOfferOrderError: 'Beim Deaktivieren der Auftrages ist ein Fehler aufgetreten.',
  previewError: 'Beim Erstellen der Vorschau ist ein Fehler aufgetreten.',
  imageIsToBig: 'Das ausgewählte Bild ist zu groß',
  error: 'Es ist ein Fehler aufgetreten',


  isLoading: 'Laden...',

  /** thank you page **/
  thanksHeading: 'Vielen Dank für Ihre Bestellung!',
  thanksHeadingAddition: '...und jetzt',
  checkMail: 'E-Mails prüfen & Status jederzeit einsehen',
  checkMailAddition: 'Indem Sie auf den Link in der von uns gesendeten E-Mail klicken.',
  thenWhat: 'Sich zurücklehnen & den Tag genießen',
  thenWhatAddition: 'Und sich freuen, dass wir Ihre Anzeige bald schalten!',

  /* StatusPage */
  status: 'Status Ihrer Anzeige',
  offerOrderNumber: 'Auftragsnummer',
  paymentStatus: 'Bezahlstatus',
  paymentStatusOpen: 'ausstehend',
  paymentStatusPaid: 'bezahlt',
  paymentStatusFailed: 'fehlgeschlagen',
  paymentStatusRefunded: 'rückerstattet',
  paymentStatusCanceled: 'abgebrochen',
  statusPaymentMethod: 'Zahlungsart',
  statusPaymentMethodCard: 'Kreditkarte',
  statusPaymentMethodSepa: 'SEPA Lastschrift',
  statusPaymentMethodInvoice: 'Rechnung',
  publicationDate: 'Erscheinungstermin',
  isBooked: 'gebucht',
  deleteOfferOrderSuccess: 'Der Auftrag wurde erfolgreich storniert!',
  deactivateOfferOrderSuccess: 'Der Auftrag wurde erfolgreich deaktiviert.',
  activateOfferOrderSuccess: 'Der Auftrag wurde erfolgreich aktiviert.',

  /*confirmation page*/
  from: 'ab',
  appliedDiscount: 'Rabatt',

  /* Deactivate OfferOrder Dialog */
  deactivateDialogTitle: 'Auftrag deaktivieren',
  reasonForDeactivation: 'Grund der Deaktivierung',
  deactivateOrderButtonSubmit: 'Auftrag deaktivieren',

  /* Locations Component */
  location: 'Einsatzort',
  locationCountry: 'Land',
  locationState: 'Bundesland',
  locationCity: 'PLZ/Ort',

  /* Categories Component */
  categories: 'Tätigkeitsfelder',
  categoriesDescription: '(Wählen Sie bis zu 5 Tätigkeitsfelder)',
  categoryPlaceholder: 'Rubrik auswählen',
  subCategoriesPlaceholder: 'Tätigkeitsfelder',
  firstChooseCategoryPlaceholder: 'Bitte wählen Sie zuerst eine Rubrik',
  maxAmountReachedPlaceholder: 'Sie haben die max. Anzahl ausgewählt',
  deleteSubCategoryToolTip: 'Tätigkeitsfeld löschen',

  noCategoryError: 'Wählen Sie mindestens eine Kategorie',

  /* Html Template */
  htmlTemplateCompanyName: 'Unternehmensname',
  htmlTemplateIntroductionText: 'Einleitung',
  htmlTemplateYourAssignmentsHeadline: 'Ihre Aufgaben',
  htmlTemplateYourAssignmentsText: 'Beschreiben Sie die Aufgaben des Stellenangebots',
  htmlTemplateYourProfileHeadline: 'Ihr Profil',
  htmlTemplateYourProfileText: 'Beschreiben Sie das geforderte Profil des Bewerbers',
  htmlTemplateWeOfferYouHeadline: 'Wir bieten Ihnen',
  htmlTemplateWeOfferYouText: 'Beschreiben Sie, was Sie dem Bewerber bieten',
  htmlTemplateCompanyDescription: 'Unternehmenstext',
  htmlTemplateJobApplyHeadline: 'Bewerbungswege',
  htmlTemplateJobApplyText: 'Bewerbungsoptionen',
  htmlTemplateJobApplyContactHeadline: 'Ansprechpartner',
  htmlTemplateJobApplyContact: 'Ansprechpartner',
  htmlTemplateLogo: 'Laden Sie hier Ihr Logo hoch: ',
  htmlTemplateLogoUpload: 'Logo hochladen',
  htmlTemplateAttachment: 'Anlage für Ihre Anzeige',
  htmlTemplateAttachmentHeadline: 'Titel des Anhangs',
  htmlTemplateColorPickerHeading: 'Wählen Sie Ihre Farbe aus',
  htmlTemplateCompanyDataHeader: 'Firmendaten',
  htmlTemplateJobDataHeader: 'Ausführliche Stellenbeschreibung',
  htmlTemplatePreviewTooltip: 'Vorschau',
  htmlTemplateLogoSummary: 'Logo',
  htmlTemplateColorSummary: 'Farbe',
  htmlTemplateCompanyNameSummary: 'Unternehmen',
  htmlTemplateIntroductionTextSummary: 'Einleitung',
  htmlTemplateYourAssignmentsHeadlineSummary: 'Ihre Aufgaben',
  htmlTemplateYourAssignmentsTextSummary: 'Beschreiben Sie die Aufgaben des Stellenangebots',
  htmlTemplateYourProfileHeadlineSummary: 'Ihr Profil',
  htmlTemplateYourProfileTextSummary: 'Beschreiben Sie das geforderte Profil des Bewerbers',
  htmlTemplateWeOfferYouHeadlineSummary: 'Wir bieten Ihnen',
  htmlTemplateWeOfferYouTextSummary: 'Beschreiben Sie, was Sie dem Bewerber bieten',
  htmlTemplateCompanyDescriptionSummary: 'Unternehmenstext',
  htmlTemplateJobApplyHeadlineSummary: 'Bewerbungswege',
  htmlTemplateJobApplyTextSummary: 'Bewerbungsoptionen',
  htmlTemplateJobApplyContactHeadlineSummary: 'Ansprechpartner',
  htmlTemplateJobApplyContactSummary: 'Ansprechpartner',
  htmlTemplateAttachmentFormGroupHeadline: 'Anlage für Ihre Anzeige',

  /* Validation Message */
  invalidTelefaxNumber: 'Bitte geben Sie eine gültige Faxnummer an.',

  exceededFileSize: 'Die ausgewählte Datei war zu groß.',
  invalidFileType: 'Die ausgewählte Datei hatte einen ungültigen Dateityp.',
  invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  required: 'Dieses Feld wird benötigt',
  required_upload: 'Dieses Feld wird benötigt. Bitte laden Sie Ihre fertige Stellenausschreibung hoch, gern auch als Word-Dokument oder HTML-Datei.',
  validPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer an.',
  invalidChars: 'Ungültiges Zeichen:',
  illegalCharacter: 'Der Dateiname enthält ein ungültiges Zeichen.',
  dateBetween: 'Muss zwischen Startdatum und vor Laufzeitende liegen',
  duplicateDates: 'Dieses Datum wurde bereits ausgewählt',
  invalidCoupon: 'Der Gutschein ist nicht gültig.',
  invalidLink: 'Externe Links müssen mit http://... bzw. https://... beginnen.',
  invalidHost: 'Externe Links müssen eine gültige Adresse besitzen.',



  /* Symbols */
  isRequired: '*',

  /** Image Cropper texts */
  imageCropperButtonCancelLabel: 'Abbrechen',
  imageCropperButtonCancelTitle: 'Abbrechen',
  imageCropperButtonConfirmLabel: 'Annehmen',
  imageCropperButtonConfirmTitle: 'Annehmen',
  imageCropperButtonDownloadLabel: 'Herunterladen',
  imageCropperButtonDownloadTitle: 'Herunterladen',
  imageCropperButtonEditLabel: 'Editieren',
  imageCropperButtonEditTitle: 'Editieren',
  imageCropperButtonRemoveLabel: 'Löschen',
  imageCropperButtonRemoveTitle: 'Löschen',
  imageCropperButtonRotateLabel: 'Rotieren',
  imageCropperButtonRotateTitle: 'Rotieren',
  imageCropperButtonUploadLabel: 'Hochladen',
  imageCropperButtonUploadTitle: 'Hochladen',
  imageCropperLabel: 'Hier klicken oder Ihr Logo ablegen.',
  imageCropperStatusContentLength: 'Die Datei ist vermutlich zu groß',
  imageCropperStatusFileSize: 'Die Datei ist zu groß. Die maximale Dateigröße ist $0 MB',
  imageCropperStatusFileType: 'Ungültiger Dateityp',
  imageCropperStatusImageTooSmall: 'Das Bild ist zu klein. Die minimale Bildgröße ist $0 Pixel',
  imageCropperStatusNoSupport: 'Ihr Browser unterstützt keine Bildverarbeitung',
  imageCropperStatusUnknownResponse: 'Ein Fehler ist aufgetreten',
  imageCropperStatusUploadSuccess: 'Fertig!',

  /* footer */
  footerPrivacyProtection: 'Datenschutz',
  footerPrivacyProtectionLink: 'https://www.stellenanzeigen.de/ueber-uns/datenschutz/',
  footerImpressum: 'Impressum',
  footerImpressumLink: 'https://www.stellenanzeigen.de/ueber-uns/impressum/',
  footerPrivacyAgb: 'AGB',
  footerAgbLink: 'https://www.stellenanzeigen.de/ueber-uns/agb/',
  // We have to at least fill a blank into the footerFreeToChoose otherwise it will complain that it finds no value
  footerFreeToChoose: ' ',
  footerFreeToChoose2: ' ',
  footerFreeToChoose2Link: ' ',
  footerFreeToChooseButton: ' ',
};

