/**
 * Sa.Sebu.Api
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type PaymentMethodEnum = 'Invoice' | 'Card' | 'Sepa';

export const PaymentMethodEnum = {
    Invoice: 'Invoice' as PaymentMethodEnum,
    Card: 'Card' as PaymentMethodEnum,
    Sepa: 'Sepa' as PaymentMethodEnum
}
