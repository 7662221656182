import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmationPageComponent } from './confirmation-page/components/confirmation-page.component';
import { StatusPageComponent } from './status-page/components/status-page.component';
import { OfferOrderContainerComponent } from './wizard/components/offer-order-container.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';


const routes: Routes = [
  {
    path: '', component: OfferOrderContainerComponent
  },
  {
    path: 'confirm/:date/:token', component: ConfirmationPageComponent
  },
  {
    path: 'created-offer-order', component: ThankYouPageComponent, canDeactivate: ['AllowLeaveWhenRequired']
  },
  {
    path: 'status/:token', component: StatusPageComponent
  },
  {
    path: '**', redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
