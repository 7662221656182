import { AvailableProductDuration } from './available-product-duration.model';
import { AvailableAddon } from './available-addon.model';
import { ProductEnum } from '../../api/client'; // eslint-disable-line
import { Product } from '../../core/models/product.model';

export class AvailableProduct extends Product {
  icon: string;
  availableDurations: AvailableProductDuration[];
  availableProductAddons: AvailableAddon[];
  vatRate?: number;

  constructor() {
    super(undefined, '');
    this.icon = '';
    this.availableDurations = [];
    this.availableProductAddons = [];
  }
}






