export class AvailableAddon {
  type: AddonType;
  price: number;

  constructor(type: AddonType, price?: number) {
    this.type = type;
    this.price = price;
  }
}

export enum AddonType {
  TopJob, TableEntry, Refresh, SocialMedia
}
