import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from './custom-validation.helper';

export class ValidationSetter {

  public static setRequired(controls: AbstractControl[]): void {
    controls.forEach(control => {
      control.setValidators(
        control.validator ?
          [control.validator, CustomValidators.requiredNotBlank] :
          [CustomValidators.requiredNotBlank]);
    });
  }

  public static setCharValidation(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls)
      .map(e => formGroup.controls[e])
      .forEach(control => {
        control.setValidators(
          control.validator ?
            [control.validator, CustomValidators.charset] :
            [CustomValidators.charset]);
      });
  }
}
