import { ProductEnum } from '../../../api/client';
import { Product } from '../../../core/models/product.model';

export  class ProductFormModel extends Product {
  duration: string;

  constructor(product?: ProductEnum, duration?: string, productName?: string) {
    super(product, productName);
    this.duration = duration || '';
  }
}
