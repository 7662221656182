import { Attachment } from '../../api/client';
import { FileExtension } from '../../wizard/models/file-extension';
import { FileExtensionHelper } from './file-extension-helper';
import { FILE_TYPES, FileType } from '../../wizard/models/file-type';

export class AttachmentHelper {

  static openAttachment(url: string, fileName: string): void {
    const hiddenDownloadLink: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(hiddenDownloadLink);
    hiddenDownloadLink.href = url;
    hiddenDownloadLink.target = '_blank';
    hiddenDownloadLink.download = fileName;
    hiddenDownloadLink.click();
  }

  public static getAttachmentLink(attachment: Attachment): string {
    if (attachment) {
      const fileExtension: FileExtension = FileExtensionHelper.getFileType(attachment.name);
      const fileType: FileType = FILE_TYPES.filter((type: FileType) => type.fileExtension === fileExtension)[0];
      const mimeType: string = fileType.mimeType;
      return 'data:' + mimeType + ';base64,' + attachment.bytes;
    } else {
      return '';
    }
  }
}
