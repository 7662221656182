import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryData, Subcategory } from '../../../api/client';
import { CategoryHelper } from '../../helper/category.helper';
import { map } from 'rxjs/operators';
import { CategoriesCachingService } from '../../../core/services/categories-caching-service/categories-caching.service';

@Component({
  selector: 'sebu-category-display',
  templateUrl: './category-display.component.html',
  styleUrls: ['./category-display.component.scss']
})
export class CategoryDisplayComponent implements OnInit {
  @Input()
  selectedSubCategories: Array<Subcategory>;

  @Input()
  compressedDisplay = false;

  @Output()
  categoryDelete: EventEmitter<Subcategory> = new EventEmitter<Subcategory>();

  public categories$: Observable<CategoryData[]>;

  constructor(private categoriesCachingService: CategoriesCachingService) {
  }

  public ngOnInit(): void {
    this.categories$ = this.categoriesCachingService.getCategories();
  }

  public getRubrics(categoryGroupId: number): Observable<string[]> {
    return CategoryHelper.getCategoriesWithCategoryGroupIds(this.categories$, [categoryGroupId])
      .pipe(map(categories => categories.map(category => category.label)));
  }

  public removeSubCategoryAction(subCategory: Subcategory): void {
    this.categoryDelete.emit(subCategory);
  }
}
