import { Component, OnInit } from '@angular/core';
import { TenantSettingsHelper } from '../../tenant-settings/tenant-settings.helper';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TenantTextHelper } from '../../tenant-texts/tenant-text.helper';

@Component({
  selector: 'sebu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version$: Observable<string>;
  public showPipeOnFooter: boolean;
  public footerFreeToChoose: string;
  public footerFreeToChoose2: string;
  public footerFreeToChooseButton: string;

  constructor(private httpClient: HttpClient) {
  }

  public ngOnInit(): void {
    this.version$ = this.httpClient.get('assets/version.json').pipe(
      filter((result: { version: string }) => !!result),
      map((result: { version: string }) => result.version),
      catchError((error) => {
        console.log(error);
        return of('');
      }));
    this.showPipeOnFooter = TenantSettingsHelper.getSettings().showFooterWithPipe;
    this.footerFreeToChoose = (TenantTextHelper.findTextByKey('footerFreeToChoose') as string).trim();
    this.footerFreeToChoose2 = (TenantTextHelper.findTextByKey('footerFreeToChoose2') as string).trim();
    this.footerFreeToChooseButton = (TenantTextHelper.findTextByKey('footerFreeToChooseButton') as string).trim();
  }
}
