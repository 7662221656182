<div #scrollcontainer class="fill-screen" (scroll)="onScroll()" (window:resize)="onResize()">
  <sebu-title></sebu-title>
  <sebu-meta-description></sebu-meta-description>
  <sebu-snippet></sebu-snippet>
  <sebu-header></sebu-header>
  <router-outlet (activate)="scrollOnTop()"></router-outlet>
</div>
<sebu-footer></sebu-footer>
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollcontainer.scrollTop = 0">
      <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
</div>
