import { Pipe, PipeTransform } from '@angular/core';
import { ErrorHelper } from '../../core/utils/error/error.helper';
import * as moment from 'moment';

@Pipe({
  name: 'DurationIsoPipe'
})
export class DurationIsoPipe implements PipeTransform {

  transform(value: string): string {
    return DurationIsoPipe.transform(value);
  }

  static transform(value: string): string {
    const duration: moment.Duration = moment.duration(value);
    const day: boolean = value.includes('D');
    const week: boolean = value.includes('W');
    const month: boolean = value.includes('M');
    const year: boolean = value.includes('Y');

    const period: number = day
      ? duration.asDays() : ( week
        ? duration.asWeeks() : (month
          ? duration.asMonths() : duration.asYears()));
    const singular: boolean = period === 1;

    if (day) {
      return singular ? period + ' Tag' : period + ' Tage';
    } else if (week) {
      return singular ? period + ' Woche' : period + ' Wochen';
    } else if (month) {
      return singular ? period + ' Monat' : period + ' Monate';
    } else if (year) {
      return singular ? period + ' Jahr' : period + ' Jahre';
    }

    ErrorHelper.throwError(`Cannot map ${{value}} to duration`);

    return undefined;
  }
}
