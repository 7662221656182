import { CityLocation, Location, OfferLocationTypeEnum, ZipCodeLocation } from '../../api/client';
import { LocationDisplay } from '../components/location-input/location-input.model';

export class LocationDisplayHelper {
  public static toCityOrZipLocationModel(location: Location): LocationDisplay {
    if (!location) {
      return undefined;
    }
    return new LocationDisplay(
      OfferLocationTypeEnum.Ort,
      LocationDisplayHelper.getLocationId(location),
      LocationDisplayHelper.getDisplayNameForCity(location)
    );
  }

  public static getDisplayNameForCity(location: Location): string {
    if (!location) {
      return '';
    }

    /*eslint-disable no-case-declarations*/
    switch (location.typeDiscriminator) {
      case 'ZipCodeLocation':
        const zipCodeLocation = location as ZipCodeLocation;
        // ZipCode only exists for non-V1 offers since 2018-10
        return zipCodeLocation.zipCode ? `${location.name} (${zipCodeLocation.zipCode})` : location.name;

      case 'CityLocation':
        const cityLocation= location as CityLocation;
        if (cityLocation.zipCode) {
          // In order to differentiate locations with same name (e.g. Münster), zip code "area" (with country if not DE) is displayed.
          const countryCode: string = (cityLocation.countryCode === 'DE') ? '' : `${cityLocation.countryCode}-`;
          return `${location.name} (${countryCode}${cityLocation.zipCode.substr(0, 2)}...)`;
        }
        return location.name;

        default:
        return location.name;
    }
  }

  public static getLocationId(location: Location): string {
    switch (location.typeDiscriminator) {
      case 'ZipCodeLocation':
        const zipCodeLocation = location as ZipCodeLocation;
        return zipCodeLocation.id;
      case 'CityLocation':
        const cityLocation = location as CityLocation;
        return cityLocation.id;
      default:
        return location.name;
    }
  }
}
