import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OfferOrderClassicRenderRequest, OfferService, PreviewService } from '../../api/client';
import { PayloadAction } from '../../core/models/payload-action.model';
import { UserNotifyService } from '../../core/services/user-notify/user-notify.service';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { RenderService } from '../services/render.service';
import * as WizardActions from './actions';
import { exhaustMap, map, catchError, switchMap } from 'rxjs/operators';
import { ResellerCachingService } from '../../core/services/reseller-caching-service/reseller-caching.service';

@Injectable({
  providedIn: 'root',
})
export class OfferOrderWizardEffects {

  constructor(private action$: Actions,
              private resellerCachingService: ResellerCachingService,
              private offerService: OfferService,
              private route: Router,
              private renderService: RenderService,
              private previewService: PreviewService,
              private userNotifyService: UserNotifyService) {
  }

  @Effect()
  loadReseller: Observable<WizardActions.LoadProductsAndAddonsSuccess | WizardActions.LoadProductsAndAddonsError> = this.action$.pipe(
    ofType(WizardActions.LOAD_PRODUCTS_AND_ADDONS),
    // eslint-disable-next-line
    exhaustMap(action =>
      this.resellerCachingService.getReseller()),
    map(reseller => new WizardActions.LoadProductsAndAddonsSuccess(reseller))
  );

  @Effect()
  getPreview: Observable<WizardActions.FetchPreviewSuccessfully | WizardActions.ThrowError> = this.action$.pipe(
    ofType(WizardActions.FETCH_PREVIEW),
    switchMap((action: PayloadAction) => this.renderService.mapWizardFormModelToRenderRequest(action.payload)),
    switchMap((renderData: OfferOrderClassicRenderRequest) => this.previewService.render(renderData)),
    map((url: string) => new WizardActions.FetchPreviewSuccessfully(environment.apiEndpoint + '/' + url)),
    catchError(() => of(new WizardActions.ThrowError()))
  );

  @Effect({dispatch: false})
  showOfferOrderErrorNotifier: Observable<{}> = this.action$.pipe(
    ofType(WizardActions.THROW_ERROR),
    exhaustMap(() => {
      this.userNotifyService.error(TenantTextPipe.transform('error') as string);
      return of({});
    })
  );
}
