<div class="description-wrapper">
  <div *ngFor="let placeholder of tenantTextsPlaceholder">
    <div *ngIf="hasValue(placeholder)" class="single-description">
      <div class="custom-icon" *ngIf="checkIconPath; else defaultCheckIcon">
        <sebu-svg class="custom-check" [svgSrc]="checkIconPath"></sebu-svg>
      </div>
      <ng-template #defaultCheckIcon>
        <i class="material-icons list-type-icon">check</i>
      </ng-template>
      <span [innerHTML]="getTenantTextVariable(placeholder) | TenantTextPipe"></span>
    </div>
  </div>
</div>
