import { LayoutData, SebuOfferOrderStatus } from '../../api/client';
import {
  LocationDisplay
} from '../../form-components/components/location-input/location-input.model';
import { CategoryHelper } from '../../form-components/helper/category.helper';
import { ProductMapperHelper } from '../../core/utils/mapping-helper/product-mapper.helper';
import { OfferOrderFormModel } from '../../wizard/models/wizard-form-models/offer-order-form.model';
import { StandardLayoutFormModel } from '../../wizard/models/wizard-form-models/standard-layout-form.model';
import { ProductStatusPageFormModel } from '../models/product-status-page-form.model';
import { StatusPageData } from '../models/status-page-data.model';
import { AttachmentHelper } from '../../shared/helper/attachment.helper';

export class StatusPageMapper {

  static mapJobDataToOfferOrderFormModel(sebuOfferOrderStatus: StatusPageData): OfferOrderFormModel {
    const statusData: SebuOfferOrderStatus = sebuOfferOrderStatus.statusData;
    const offerOrderFormModel: OfferOrderFormModel = {
      alternativeCompany: statusData.company.alternativeCompany || '',
      applicationEmail: statusData.offer.job.applicationEmail || '',
      applicationUrl: statusData.offer.job.applicationUrl || '',
      title: statusData.offer.job.title || '',
      comment: statusData.comment,
      offerOrderAttachmentLink: statusData.attachmentLink,
      location: new LocationDisplay(statusData.offer.job.locationType, statusData.offer.job.location, statusData.offer.job.location),
      categories: CategoryHelper.findSubCategoriesWithGroupIds(sebuOfferOrderStatus.allCategories, statusData.offer.job.categoryGroups)
    };
    return offerOrderFormModel;
  }

  static mapSebuOfferOrderStatusProductSummaryFormModel(sebuOfferOrderStatus: SebuOfferOrderStatus, productName: string): ProductStatusPageFormModel {
    const productSummaryFormModel: ProductStatusPageFormModel = {
      offerOrderNumber: sebuOfferOrderStatus.offerOrderNumber,
      productName: productName,
      productEnum: sebuOfferOrderStatus.product,
      productConfiguration: ProductMapperHelper.mapProductToConfiguration(sebuOfferOrderStatus.product),
      bookedFeaturePrices: sebuOfferOrderStatus.bookedFeaturePrices,
      duration: sebuOfferOrderStatus.duration,
      publicationDate: sebuOfferOrderStatus.desiredPublicationDate,
      refreshOption: sebuOfferOrderStatus.refreshOption,
      status: sebuOfferOrderStatus.status,
      paymentStatus: sebuOfferOrderStatus.paymentStatus,
      paymentMethod: sebuOfferOrderStatus.paymentMethod,
      features: {
        topJob: sebuOfferOrderStatus.isTopJobBooked,
        socialMedia: sebuOfferOrderStatus.isSocialMediaBooked,
        tableEntry: sebuOfferOrderStatus.isTableEntryBooked,
        refreshOption: !!sebuOfferOrderStatus.refreshOption
      }
    };
    return productSummaryFormModel;
  }


  static mapSebuOfferOrderStatusToStandardLayoutFormModel(sebuOfferOrderStatus: SebuOfferOrderStatus): StandardLayoutFormModel {
    const layoutData: LayoutData = sebuOfferOrderStatus.offer.details;
     const standardLayoutFormModel: StandardLayoutFormModel = {
      companyName: sebuOfferOrderStatus.company.alternativeCompany,
      introductionText: layoutData.introductionText,
      yourAssignmentsHeadline: layoutData.yourAssignmentsHeadline,
      yourAssignmentsText: layoutData.yourAssignmentsText,
      yourProfileHeadline: layoutData.yourProfileHeadline,
      yourProfileText: layoutData.yourProfileText,
      weOfferYouHeadline: layoutData.weOfferYouHeadline,
      weOfferYouText: layoutData.weOfferYouText,
      companyDescription: layoutData.companyDescription,
      jobApplyHeadline: layoutData.jobApplyHeadline,
      jobApplyText: layoutData.jobApplyText,
      jobApplyContactHeadline: layoutData.jobApplyContactHeadline,
      jobApplyContact: layoutData.jobApplyContact,
      logo: layoutData.logo || null,
      logoLink: AttachmentHelper.getAttachmentLink(layoutData.logo),
      attachment: layoutData.attachment || null,
      attachmentHeadline: layoutData.attachmentDisplayText,
      attachmentLink: AttachmentHelper.getAttachmentLink(layoutData.attachment),
      color: layoutData.offerOrderClassicRenderStyle ? layoutData.offerOrderClassicRenderStyle.color : ''
    };
    return standardLayoutFormModel;
  }
}

