import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Attachment, OfferOrderClassicRenderRequest } from '../../api/client';
import { WizardFormModel } from '../models/wizard-form-models/wizard-form.model';
import { CompanyFormModel } from '../models/wizard-form-models/company-form.model';
import { OfferOrderFormModel } from '../models/wizard-form-models/offer-order-form.model';
import { StandardLayoutFormModel } from '../models/wizard-form-models/standard-layout-form.model';
import { FileReaderService } from './file-reader.service';
import { ProductStatusPageFormModel } from '../../status-page/models/product-status-page-form.model';
import { ProductFormModel } from '../models/wizard-form-models/product-form.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RenderService {

  constructor(private fileReader: FileReaderService) {
  }

  public mapWizardFormModelToRenderRequest(wizardFormModel: WizardFormModel): Observable<OfferOrderClassicRenderRequest> {
    const attachment: Attachment = wizardFormModel.standardLayout.attachment;
    if (attachment && attachment instanceof File) {
      const attachment$: Observable<Attachment> = this.fileReader.convertFileToAttachment(attachment as File);
      return attachment$.pipe(map(resolvedAttachment => this.mapWizardFormModel(wizardFormModel, resolvedAttachment)));
    } else {
      return of(this.mapWizardFormModel(wizardFormModel, null));
    }
  }

  private mapWizardFormModel(wizardFormModel: WizardFormModel, attachment: Attachment): OfferOrderClassicRenderRequest {
    const companyFormModel: CompanyFormModel = wizardFormModel.company;
    const offerOrderFormModel: OfferOrderFormModel = wizardFormModel.offerOrder;
    const template: StandardLayoutFormModel = wizardFormModel.standardLayout;
    const renderRequest: OfferOrderClassicRenderRequest = {
      typeDiscriminator: 'OfferOrderClassicRenderRequest',
      renderData: {
        positionName: offerOrderFormModel.title || null,
        companyName: template.companyName || null,
        jobLocation: offerOrderFormModel.location ? offerOrderFormModel.location.valueToDisplay || null : null,
        startDate: wizardFormModel.product instanceof ProductFormModel ? wizardFormModel.summary.summaryProduct.publicationDate || new Date() :
          (wizardFormModel.product as ProductStatusPageFormModel).publicationDate || new Date(),
        introductionText: template.introductionText || null,
        yourAssignmentsHeadline: template.yourAssignmentsHeadline || null,
        yourAssignmentsText: template.yourAssignmentsText || null,
        yourProfileHeadline: template.yourProfileHeadline || null,
        yourProfileText: template.yourProfileText || null,
        weOfferYouHeadline: template.weOfferYouHeadline || null,
        weOfferYouText: template.weOfferYouText || null,
        companyStreet: companyFormModel.street || null,
        companyPostcode: companyFormModel.postcode || null,
        companyCity: companyFormModel.city || null,
        companyHomepage: companyFormModel.homepage || null,
        companyEmail: companyFormModel.email || null,
        companyDescription: template.companyDescription || null,
        jobApplyHeadline: template.jobApplyHeadline || null,
        jobApplyText: template.jobApplyText || null,
        jobApplyEmail: offerOrderFormModel.applicationEmail || null,
        jobApplyUrl: offerOrderFormModel.applicationUrl || null,
        jobApplyContactHeadline: template.jobApplyContactHeadline || null,
        jobApplyContact: template.jobApplyContact || null,
        attachmentHeadline: template.attachmentHeadline || null,
        logo: template.logo ? {
          name: template.logo.name,
          bytes: template.logo.bytes
        } : null,
        attachment: this.mapAttachment(template.attachment, attachment)
      },
      style: template.color ? {
        color: template.color
      } : null
    };
    return renderRequest;
  }

  /*
  Attachment is sometimes a empty object, which results in a null pointer exception of the renderer
   */
  private mapAttachment(templateAttachment: Attachment, attachment: Attachment): Attachment {
    attachment = attachment || templateAttachment;
    return attachment && Object.keys(attachment).length !== 0 ? attachment : null;
  }
}
