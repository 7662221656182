import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import {
  StripeCardCvcElement,
  StripeCardCvcElementOptions,
  StripeCardExpiryElement,
  StripeCardExpiryElementOptions,
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  StripeElementClasses,
  StripeElements,
  StripeElementsOptions,
  StripeElementStyle,
  StripeIbanElement,
  StripeIbanElementOptions
} from '@stripe/stripe-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentMethodEnum } from '../../../api/client';
import { environment } from '../../../../environments/environment';
import { CheckoutData } from './checkout.model';
import { CreditCardBrand } from './credit-card-brand.type';
import { TenantTextHelper } from '../../../tenant-texts/tenant-text.helper';

@Component({
  selector: 'sebu-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckoutComponent),
    multi: true
  }]
})
export class CheckoutComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  public paymentMethods: PaymentMethodEnum[] = [];
  public stripe: StripeInstance;
  public cardNumberComplete: boolean;
  public cardExpiryComplete: boolean;
  public cardCvcComplete: boolean;
  public sepaComplete: boolean;

  public checkoutForm: UntypedFormGroup;
  public paymentMethod: PaymentMethodEnum;
  public brand?: CreditCardBrand;
  public bank?: string;

  public errorMessage: string;
  public sepaMandat: string;
  public valid: boolean;

  private elementsOptions: StripeElementsOptions = {
    locale: 'de',
    fonts: [{
      // confirm page is "{base}/confirm/{date}/{token}"
      // and assets are "{base}/assets/...", so go up 2
      src: 'url(../../assets/font.ttf)',
      family: 'font',
    }],
  };

  private elementStyles: StripeElementStyle = {
    base: {
      fontWeight: '500',
      fontFamily: 'font',
      fontSize: '14px',
      color: '#5e5e5e', // $font-grey
      '::placeholder': {
        color: '#D8D8D8', // $light-grey
      },
    },
  };

  private elementClasses: StripeElementClasses = {
    complete: 'complete',
    invalid: 'invalid',
    focus: 'focus',
  };

  @ViewChild('cardNumber', { static: true })
  private cardNumberRef: ElementRef;
  private cardNumber: StripeCardNumberElement;
  private cardNumberOptions: StripeCardNumberElementOptions = {
    style: this.elementStyles,
    classes: this.elementClasses,
  };

  @ViewChild('cardExpiry', { static: true })
  private cardExpiryRef: ElementRef;
  private cardExpiry: StripeCardExpiryElement;
  private cardExpiryOptions: StripeCardExpiryElementOptions = {
    style: this.elementStyles,
    classes: this.elementClasses,
  };

  @ViewChild('cardCvc', { static: true })
  private cardCvcRef: ElementRef;
  private cardCvc: StripeCardCvcElement;
  private cardCvcOptions: StripeCardCvcElementOptions = {
    style: this.elementStyles,
    classes: this.elementClasses,
    placeholder: '123',
  };

  @ViewChild('sepa', { static: true })
  private sepaRef: ElementRef;
  private sepa: StripeIbanElement;
  private sepaOptions: StripeIbanElementOptions = {
    supportedCountries: ['SEPA'],
    style: this.elementStyles,
    classes: this.elementClasses,
  };

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private stripeFactory: StripeFactoryService) {
  }

  public writeValue(_: CheckoutData): void {
    // Input only
  }

  // eslint-disable-next-line
  public propagateChange = (_: CheckoutData) => {
  }

  // eslint-disable-next-line
  public registerOnTouched(): void {
  }

  public registerOnChange(fn: (_: CheckoutData) => {}): void {
    this.propagateChange = fn;
    if (this.valid) {
      // send initial state to parent control if valid
      this.propagateChange(this.getCheckoutData());
    }
  }

  ngOnInit(): void {
    this.buildSepaMandatText();

    this.checkoutForm = this.formBuilder.group({
      'paymentMethod': [],
      'cardOwner': [''],
      'accountOwner': [''],
      'agreeSepaMandat': [''],
    });
    this.setValidation();

    this.checkoutForm.get('cardOwner').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.update());

    this.checkoutForm.get('accountOwner').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.update());

    this.checkoutForm.get('paymentMethod').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((paymentMethod: PaymentMethodEnum) => {
        this.paymentMethod = paymentMethod;
        this.setValidation();
        this.update();
      });

    this.checkoutForm.get('agreeSepaMandat').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((() => this.update()));

    this.stripe = this.stripeFactory.create(environment.stripeKey);
    this.stripe.elements(this.elementsOptions).subscribe(elements => {
      this.mount(elements);
    });

    // set initial value for payment method
    this.checkoutForm.get('paymentMethod').setValue(this.paymentMethods[0]);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  private buildSepaMandatText(): void {
    const placeholder: RegExp = /\[ZAHLUNGSEMPFÄNGER]/g; // eslint-disable-line
    const receiver: string = TenantTextHelper.hasValue('summaryPaymentMethodSepaMandatReceiver') ?
      TenantTextHelper.texts['summaryPaymentMethodSepaMandatReceiver'] :
      TenantTextHelper.texts['sebuDomain'];

    this.sepaMandat = TenantTextHelper.texts['summaryPaymentMethodSepaMandat'].replace(placeholder, receiver);
  }

  private setValidation(): void {
    this.checkoutForm.markAsPristine();
    this.checkoutForm.markAsUntouched();

    this.checkoutForm.get('cardOwner').clearValidators();
    this.checkoutForm.get('accountOwner').clearValidators();

    if (this.paymentMethod === PaymentMethodEnum.Card) {
      this.checkoutForm.get('cardOwner').setValidators([Validators.required]);
    } else if (this.paymentMethod === PaymentMethodEnum.Sepa) {
      this.checkoutForm.get('accountOwner').setValidators([Validators.required]);
    }

    this.checkoutForm.get('cardOwner').updateValueAndValidity();
    this.checkoutForm.get('accountOwner').updateValueAndValidity();
  }

  private mount(elements: StripeElements): void {
    this.cardNumber = elements.create('cardNumber', this.cardNumberOptions);
    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardNumber.on('change', event => {
      this.brand = !event.brand || event.brand === 'unknown' || event.brand === 'unionpay' // no icon
        ? null : event.brand;
      this.cardNumberComplete = event.complete;
      this.update(event);
    });
    this.cardExpiry = elements.create('cardExpiry', this.cardExpiryOptions);
    this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
    this.cardExpiry.on('change', event => {
      this.cardExpiryComplete = event.complete;
      this.update(event);
    });
    this.cardCvc = elements.create('cardCvc', this.cardCvcOptions);
    this.cardCvc.mount(this.cardCvcRef.nativeElement);
    this.cardCvc.on('change', event => {
      this.cardCvcComplete = event.complete;
      this.update(event);
    });

    this.sepa = elements.create('iban', this.sepaOptions);
    this.sepa.mount(this.sepaRef.nativeElement);
    this.sepa.on('change', event => {
      this.bank = event.bankName;
      this.sepaComplete = event.complete;
      this.update(event);
    });
  }

  private update(event?: StripeElementChangeEvent): void {
    this.errorMessage = event && event.error ? event.error.message : null;
    this.valid = this.getValidState();
    this.propagateChange(this.getCheckoutData());
  }

  private getCheckoutData(): CheckoutData | null {
    if (!this.valid) {
      return null; // causes "required" validation error in outer component
    }
    switch (this.paymentMethod) {
      case PaymentMethodEnum.Card:
        return {
          method: this.paymentMethod,
          stripe: this.stripe,
          element: this.cardNumber,
          cardOwner: this.checkoutForm.get('cardOwner').value,
          brand: this.brand,
        };
      case PaymentMethodEnum.Sepa:
        return {
          method: this.paymentMethod,
          stripe: this.stripe,
          element: this.sepa,
          accountOwner: this.checkoutForm.get('accountOwner').value,
          bank: this.bank,
        };
      case PaymentMethodEnum.Invoice:
        return {
          method: this.paymentMethod,
          stripe: this.stripe,
        };
    }
  }

  private getValidState(): boolean {
    switch (this.paymentMethod) {
      case PaymentMethodEnum.Card:
        return this.checkoutForm.get('cardOwner').valid &&
          this.cardNumberComplete &&
          this.cardExpiryComplete &&
          this.cardCvcComplete;
      case PaymentMethodEnum.Sepa:
        return this.checkoutForm.get('accountOwner').valid &&
          this.sepaComplete &&
          this.checkoutForm.get('agreeSepaMandat').value;
      case PaymentMethodEnum.Invoice:
        return true;
    }
  }
}
