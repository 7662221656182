import { Coupon } from '../../../../api/client';

export class CouponDiscountHelper {

  // Will prioritize coupon discount amount before percent when both are non-zero
  public static calculateDiscountFromCoupon(coupon: Coupon, totalPrice: number): number | null {
    if (!coupon) {
      return null;
    }
    return this.calculateDiscount(coupon.amount, coupon.percent, totalPrice);
  }

  // Will prioritize discount amount before percent when both are non-zero
  public static calculateDiscount(discountAmount: number, discountPercent: number, totalPrice: number): number | null {
    if (discountAmount) {
      // More discount than the total price is not allowed.
      return (discountAmount > totalPrice) ? totalPrice : discountAmount;
    }
    if (discountPercent) {
      // More discount than 100% is not allowed.
      return (discountPercent > 1) ? totalPrice : discountPercent *  totalPrice ;
    }
    return null;
  }
}
