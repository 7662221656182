import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ZipUploadDialogComponent } from './zip-upload/zip-upload-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    MatIconModule,
    CommonModule,
    FlexLayoutModule
  ],
  declarations: [
    ZipUploadDialogComponent],

  entryComponents: [
    ZipUploadDialogComponent
  ]
})

export class AttachmentUploadModule { }
