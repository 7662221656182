import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogService } from '../../../core/services/confirm-dialog/confirm-dialog.service';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { AvailableProduct } from '../../models/available-product.model';
import { ProductFormModel } from '../../models/wizard-form-models/product-form.model';
import { ProductArrayHelper } from './product-array.helper';

@Directive()
export class ProductSelectionComponent implements ControlValueAccessor {

  @Input()
  public set availableProducts(value: AvailableProduct[]){
    this._availableProducts = ProductArrayHelper.fillProductEnumInDurations(value)
      .sort(ProductArrayHelper.productsByWeightSorter);
  }
  public get availableProducts(): AvailableProduct[] {
    return this._availableProducts;
  }

  @Input()
  public willLooseData = false;

  @Output()
  public willLooseDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _availableProducts: AvailableProduct[];

  public propagateChange = ( _: ProductFormModel ) => {
  }

  constructor( protected confirmDialog: ConfirmDialogService ) {
  }

  // eslint-disable-next-line
  public writeValue( productDetails: ProductFormModel ): void {
    // Overridden in child classes
  }

  public registerOnChange( fn: ( _: ProductFormModel ) => {} ): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched( fn: string ): void {
  }

  protected getSVG( pictureName: string ): string {
    return environment.pathToSVGs + pictureName;
  }

  protected warnForClear(): Observable<boolean> {
    return this.confirmDialog.confirm(TenantTextPipe.transform('dialogChangeProductHeader') as string,
      TenantTextPipe.transform('dialogChangeProductDescription') as string,
      TenantTextPipe.transform('dialogAbort') as string,
      TenantTextPipe.transform('dialogConfirm') as string,
      'accent',
      'warn');
  }
}
