<div class="content" *ngIf="(state$ | async) as state">
  <ng-container *ngIf="state &&
                       state.formModels &&
                       state.formModels.product &&
                       state.formModels.company &&
                       state.formModels.offerOrder &&
                       state.productConfiguration">
    <mat-horizontal-stepper [linear]="linearStepper || true"
                            [@.disabled]="disableAnimationsForStepper"
                            [selectedIndex]="templatedProducts.includes(state.productConfiguration?.productEnum) ? 5 : 4"
                            #stepper>
      <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
        {{index + 1}}
      </ng-template>
      <ng-template matStepperIcon="number" let-index="index">
        {{index + 1}}
      </ng-template>

      <mat-step [editable]="false" [completed]="true" aria-labelledby="disabled">
        <ng-template matStepLabel>
          <span sebuTenantText>product_selection</span>
        </ng-template>
      </mat-step>

      <mat-step [editable]="false" [completed]="true" aria-labelledby="disabled">
        <ng-template matStepLabel>
          <span sebuTenantText>offer_order_details</span>
        </ng-template>
      </mat-step>
      <mat-step *ngIf="templatedProducts.includes(state.productConfiguration?.productEnum)"
                [editable]="false" [completed]="true" aria-labelledby="disabled">
        <ng-template matStepLabel>
          <span sebuTenantText>htmlTemplateDetails</span>
        </ng-template>
      </mat-step>
      <mat-step [editable]="false" [completed]="true" aria-labelledby="disabled">
        <ng-template matStepLabel>
          <span sebuTenantText>company_details</span>
        </ng-template>
      </mat-step>
      <mat-step [editable]="false" [completed]="true" aria-labelledby="disabled">
        <ng-template matStepLabel>
          <span sebuTenantText>summary</span>
        </ng-template>
      </mat-step>

      <ng-container *ngIf="sebuResellerData$ | async as reseller">
          <mat-step *ngIf="reseller.paymentEnabled" [stepControl]="paymentForm.get('payment')">
            <ng-template matStepLabel>
              <span sebuTenantText>payment</span>
            </ng-template>
            <div [formGroup]="paymentForm">
              <sebu-checkout [paymentMethods]="reseller.paymentMethods"
                             formControlName="payment"
                             #checkout
                             ngDefaultControl></sebu-checkout>
              <div class="button-row e2e-payment-type-buttons" fxLayoutAlign="end">
                <button mat-raised-button
                        class="button-next"
                        [disabled]="!checkout.valid"
                        matStepperNext
                        sebuTenantText>buttonNext</button>
              </div>
            </div>

          </mat-step>
      </ng-container>

      <mat-step>
        <ng-template matStepLabel>
          <span sebuTenantText>finalize</span>
        </ng-template>
        <div>
          <sebu-confirmation-product-summary
            [productConfiguration]="state.productConfiguration"
            [productName]="state.formModels.product.productName"
            [offerOrder]="state.offerOrderData"
            [sebuResellerData]="sebuResellerData$ | async"
            [checkoutData]="checkoutData"></sebu-confirmation-product-summary>
          <sebu-company-summary
            [isStepEditable]="false"
            [companyData]="state.formModels.company"></sebu-company-summary>
          <sebu-offer-summary
            [isStepEditable]="false"
            [offerOrderData]="state.formModels.offerOrder"
            [productControlConfiguration]="state.productConfiguration.formConfiguration"></sebu-offer-summary>
          <sebu-html-template-summary
            [isStepEditable]="false"
            [previewUrl]="(previewUrl$ | async)"
            [previewIsLoading]="(uiStateWizard$ | async).previewLoadingSpinner.isLoading"
            *ngIf="templatedProducts.includes(state.productConfiguration?.productEnum)"></sebu-html-template-summary>

          <sebu-privacy-statement (privacyStatementChanged)="togglePrivacyAgreed($event)"
                                  [disabled]="(confirmButtonText === ConfirmButtonTextEnum.IsLoading)"></sebu-privacy-statement>
          <div fxLayoutAlign="end start">
            <button
              mat-raised-button
              class="button-confirm"
              [disabled]="confirmButtonDisabled || !paymentForm.valid"
              (click)="sendOrder()">{{confirmButtonText | TenantTextPipe}}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
</div>
