import { OfferOrderActionEnum, SebuOfferOrderStatus } from '../../../api/client';
import { PayloadAction } from '../../../core/models/payload-action.model';
import { ProductMapperHelper } from '../../../core/utils/mapping-helper/product-mapper.helper';
import { WizardFormModel } from '../../../wizard/models/wizard-form-models/wizard-form.model';
import { StatusPageMapper } from '../../helper/status-page-mapper.helper';
import { StatusPageState } from '../../models/status-page';
import * as Actions from '../actions';
import { StatusPageInitialState } from './status-page-initial-state';

export function statusPageReducer(state: StatusPageState = StatusPageInitialState, action: PayloadAction): StatusPageState {

  /*eslint-disable no-case-declarations*/
  switch (action.type) {
    case Actions.LOAD_OFFER_ORDER_DATA_SUCCESS:
      const payload = action.payload;
      return {
        ...state,
        wizardFormModel: {
          product: StatusPageMapper.mapSebuOfferOrderStatusProductSummaryFormModel(payload.statusData, payload.productName),
          company: ProductMapperHelper.mapCompanyDataToCompanyFormModel((payload.statusData as SebuOfferOrderStatus).company),
          offerOrder: StatusPageMapper.mapJobDataToOfferOrderFormModel(payload),
          standardLayout: StatusPageMapper.mapSebuOfferOrderStatusToStandardLayoutFormModel(payload.statusData),
          summary: null
        }
      };

    case Actions.LOAD_OFFER_ORDER_ACTIONS_SUCCESS:
      return {
        ...state,
        allowedActions: action.payload.offerOrderActions
      };

    case Actions.DELETE_OFFER_ORDER_SUCCESS:
      return new StatusPageState();

    default:
      return state;
  }
}

export const getStatusPageAllowedActionsEntities: (_: StatusPageState) => OfferOrderActionEnum[] =
  (state: StatusPageState) => state.allowedActions;
export const getFormModelEntities: (_: StatusPageState) => WizardFormModel =
  (state: StatusPageState) => state.wizardFormModel;
