<sebu-header-with-button [showEditButton]="false">{{ "summaryProductAndOptionsTitle" | TenantTextPipe }}
</sebu-header-with-button>
<div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="center center">

  <div class="cost-wrapper">
    <div class="row product-price">
      <h4 class="feature-name">{{productName}} - {{offerOrder?.duration | DurationIsoPipe}} <br/>
        ({{'from' | TenantTextPipe}} {{publicationDate| date:'dd.MM.yyyy'}})</h4>
      <h4 class="price">{{offerOrder.bookedFeaturePrices.sebuProductPrice | currency:'EUR' }}
        <span *ngIf="showAsteriscPriceHint">&nbsp;*</span>
      </h4>
    </div>
    <div class="row top-job-feature" *ngIf="sebuResellerData?.topJobPrice != null && offerOrder.bookedFeaturePrices.sebuTopJobPrice != null">
      <div sebuTenantText class="feature-name">topJob</div>
      <div
        class="price">{{(offerOrder.bookedFeaturePrices.sebuTopJobPrice |
          currency:'EUR') + ' ' + (showAsteriscPriceHint ? '*' : '')}}</div>
    </div>
    <div class="row social-media-feature" *ngIf="sebuResellerData?.socialMediaPrice != null && offerOrder.bookedFeaturePrices.sebuSocialMediaPrice != null">
      <div sebuTenantText class="feature-name">socialMedia</div>
      <div
        class="price">{{(offerOrder.bookedFeaturePrices.sebuSocialMediaPrice |
        currency:'EUR') + ' ' + (showAsteriscPriceHint ? '*' : '')}}</div>
    </div>
    <div class="row table-entry-feature" *ngIf="hasResellerTableEntryForProduct && sebuResellerData?.tableEntryPrice != null && offerOrder.bookedFeaturePrices.sebuTableEntryPrice != null">
      <div sebuTenantText class="feature-name">tableEntry</div>
      <div
        class="price">{{(offerOrder.bookedFeaturePrices.sebuTableEntryPrice
          | currency:'EUR') + ' ' + (showAsteriscPriceHint ? '*' : '')}}</div>
    </div>
    <div class="row refresh-feature" *ngIf="hasResellerRefreshEnabledForProduct && offerOrder.bookedFeaturePrices.sebuRefreshDatePrice != null">
      <div
        class="feature-name">{{('refresh' | TenantTextPipe) + ' x ' + amountOfRefreshes}}</div>
      <div class="price">
        {{((offerOrder.bookedFeaturePrices.sebuRefreshDatePrice * amountOfRefreshes)
          | currency:'EUR') + ' ' + (showAsteriscPriceHint ? '*' : '') }}
      </div>
    </div>
    <div class="row discount-display" *ngIf="paymentEnabled && discount">
      <div sebuTenantText class="feature-name">appliedDiscount</div>
      <div class="price">- {{discount | currency:'EUR'}}</div>
    </div>
    <hr>
    <div class="row price-sum" *ngIf="offerOrder.bookedFeaturePrices.sebuVatRate">
      <h4 sebuTenantText>summaryPriceSum</h4>
      <h4 class="price">{{priceSum | currency:'EUR' }}</h4>
    </div>
    <div class="row vat-display" *ngIf="offerOrder.bookedFeaturePrices.sebuVatRate">
      <div>{{'summaryVAT' | TenantTextPipe}} ({{offerOrder.bookedFeaturePrices.sebuVatRate | percent:'1.0-2'}})</div>
      <div class="price">{{vatAmount | currency:'EUR' }}</div>
    </div>
    <div class="row">
      <h3 sebuTenantText>totalAmount</h3>
      <h3 class="price">{{(offerOrder.bookedFeaturePrices.sebuTotalPrice || '0') | currency:'EUR' }}
        <span *ngIf="showAsteriscPriceHint">&nbsp;*</span></h3>
    </div>
    <div class="row" *ngIf="checkoutData?.method">
      <h4 sebuTenantText>summaryPaymentMethod</h4>
      <h4 class="price">{{ 'summaryPaymentMethod' + checkoutData.method | TenantTextPipe }}</h4>
    </div>
    <div *ngIf="showAsteriscPriceHint" sebuTenantText>summaryPriceDisclaimer</div>
  </div>
</div>
