import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';
import { SummaryFormModel } from '../../models/wizard-form-models/summary-form.model';
import { WizardFormModel } from '../../models/wizard-form-models/wizard-form.model';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { ProductEnum } from '../../../api/client/model/productEnum';
import { OrderPrice } from '../../models/wizard-form-models/order-price.model';
import { Product } from '../../../core/models/product.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TemplatedProducts } from '../../models/product-configuration/product-registry';
import { TenantTextHelper } from '../../../tenant-texts/tenant-text.helper';


@Component({
  selector: 'sebu-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SummaryComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: SummaryComponent,
      multi: true
    }]
})
export class SummaryComponent implements ControlValueAccessor, OnDestroy, Validator {

  @Input()
  public formData: WizardFormModel;

  @Input()
  public productControlConfiguration: ControlConfiguration[] = [];

  @Input()
  public priceModel: OrderPrice;

  @Input()
  public selectedProduct: Product;

  @Input()
  public previewUrl: string;

  @Input()
  public previewIsLoading: boolean;

  @Input()
  public summaryInfoSubtitle: string = TenantTextHelper.texts['summaryInfoSubtitle'];

  @Output()
  public editProduct: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editCompany: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editOffer: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editStandardLayout: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public showPreviewEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  public PRODUCT_ENUM: typeof ProductEnum = ProductEnum;

  public summary: UntypedFormGroup;
  public templatedProducts: ProductEnum[] = TemplatedProducts;
  private componentDestroyed$: Subject<void> = new Subject<void>();
  private validatorChange: () => void;

  public propagateChange = (_: SummaryFormModel) => {
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    this.summary = this.formBuilder.group(new SummaryFormModel());
    this.summary.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(changes =>
      this.propagateChange(changes));
  }

  writeValue(summaryModel: SummaryFormModel): void {
    if (summaryModel) {
      this.summary.setValue(summaryModel);
    } else {
      this.summary.reset();
    }
  }

  registerOnChange(fn: (_: SummaryFormModel) => {}): void {
    this.propagateChange = fn;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.validatorChange = fn;
  }
  // eslint-disable-next-line
  registerOnTouched(fn: (_: SummaryFormModel) => {}): void {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  // eslint-disable-next-line
  validate(control: AbstractControl): ValidationErrors | null {
    return this.summary.invalid ? {summary: false} : null;
  }
}
