import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OfferOrderFormModel } from '../../models/wizard-form-models/offer-order-form.model';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { FormBuilderHelper } from '../../../dynamic-forms/form-builder.helper';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sebu-offer-order-details',
  templateUrl: './offer-order-details.component.html',
  styleUrls: ['./offer-order-details.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OfferOrderDetailsComponent),
    multi: true
  }]
})
export class OfferOrderDetailsComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {

  @Input()
  public controlConfiguration: ControlConfiguration[] = [];

  public offerOrderDetailsFormGroup: UntypedFormGroup;
  private offerOrderFormSubscription: Subscription;

  private componentDestroyed$: Subject<void> = new Subject<void>();

  public propagateChange = (_: OfferOrderFormModel) => {
  }

  ngOnInit(): void {
    this.offerOrderDetailsFormGroup = FormBuilderHelper.group(this.controlConfiguration);
    this.offerOrderFormSubscription = this.offerOrderDetailsFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$), debounceTime(600)).subscribe(() => this.propagateOfferOrder());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlConfiguration) {

      const productControlConfiguration: ControlConfiguration[] = changes.controlConfiguration.currentValue as ControlConfiguration[];
      // eslint-disable-next-line
      if (!!this.offerOrderFormSubscription) {
        this.offerOrderFormSubscription.unsubscribe();
      }

      this.offerOrderDetailsFormGroup = FormBuilderHelper.group(productControlConfiguration);
      this.offerOrderFormSubscription = this.offerOrderDetailsFormGroup.valueChanges
        .pipe(takeUntil(this.componentDestroyed$), debounceTime(600)).subscribe(() => this.propagateOfferOrder());
      this.propagateOfferOrder();
    }
  }

  // eslint-disable-next-line
  writeValue(offerOrderDetailsModel: OfferOrderFormModel): void {
  }

  registerOnChange(fn: (_: OfferOrderFormModel) => {}): void {
    this.propagateChange = fn;
  }

  propagateOfferOrder(): void {
    const offerOrderDetails: OfferOrderFormModel = this.offerOrderDetailsFormGroup.getRawValue();
    this.propagateChange(this.offerOrderDetailsFormGroup.valid ? offerOrderDetails : undefined);
  }

  // eslint-disable-next-line
  registerOnTouched(fn: string): void {
  }
}
