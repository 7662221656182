import { FileExtension } from '../../wizard/models/file-extension';
import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';
import { FileExtensionHelper } from '../../shared/helper/file-extension-helper';

export class ImageUploadControlConfiguration extends ControlConfiguration{
  public imageUploadOptions: ImageUploadOptions;
  private readonly defaultUploadOptions: ImageUploadOptions = {
    edit: true,
    allowedFileTypes: FileExtensionHelper.BITMAP,
    maxFileSize: 20,
  };

  constructor(options: ImageUploadControlConfigurationArgs) {
    super(ControlType.LOGO, options);
    this.imageUploadOptions = Object.assign({}, this.defaultUploadOptions, options.imageUploadOptions);
  }
}

export interface ImageUploadControlConfigurationArgs extends ControlConfigurationArgs {
  imageUploadOptions: ImageUploadOptions;
}

export interface ImageUploadOptions {
  allowedFileTypes?: FileExtension[];
  download?: boolean;
  edit?: boolean;
  filterSharpen?: number;
  forceSize?: string;
  label?: string;
  maxFileSize?: number;
  minSize?: { width: number, height: number };
  ratio?: string;
  outputSize?: { width: number, height: number };
}
