import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  Attachment,
  OfferService,
  ProductEnum,
  SebuOfferOrderCreate,
  SebuRecordLocation,
  SebuResellerData
} from '../../api/client';
import { ProductMapperHelper } from '../../core/utils/mapping-helper/product-mapper.helper';
import { ControlConfiguration } from '../../dynamic-forms/models/control-configuration';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { AvailableProduct } from '../models/available-product.model';
import { ProductConfiguration } from '../models/product-configuration/product-configuration';
import { RegioOnlineOnlyTemplateConfiguration } from '../models/standard-layout-configuration/regio-online-only-standard-layout-template-configuration';
import { UiStateModel } from '../models/ui-state.model';
import { CompanyFormModel } from '../models/wizard-form-models/company-form.model';
import { ProductFormModel } from '../models/wizard-form-models/product-form.model';
import { SummaryFormModel } from '../models/wizard-form-models/summary-form.model';
import { WizardFormModel } from '../models/wizard-form-models/wizard-form.model';
import {
  FetchPreview,
  LoadProductsAndAddons,
  SaveOrderFormConfiguration
} from '../ngrx/actions';
import { getAvailableProducts, getPreviewUrlSelector, getUiStateSelector } from '../ngrx/reducer/selectors';
import { WizardState } from '../ngrx/reducer/wizard-state.model';
import { FileReaderService } from '../services/file-reader.service';
import { OrderPrice } from '../models/wizard-form-models/order-price.model';
import { SummaryProductModel } from '../models/wizard-form-models/summary-product.model';
import { TenantSettingsHelper } from '../../tenant-settings/tenant-settings.helper';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Product } from '../../core/models/product.model';
import { OfferOrderFormModel } from '../models/wizard-form-models/offer-order-form.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserNotifyService } from '../../core/services/user-notify/user-notify.service';
import { ResellerCachingService } from '../../core/services/reseller-caching-service/reseller-caching.service';
import { MatStepper } from '@angular/material/stepper';
import { TenantTextHelper } from '../../tenant-texts/tenant-text.helper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ScrollEventHelper } from '../../shared/helper/scroll-event-helper';

@Component({
  selector: 'sebu-offer-order-container',
  templateUrl: './offer-order-container.component.html',
  styleUrls: ['./offer-order-container.component.scss']
})
export class OfferOrderContainerComponent implements OnDestroy, OnInit, AfterViewInit  {
  public offerOrderContainerFormGroup: UntypedFormGroup;
  public availableProducts$: Observable<AvailableProduct[]>;
  public previewUrl$: Observable<string>;
  public priceModel: OrderPrice = { topJobPrice: 0, tableEntryPrice: 0, durationPrice: 0, refreshPrice: 0, vatRate: 0, socialMediaPrice: 0 };

  /*stepper configuration*/
  public linearStepper: boolean = environment.linearStepper;
  public disableAnimationsForStepper = true;

  /*Product config*/
  public productControlConfiguration: ControlConfiguration[] = [];
  public standardLayoutConfiguration: ControlConfiguration[] = new RegioOnlineOnlyTemplateConfiguration().formConfiguration;

  /*ui state*/
  public isLoading = false;
  public willLooseData = false;
  public currentSelectedProduct: Product = {};
  public companyName = '';
  public offerOrderFormValue: OfferOrderFormModel = {};
  public uiState$: Observable<UiStateModel>;
  public isProductSelectionStep = true;
  public sebuResellerData$: Observable<SebuResellerData>;
  public paymentEnabled: boolean;

  public readonly productFieldName: string = 'product';
  public readonly companyFieldName: string = 'company';
  public readonly offerOrderFieldName: string = 'offerOrder';
  public readonly standardLayoutFieldName: string = 'standardLayout';
  public readonly summaryFieldName: string = 'summary';
  public routeParam: string;
  public companyPageUrl: string;
  public companyPageLinkName: string;

  private readonly indexOfStepTemplateDetails: number = 4;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  /*
  Make sure the order matches the order in the HTML-template
   */
  private steps: Array<string> = [this.productFieldName, this.offerOrderFieldName, this.standardLayoutFieldName, this.companyFieldName, this.summaryFieldName];
  private scrollEventSubscription: Subscription;
  private resizeEventSubscription: Subscription;

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly store: Store<WizardState>,
              private readonly fileReaderService: FileReaderService,
              private readonly offerService: OfferService,
              private readonly userNotifyService: UserNotifyService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private resellerCachingService: ResellerCachingService,
              private scrollEventHelper: ScrollEventHelper) {
  }

  public ngOnInit(): void {
    this.companyPageUrl = TenantSettingsHelper.getSettings().buttonProductInfoCustomExternalLink;
    this.companyPageLinkName = (TenantTextHelper.findTextByKey('buttonProductInfo') as string);
    this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.routeParam = paramMap.get('preselected');
    });
    this.availableProducts$ = this.store.select(getAvailableProducts);
    this.uiState$ = this.store.select(getUiStateSelector);
    this.previewUrl$ = this.store.select(getPreviewUrlSelector);

    this.offerOrderContainerFormGroup = this.buildForm();
    const productChanges$: Observable<ProductFormModel> = this.offerOrderContainerFormGroup.get(this.productFieldName).valueChanges;
    productChanges$.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((product: ProductFormModel) => {
        if (this.currentSelectedProduct.productEnum !== product.productEnum) {
          this.propagateProductConfiguration(product);
        }
      });

    const companyChanges$: Observable<CompanyFormModel> = this.offerOrderContainerFormGroup.get(this.companyFieldName).valueChanges;
    companyChanges$
      .pipe(takeUntil(this.componentDestroyed$),
        filter(company => !!company))
      .subscribe((company: CompanyFormModel) => this.companyName = company.companyName);

    const offerOrderModel$: Observable<CompanyFormModel> = this.offerOrderContainerFormGroup.get(this.offerOrderFieldName).valueChanges;
    offerOrderModel$
      .pipe(takeUntil(this.componentDestroyed$),
        filter(offer => !!offer))
      .subscribe((offerOrder: OfferOrderFormModel) => this.offerOrderFormValue = offerOrder);

    const publicationDateChanges$: Observable<SummaryProductModel> = this.offerOrderContainerFormGroup
      .get(this.summaryFieldName).valueChanges;
    publicationDateChanges$.pipe(takeUntil(this.componentDestroyed$)).subscribe((changes: SummaryProductModel) => {
      if (changes) {
        this.getPreviewForSummary(this.indexOfStepTemplateDetails);
      }
    });

    combineLatest(productChanges$, this.availableProducts$)
      .pipe(takeUntil(this.componentDestroyed$),
        // eslint-disable-next-line
        filter(([product, productConfigs]: [ProductFormModel, AvailableProduct[]]) => !!productConfigs))
      .subscribe(([product, productConfigs]: [ProductFormModel, AvailableProduct[]]) => this.priceModel = OrderPrice
        .createFromProductConfig(productConfigs.find(config => config.productEnum === product.productEnum), product.duration));

    this.availableProducts$
      .pipe(takeUntil(this.componentDestroyed$),
        filter(availableProducts => !!availableProducts && !!availableProducts.length))
      .subscribe(availableProducts => {
        // Preselect the product given from the url or the default defined in the settings. If none available, we use the first product in available products
        const preselectedProduct: AvailableProduct =
          this.routeParam && availableProducts.find(p => (p.productName && p.productName.toLowerCase()) ===
          (this.routeParam && this.routeParam.toLowerCase())) ||
          availableProducts.find(product => product.productEnum === TenantSettingsHelper.getSettings().preselectedProduct)
          || availableProducts[0];

        // always preselect the first available duration of the preselected product
        this.offerOrderContainerFormGroup.get(this.productFieldName).setValue(preselectedProduct.availableDurations[0].value);
      });

    this.store.dispatch(new LoadProductsAndAddons());

    this.sebuResellerData$ = this.resellerCachingService.getReseller();
    this.sebuResellerData$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(reseller => {
        this.paymentEnabled = reseller.paymentEnabled;
      });

    this.scrollEventSubscription = this.scrollEventHelper
      .getScrollEvent()
      .subscribe((data) => {
        this.handleScroll(data.position);
      });

      this.resizeEventSubscription = this.scrollEventHelper
          .getResizeEvent()
          .subscribe((data) => {
              this.handleWindowResize();
          });
  }

  ngAfterViewInit (){
    const domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(domStepperPlaceHolder.length === 0){
      this.addStepperWrapper();
    }
  }

  //event is being listened to by another component
  handleScroll(scrollPosition: number){
    const headerHeight = document.querySelectorAll('.header-toolbar')[0].clientHeight;
    const windowScroll = window.scrollY + headerHeight;

    if(windowScroll < scrollPosition){
      this.addStickyClassToStepper();
    }
    if(windowScroll >= scrollPosition){
      this.removeStickyClassFromStepper();
    }
  }

    private handleWindowResize(){
        const domStepperWrapper = document.querySelectorAll('.mat-horizontal-stepper-wrapper');
        const width = domStepperWrapper[0].clientWidth;
        const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
        domStepper[0].setAttribute('style', `width:${width}px;`);
    }

  public propagateAllSteps(stepper: StepperSelectionEvent): void {
    this.isProductSelectionStep = stepper.selectedIndex === 0;
    this.willLooseData = true;
    this.getPreviewForSummary(stepper.selectedIndex);
    window.scrollTo(0, 0);
  }

  public sendOfferOrder(): void {
    this.isLoading = true;
    const formModel: WizardFormModel = {
      product: this.offerOrderContainerFormGroup.get(this.productFieldName).value,
      company: this.offerOrderContainerFormGroup.get(this.companyFieldName).value,
      offerOrder: this.offerOrderContainerFormGroup.get(this.offerOrderFieldName).value,
      standardLayout: (this.offerOrderContainerFormGroup.get(this.standardLayoutFieldName) as UntypedFormControl) ?
        this.offerOrderContainerFormGroup.get(this.standardLayoutFieldName).value : null,
      summary: this.offerOrderContainerFormGroup.get(this.summaryFieldName).value
    };

    const attachment: Attachment = formModel.offerOrder.offerOrderAttachment ? formModel.offerOrder.offerOrderAttachment : formModel.standardLayout.attachment;

    if (attachment) {
      if (attachment instanceof File){
        this.fileReaderService.convertFileToAttachment(attachment as File)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((resolvedAttachment: Attachment) => {
            const sebuOfferOrderCreate: SebuOfferOrderCreate =
              ProductMapperHelper.mapWizardFormModelsToSebuOfferOrderCreateModel(formModel, resolvedAttachment, this.priceModel);
            this.createOfferOrder(sebuOfferOrderCreate);
          });
      }else{
        const sebuOfferOrderCreate: SebuOfferOrderCreate =
          ProductMapperHelper.mapWizardFormModelsToSebuOfferOrderCreateModel(formModel, attachment, this.priceModel);
        this.createOfferOrder(sebuOfferOrderCreate);
      }
    } else {
      const sebuOfferOrderCreate: SebuOfferOrderCreate = ProductMapperHelper
        .mapWizardFormModelsToSebuOfferOrderCreateModel(formModel, null, this.priceModel);
      this.createOfferOrder(sebuOfferOrderCreate);
    }
  }

  public propagateProductConfiguration(product: Product): void {
    setTimeout(() => {
      const productConfiguration: ProductConfiguration = ProductMapperHelper.mapProductToConfiguration(product.productEnum);
      this.currentSelectedProduct = product;
      this.offerOrderContainerFormGroup = this.handleStandardLayoutForm(this.currentSelectedProduct, this.offerOrderContainerFormGroup);
      this.productControlConfiguration = productConfiguration.formConfiguration;
      this.store.dispatch(new SaveOrderFormConfiguration(productConfiguration));
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.scrollEventSubscription.unsubscribe();
    this.resizeEventSubscription.unsubscribe();
  }

  public goToStep(stepControlIdentifier: string, stepper: MatStepper): void {
    if (this.hasStandardLayout(this.currentSelectedProduct.productEnum)) {
      stepper.selectedIndex = this.steps.findIndex(value => value === stepControlIdentifier);
    } else {
      stepper.selectedIndex = this.steps
        .filter(steps => steps !== this.standardLayoutFieldName)
        .findIndex(value => value === stepControlIdentifier);
    }
  }

  private addStepperWrapper(){

    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'stepperPlaceHolder');

    const domRepresentation = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    if(domRepresentation && domRepresentation[0]){
      domRepresentation[0].insertAdjacentElement('afterend', newDiv)
    }
  }

  private addStickyClassToStepper(): void {
    const sticky = "sticky";

    const domStepperWrapper = document.querySelectorAll('.mat-horizontal-stepper-wrapper');
    const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    this.adjustPlaceHolderForSticky(domStepper);

    const width = domStepperWrapper[0].clientWidth;
    domStepper[0].setAttribute('style', `width:${width}px;`);
    domStepper[0].classList.add(sticky);
  }

  private adjustPlaceHolderForSticky(domStepper: any){
    const domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(domStepperPlaceHolder && domStepperPlaceHolder[0]){
      const height = domStepper[0].clientHeight;
      const margin = parseInt(window.getComputedStyle(domStepper[0]).getPropertyValue('margin-bottom'))

      domStepperPlaceHolder[0].setAttribute('style', `height:${height}px; margin-bottom:${margin}px;`);
    }
  }

  private removeStickyClassFromStepper(): void {
    const sticky = "sticky";

    const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    this.removStyleFromPlaceHolder();

    domStepper[0].removeAttribute('style');
    domStepper[0].classList.remove(sticky);
  }

  private removStyleFromPlaceHolder(){
    const domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(domStepperPlaceHolder.length > 0){
      domStepperPlaceHolder[0].removeAttribute('style');
    }
  }

  private buildForm(): UntypedFormGroup {
    // every form needs to be initialized empty/undefined
    // in order to be invalid in the beginning (validity is checked through the required validator)
    const offerOrderForm: UntypedFormGroup = this.formBuilder.group({
      product: [new ProductFormModel(), Validators.required],
      company: ['', Validators.required],
      offerOrder: ['', Validators.required],
      summary: [new SummaryFormModel()]
    });
    return this.handleStandardLayoutForm(this.currentSelectedProduct, offerOrderForm);
  }

  private handleStandardLayoutForm(selectedProduct: Product, offerOrderForm: UntypedFormGroup): UntypedFormGroup {
    if (this.hasStandardLayout(selectedProduct.productEnum)) {
      offerOrderForm.addControl(this.standardLayoutFieldName, new UntypedFormControl('', Validators.required));
    } else {
      offerOrderForm.removeControl(this.standardLayoutFieldName);
    }
    return offerOrderForm;
  }

  private getPreviewForSummary(index: number): void {
    if (this.hasStandardLayout(this.currentSelectedProduct.productEnum) && index === 4 && this.offerOrderContainerFormGroup.valid) {
      this.store.dispatch(new FetchPreview(this.offerOrderContainerFormGroup.getRawValue()));
    }
  }

  private hasStandardLayout(productEnum: ProductEnum): boolean {
    return productEnum === ProductEnum.RegioOnlineOnlyStandardlayout || productEnum === ProductEnum.RegioOnlineOnlyAzubi || productEnum == ProductEnum.RegioOnlineOnlyMinijobs;
  }

  private createOfferOrder(offerOrderCreate: SebuOfferOrderCreate): void {
    this.offerService.createOfferOrder(offerOrderCreate)
      .pipe(switchMap((result: SebuRecordLocation) =>
        // after successful creation, navigate to offerOrder confirmation page
        this.router.navigate([`confirm/${result.date}/${result.token}`])))
      .pipe(catchError(() => {
        this.userNotifyService.error(TenantTextPipe.transform('error') as string);
        this.isLoading = false;
        return of({});
      }))
      .subscribe();
  }
}
function useEffect(arg0: () => void, arg1: undefined[]) {
    throw new Error('Function not implemented.');
}

