import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../core/core.module';
import { SebuFormComponentsModule } from '../form-components/sebu-form-components.module';
import { SharedModule } from '../shared/shared.module';
import { ProductSelectionModule } from '../wizard/components/product-selection/product-selection.module';
import { SummaryModule } from '../wizard/components/summary/summary.module';
import { ConfirmationPageComponent } from './components/confirmation-page.component';
import { ConfirmationProductComponent } from './components/product/confirmation-page-product.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ConfirmationPageEffects } from './ngrx/effects';
import { confirmationPageReducer } from './ngrx/reducer';
import { NgxStripeModule } from 'ngx-stripe';
import { PrivacyStatementComponent } from '../wizard/components/summary/privacy-statement/privacy-statement.component';
import { ThankYouPageComponent } from '../thank-you-page/thank-you-page.component';

@NgModule({
  imports: [
    SebuFormComponentsModule,
    SharedModule,
    CoreModule,
    ProductSelectionModule,
    SummaryModule,
    StoreModule.forFeature('confirmationPage', confirmationPageReducer),
    EffectsModule.forFeature([ConfirmationPageEffects]),
    NgxStripeModule.forRoot(),
  ],
  declarations: [
    ConfirmationPageComponent,
    ConfirmationProductComponent,
    CheckoutComponent,
    PrivacyStatementComponent,
    ThankYouPageComponent,
  ],
  exports: [
    ConfirmationPageComponent,
    ConfirmationProductComponent,
    CheckoutComponent,
  ]
})

export class ConfirmationPageModule {

}
