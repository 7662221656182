import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DynamicFormsModule } from '../../../dynamic-forms/dynamic-forms.module';
import { SebuFormComponentsModule } from '../../../form-components/sebu-form-components.module';
import { StandardLayoutDetailsComponent } from './standard-layout-details.component';

@NgModule({
  imports: [
    SharedModule,
    SebuFormComponentsModule,
    DynamicFormsModule
  ],
  declarations: [
    StandardLayoutDetailsComponent
  ],
  exports: [StandardLayoutDetailsComponent]
})
export class StandardLayoutDetailsModule { }
