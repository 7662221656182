import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { Attachment } from '../../api/client';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  public fileReader: FileReader;

  constructor() {
    this.fileReader = new FileReader();
  }

  public convertFileToAttachment(file: File): Observable<Attachment> {
    return new Observable((observer: Observer<Attachment>) => {
      this.fileReader.onloadend = () => {
        const uploadedFile: Attachment = {
          name: file.name,
          bytes: (<string>this.fileReader.result).split(',')[1]
        };
        observer.next(uploadedFile);
      };
      this.fileReader.readAsDataURL(file);
    });
  }
}
