<form [formGroup]="standardLayoutDetailsFormGroup" fxLayout="column" fxLayoutAlign="space-between">
  <div fxLayout="column" class="inline-form"
    [ngStyle]="{'border-color': colorPicker.color}">
    <div fxLayout="column">
      <div fxLayout="row" class="header-row">
        <div class="element image-upload">
          <sebu-image-upload [options]="filterControlConfiguration(FormControlNameEnum.htmlTemplateLogo).imageUploadOptions"
                             [formControlName]="FormControlNameEnum.htmlTemplateLogo"></sebu-image-upload>
        </div>
        <div class="element">
         <p class="label">Firma:</p>
          <sebu-form-control-factory formControlClass="company-name"
                                     [control]="filterControlConfiguration(FormControlNameEnum.htmlTemplateCompanyName)"
                                     [form]="standardLayoutDetailsFormGroup"></sebu-form-control-factory>
        </div>
      </div>
      <div>
        <p>Wir suchen für unseren Standort in <span class="location">{{offerOrderValue?.location?.valueToDisplay}}</span>:</p>
        <h1>{{offerOrderValue?.title}}</h1>
      </div>
    </div>
    <div class="form-body">
      <sebu-form-control-factory [control]="filterControlConfiguration(FormControlNameEnum.htmlTemplateBodyFormGroup)"
                                 [form]="standardLayoutDetailsFormGroup"
                                 formControlClass="inline-edit">
      </sebu-form-control-factory>
      <div class="contact-links">
        <p *ngIf="offerOrderValue?.applicationEmail" [ngStyle]="{'color': colorPicker.color}"><i class="far fa-envelope"></i>{{offerOrderValue?.applicationEmail}}</p>
      </div>
      <sebu-form-control-factory [control]="filterControlConfiguration(FormControlNameEnum.htmlTemplateJobApplyContactFormGroup)"
                                 [form]="standardLayoutDetailsFormGroup"
                                 formControlClass="inline-edit">
      </sebu-form-control-factory>
      <div  class="button-container" *ngIf="offerOrderValue?.applicationUrl">
        <a class="apply-button" matTooltip="{{offerOrderValue?.applicationUrl}}" [ngStyle]="{'background-color': colorPicker.color}">Jetzt bewerben</a>
      </div>
    </div>
  </div>
  <div  fxLayout="column" fxLayoutAlign="start left" class="inline-form" fxLayoutGap="20px" [ngStyle]="{'border-color': colorPicker.color}">
    <div fxLayout="column" fxLayoutAlign="space-between" class="color-picker-info"><span sebuTenantText>colorPickerInfo</span></div>
    <div fxLayout="column" fxLayoutAlign="space-between" class="color-picker-row">
      <sebu-color-picker #colorPicker [formControlName]="FormControlNameEnum.htmlTemplateColorPicker" ngDefaultControl></sebu-color-picker>
    </div>
     </div>
</form>
