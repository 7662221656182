import { AppConfig } from '../../../app.config';

export class ErrorHelper {
  static throwError(message: string): void {
    if (!AppConfig.environment.production) {
      throw new Error(message);
    } else {
      console.log(message);
    }
  }
}
