import { NgModule } from '@angular/core';
import { CustomErrorsDirective } from '../core/directives/custom-errors.directive';
import { TenantTextsModule } from '../tenant-texts/tenant-texts.module';
import { DurationIsoPipe } from './pipes/duration.iso.pipe';
import { ActionPipe } from './pipes/action.pipe';
import { SafeHtmlPipe} from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-Url.pipe';

@NgModule({
  imports: [
    TenantTextsModule
  ],
  declarations: [
    DurationIsoPipe,
    ActionPipe,
    CustomErrorsDirective,
    SafeHtmlPipe,
    SafeUrlPipe
  ],
  exports: [
    TenantTextsModule,
    DurationIsoPipe,
    ActionPipe,
    CustomErrorsDirective,
    SafeHtmlPipe,
    SafeUrlPipe
  ]
})
export class SebuDirectivesAndPipesModule {
}
