export class CompanyFormModel {

  companyName: string;
  telephoneNumber: string;
  email: string;
  homepage?: string;
  street?: string;
  postOffice?: string;
  city: string;
  postcode: string;
  country?: string;
  salutation?: string;
  title?: string;
  firstName: string;
  lastName: string;

  constructor() {
    this.companyName = '';
    this.telephoneNumber = '';
    this.email = '';
    this.homepage = '';
    this.street = '';
    this.postOffice = '';
    this.city = '';
    this.postcode = '';
    this.country = '';
    this.salutation = '';
    this.title = '';
    this.firstName = '';
    this.lastName = '';
  }
}
