import { NgModule } from '@angular/core';
import { SebuFormComponentsModule } from '../form-components/sebu-form-components.module';
import { SharedModule } from '../shared/shared.module';
import { FormControlFactoryComponent } from './forms-factory/form-control-factory/form-control-factory.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReplaceErrorsForUploadPipe } from './helper/replace-errors-for-upload.pipe';


@NgModule({
  imports: [
    SharedModule,
    SebuFormComponentsModule,
  ],
  declarations: [
    FormControlFactoryComponent,
    ReplaceErrorsForUploadPipe,
  ],
  exports: [
    FormControlFactoryComponent,
    ReactiveFormsModule,
  ]
})

export class DynamicFormsModule {}
