import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';

export  class HeaderControlConfiguration extends ControlConfiguration{
  public header: string;

  constructor(options: HeaderControlConfigurationArgs) {
    super(ControlType.HEADER, options);
    this.header = options.header;
  }
}

export interface HeaderControlConfigurationArgs extends ControlConfigurationArgs {
  header: string;
}
