import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ImageUploadOptions } from '../../../dynamic-forms/models/image-upload-control-configuration';
import { ArrayHelper, KeyValuePair } from '../../../shared/helper/array.helper';
import { SlimSettings, SlimUploadResponse } from '../../../shared/image-cropper/slim-settings.model';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { takeUntil } from 'rxjs/operators';
import { CustomValidators } from '../../../shared/helper/custom-validation.helper';

export class Logo {

  constructor(bytes: string, name: string, type: string, size?: number) {
    this.bytes = bytes;
    this.name = name;
    this.type = type;
    this.size = size || 0;
  }

  bytes: string;
  name: string;
  type: string;
  size: number;
}

@Component({
  selector: 'sebu-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageUploadComponent),
    multi: true
  }]
})
export class ImageUploadComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input()
  public options: ImageUploadOptions;

  public cropperOptions: SlimSettings;
  public allowedFileTypes: string;

  private fileChanges$: Subject<Logo> = new Subject<Logo>();
  private readonly defaultSettings: SlimSettings = {
    service: this.uploadedFile.bind(this),
    didRemove: this.removed.bind(this),
    serviceFormat: 'file',
    didLoad: this.validateFileName.bind(this),
    buttonCancelLabel: TenantTextPipe.transform('imageCropperButtonCancelLabel') as string,
    buttonCancelTitle: TenantTextPipe.transform('imageCropperButtonCancelTitle') as string,
    buttonConfirmLabel: TenantTextPipe.transform('imageCropperButtonConfirmLabel') as string,
    buttonConfirmTitle: TenantTextPipe.transform('imageCropperButtonConfirmTitle') as string,
    buttonDownloadLabel: TenantTextPipe.transform('imageCropperButtonDownloadLabel') as string,
    buttonDownloadTitle: TenantTextPipe.transform('imageCropperButtonDownloadTitle') as string,
    buttonEditLabel: TenantTextPipe.transform('imageCropperButtonEditLabel') as string,
    buttonEditTitle: TenantTextPipe.transform('imageCropperButtonEditTitle') as string,
    buttonRemoveLabel: TenantTextPipe.transform('imageCropperButtonRemoveLabel') as string,
    buttonRemoveTitle: TenantTextPipe.transform('imageCropperButtonRemoveTitle') as string,
    buttonRotateLabel: TenantTextPipe.transform('imageCropperButtonRotateLabel') as string,
    buttonRotateTitle: TenantTextPipe.transform('imageCropperButtonRotateTitle') as string,
    buttonUploadLabel: TenantTextPipe.transform('imageCropperButtonUploadLabel') as string,
    buttonUploadTitle: TenantTextPipe.transform('imageCropperButtonUploadTitle') as string,
    label: TenantTextPipe.transform('imageCropperLabel') as string,
    statusContentLength: TenantTextPipe.transform('imageCropperStatusContentLength') as string,
    statusFileSize: TenantTextPipe.transform('imageCropperStatusFileSize') as string,
    statusFileType: TenantTextPipe.transform('imageCropperStatusFileType') as string,
    statusImageTooSmall: TenantTextPipe.transform('imageCropperStatusImageTooSmall') as string,
    statusNoSupport: TenantTextPipe.transform('imageCropperStatusNoSupport') as string,
    statusUnknownResponse: TenantTextPipe.transform('imageCropperStatusUnknownResponse') as string,
    statusUploadSuccess: TenantTextPipe.transform('imageCropperStatusUploadSuccess') as string,
    instantEdit: true,
    push: true,
  };
  private componentDestroyed$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    const optionsToApply: ImageUploadOptions = _.cloneDeep(this.options);
    this.allowedFileTypes = optionsToApply.allowedFileTypes ? ArrayHelper.toKeyValueArray(optionsToApply.allowedFileTypes)
      .map((v: KeyValuePair) => `.${v.value}`).join(', ') : null;
    delete optionsToApply.allowedFileTypes;
    const extraOptions: SlimSettings = {
      forceType: !!optionsToApply.allowedFileTypes,
      forceMinSize: !!optionsToApply.minSize
    };
    this.cropperOptions = Object.assign({}, this.defaultSettings, optionsToApply, extraOptions);
  }

  // eslint-disable-next-line
  writeValue(file: File): void {
    // Not editable
  }

  registerOnChange(fn: (f: Logo) => {}): void {
    this.fileChanges$.pipe(takeUntil(this.componentDestroyed$)).subscribe(changes => fn(changes));
  }

  // eslint-disable-next-line
  registerOnTouched(fn: File): void {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public uploadedFile(data: Object, progress: (current: number, total: number) => void,
                      // eslint-disable-next-line
                      success: (response: SlimUploadResponse | string) => void, failure: (error: string) => void): void {
    progress(50, 100);
    const file: File = data[0] as File;
    const response: SlimUploadResponse = {
      status: 'success',
      name: file.name,
      path: ''
    };
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = () => {
      const logo: Logo = {
        bytes: (<string>fileReader.result).split(',')[1],
        name: file.name,
        type: file.type,
        size: file.size
      };
      this.fileChanges$.next(logo);
      progress(100, 100);
      success(response);
    };
    fileReader.readAsDataURL(file);
  }

  // eslint-disable-next-line
  public removed(status: Object): void {
    this.fileChanges$.next(null);
  }

  private validateFileName(status: Object): string | boolean {
    return (CustomValidators.hasInvalidCharacters(status['name'])) ? TenantTextPipe.transform('illegalCharacter') as string : true;
  }
}
