/**
 * Sa.Sebu.Api
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The offer location type.
 */
export type OfferLocationTypeEnum = 'Ort' | 'Bundesland' | 'Land';

export const OfferLocationTypeEnum = {
    Ort: 'Ort' as OfferLocationTypeEnum,
    Bundesland: 'Bundesland' as OfferLocationTypeEnum,
    Land: 'Land' as OfferLocationTypeEnum
}
