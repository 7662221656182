<div [ngClass]="{'compressed': compressedDisplay}"
     class="category-tree"
     fxLayout="{{compressedDisplay ? 'column': 'column'}}"
     fxLayoutGap="1.1em">
  <div class="category-item" fxLayout="row" fxLayoutGap="0.5em"
       *ngFor="let subcategory of selectedSubCategories; let j = index">
    <div class="number-circle" fxFlex="2em">{{j + 1}}</div>
    <div class="category-text">
      <span class="subcategory-label"> {{subcategory.label}}</span>
      <div *ngFor="let category of (getRubrics(subcategory.categoryGroupId) | async)">
        <span class="category-label">{{category}}</span>
      </div>
    </div>
    <div *ngIf="!compressedDisplay">
      <button mat-icon-button color="warn" matTooltip="{{'deleteSubCategoryToolTip' | TenantTextPipe}}"
              (click)="removeSubCategoryAction(subcategory)"
              fxFlex="2em">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
