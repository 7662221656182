import {
  AfterViewChecked, ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild, ViewContainerRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';
import { Observable ,  Subject } from 'rxjs';
import { AttachmentControlConfiguration } from '../../../dynamic-forms/models/attachment-control-configuration';
import { FileExtensionHelper } from '../../../shared/helper/file-extension-helper';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ZipUploadDialogComponent } from '../../../shared/attachment-upload/zip-upload/zip-upload-dialog.component';
import { AttachmentModel } from '../../../shared/attachment-upload/attachment-model';
import * as _ from 'lodash';

@Component({
  selector: 'sebu-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
  }]
})

export class FileUploadComponent implements OnInit, AfterViewChecked, ControlValueAccessor, OnDestroy {

  @Input()
  public controlConfiguration: AttachmentControlConfiguration;

  @Input()
  public icon: string;

  @Input()
  public delete: string;

  @Input()
  public label: string;

  @ViewChild('fileUpload', { static: true })
  public fileUpload: ElementRef;

  public warningMessage = '';

  public file: File;
  public dialogRef: MatDialogRef<ZipUploadDialogComponent>;
  public attachment: AttachmentModel;
  public allowedFileTypeHint: string;
  public fileReader: FileReader = new FileReader();

  private changesToPropagate$: Observable<AttachmentModel>;
  private componentDestroyed$: Subject<void> = new Subject<void>();
  private attachmentChanges$: Subject<AttachmentModel> = new Subject<AttachmentModel>();
  private allowedFileTypes: string[];

  constructor(private viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.changesToPropagate$ = this.attachmentChanges$;
    this.allowedFileTypes = this.getAllowedFileTypesAsStringList();
    this.allowedFileTypeHint = this.getAllowedFileTypesHint();
  }

  // eslint-disable-next-line
  writeValue(file: File): void {
    // Not editable
  }

  registerOnChange(fn: (f: AttachmentModel) => {}): void {
    this.changesToPropagate$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(changes => fn(changes));
  }

  // eslint-disable-next-line
  registerOnTouched(fn: File): void {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public openZipAttachmentDialog(): void{
    const config: MatDialogConfig = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    this.dialogRef = this.dialog.open(ZipUploadDialogComponent, config);
    this.dialogRef.componentInstance.attachment = this.attachment;
    this.dialogRef.componentInstance.attachmentCallback = this.setAttachmentCallback;
    this.dialogRef.componentInstance.allowedFileTypes = this.getAllowedFileTypes();
    this.dialogRef.componentInstance.allowedFileTypeHint = this.allowedFileTypeHint;
    this.dialogRef.beforeClosed().pipe().subscribe();
  }

  public setAttachmentCallback = (attachment: AttachmentModel): void => {
    this.setAttachment(attachment);
  }

  public uploadFile(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files[0];
    if (!file) {
      return;
    }
    const validationErrors: ValidationErrors = this.isFileInputValid(file, this.controlConfiguration.validators);
    if (!validationErrors) {
      this.fileReader.onloadend = () => {
        this.file = file;
        const uploadedFile: AttachmentModel = {
          name: file.name,
          bytes: this.fileReader.result.toString().split(',')[1],
          file: file
        };
        this.setAttachment(uploadedFile);
      };
      this.fileReader.readAsDataURL(file);
    } else {
      /*eslint-disable no-extra-boolean-cast*/
      if (!!validationErrors.invalidChars) {
        this.warningMessage = TenantTextPipe.transform('illegalCharacter') as string;
      } else if (!!validationErrors.invalidFileType){
        this.warningMessage = TenantTextPipe.transform('invalidFileType') as string;
      } else if (!!validationErrors.exceededFileSize) {
        this.warningMessage = `Die Datei ist zu groß. Maximal 15 MB sind erlaubt.`;
      }
    }
  }

  public deleteAttachment(): void {
    this.setAttachment(null);
  }

  public getAllowedFileTypes(): string {
    return FileExtensionHelper.getFileTypesAsString(this.controlConfiguration.allowedFileTypes);
  }

  public getAllowedFileTypesAsStringList(): string[] {
    return FileExtensionHelper.getFileTypesAsStringList(this.controlConfiguration.allowedFileTypes);
  }

  private createFile(bytes: string, name: string): File {
    const file: File = <File>Object.assign(new Blob([bytes]), {lastModified: Date.now(), name: name});
    return file;
  }

  private getAllowedFileTypesHint(): string {
    const types: string[] = _.cloneDeep(this.allowedFileTypes);
    const fileTypes: string = types.length === 1 ?
      types[0] :
      types.splice(0, types.length - 1).join(', ') + ' oder ' + types[types.length - 1];
    return fileTypes;
  }

  private isFileInputValid(file: File, validators: ValidatorFn[]): ValidationErrors | null {
    const formControl: UntypedFormControl = new UntypedFormControl();
    formControl.setValidators(validators);
    formControl.setValue(file);
    formControl.updateValueAndValidity();
    return formControl.errors;
  }

  private setAttachment(attachment: AttachmentModel): void {
    this.attachment = attachment;
    this.warningMessage = '';
    this.attachmentChanges$.next(attachment);
  }
}
