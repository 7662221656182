<div class="confirm-box">
  <div>
    <h1>{{ title }}</h1>
    <p>{{ message }}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button fxFlex="48%" type="button" mat-raised-button color="{{buttonCancelColor ? buttonCancelColor : 'accent'}}"
            (click)="dialogRef.close(false)">{{ buttonCancel ? buttonCancel : 'Nein' }}</button>
    <button fxFlex="48%" type="button" mat-raised-button color="{{buttonConfirmColor ? buttonConfirmColor : 'accent'}}"
            (click)="dialogRef.close(true)">{{ buttonConfirm ? buttonConfirm : 'Ja' }}</button>
  </div>
</div>
