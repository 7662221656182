<form [formGroup]="offerOrderContainerFormGroup">
  <mat-horizontal-stepper (selectionChange)="propagateAllSteps($event)"
                          class="content"
                          [linear]="linearStepper||true"
                          [@.disabled]="disableAnimationsForStepper" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{index + 1}}
    </ng-template>
    <ng-template matStepperIcon="done" let-index="index">
      {{index + 1}}
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
      {{index + 1}}
    </ng-template>

    <mat-step [stepControl]="offerOrderContainerFormGroup.get(productFieldName)">
      <ng-template matStepLabel>
        <span sebuTenantText>product_selection</span>
      </ng-template>
      <div class="product-selection-container content" *ngIf="(availableProducts$ | async) as availableProducts">
        <sebu-product-selection
          formControlName="{{productFieldName}}"
          [availableProducts]="availableProducts"
          [(willLooseData)]="willLooseData"></sebu-product-selection>
        <div class="button-row">
          <div class="company-page-link-container">
            <mat-icon aria-label="arrow right icon">arrow_right</mat-icon>
            <a class="company-page-link" href="{{companyPageUrl}}" target="_blank">{{companyPageLinkName}}</a>
          </div>
          <button mat-raised-button class="button-next"
                  [disabled]="!this.offerOrderContainerFormGroup.get(productFieldName).valid"
                  matStepperNext
                  sebuTenantText>buttonNext
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="offerOrderContainerFormGroup.get(offerOrderFieldName)">
      <ng-template matStepLabel>
        <span sebuTenantText>offer_order_details</span>
      </ng-template>
      <sebu-offer-order-details
        formControlName="{{offerOrderFieldName}}"
        [controlConfiguration]="productControlConfiguration">
      </sebu-offer-order-details>

      <div class="button-row e2e-offer-order-buttons">
        <button mat-raised-button
                matStepperPrevious
                sebuTenantText>buttonBack
        </button>
        <button mat-raised-button
                [disabled]="!offerOrderContainerFormGroup.get(offerOrderFieldName).valid"
                matStepperNext
                sebuTenantText>buttonNext
        </button>
      </div>
    </mat-step>

    <mat-step *ngIf="offerOrderContainerFormGroup.get(standardLayoutFieldName)"
              [stepControl]="offerOrderContainerFormGroup.get(standardLayoutFieldName)">
      <ng-template matStepLabel>
        <span sebuTenantText>htmlTemplateDetails</span>
      </ng-template>
      <sebu-html-template-details
        formControlName="{{standardLayoutFieldName}}"
        [controlConfiguration]="standardLayoutConfiguration"
        [offerOrderValue]="offerOrderFormValue">
      </sebu-html-template-details>

      <div class="button-row e2e-template-details-buttons">
        <button mat-raised-button
                matStepperPrevious
                sebuTenantText>buttonBack
        </button>
        <button mat-raised-button
                [disabled]="!offerOrderContainerFormGroup.get(standardLayoutFieldName).valid"
                matStepperNext
                sebuTenantText>buttonNext
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="offerOrderContainerFormGroup.get(companyFieldName)">
      <ng-template matStepLabel>
        <span sebuTenantText>company_details</span>
      </ng-template>
      <sebu-company-details
        formControlName="{{companyFieldName}}"></sebu-company-details>
      <div class="button-row e2e-company-details-buttons">
        <button mat-raised-button
                matStepperPrevious
                sebuTenantText>buttonBack
        </button>
        <button mat-raised-button
                [disabled]="!offerOrderContainerFormGroup.get(companyFieldName).valid"
                matStepperNext
                sebuTenantText>buttonNext
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="offerOrderContainerFormGroup.get(summaryFieldName)">
      <ng-template matStepLabel>
        <span sebuTenantText>summary</span>
      </ng-template>

      <sebu-summary formControlName="{{summaryFieldName}}"
                    [formData]="offerOrderContainerFormGroup.getRawValue()"
                    [productControlConfiguration]="productControlConfiguration"
                    [selectedProduct]="currentSelectedProduct"
                    [priceModel]="priceModel"
                    [previewUrl]="(previewUrl$ | async)"
                    [previewIsLoading]="(uiState$ | async).previewLoadingSpinner.isLoading"
                    (editProduct)="goToStep(productFieldName, stepper)"
                    (editCompany)="goToStep(companyFieldName, stepper)"
                    (editOffer)="goToStep(offerOrderFieldName, stepper)"
                    (editStandardLayout)="goToStep(standardLayoutFieldName, stepper)"
                    ngDefaultControl>
      </sebu-summary>
      <div class="button-row e2e-summary-buttons">
        <button mat-raised-button
                matStepperPrevious
                sebuTenantText>buttonBack
        </button>
        <button mat-raised-button class="send-button"
                (click)="sendOfferOrder()"
                [disabled]="!(offerOrderContainerFormGroup.valid && !isLoading)">{{(isLoading ? 'isLoading' : (paymentEnabled ? 'buttonToPaymentMethods' : 'buttonCheck')) | TenantTextPipe}}
        </button>
      </div>
    </mat-step>

    <mat-step *ngIf="paymentEnabled" aria-labelledby="disabled">
      <ng-template matStepLabel>
        <span sebuTenantText>payment</span>
      </ng-template>
    </mat-step>

    <mat-step aria-labelledby="disabled">
      <ng-template matStepLabel>
        <span sebuTenantText>finalize</span>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>

  <sebu-product-description-display
    *ngIf="currentSelectedProduct.productEnum && isProductSelectionStep"
    [product]="currentSelectedProduct">
  </sebu-product-description-display>
</form>

