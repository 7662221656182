<header class="header-footer-full-width">
  <mat-toolbar class="header-toolbar">
    <div class="title">
      <a [attr.href]="logoLink" target="_blank" rel="noopener">
      <img src="./assets/images/logo.png"/>
      </a>
      <h1 *ngIf="showSebuHeader">{{'sebuHeader' | TenantTextPipe}}</h1>
    </div>
    <sebu-info-tooltip [content]="'support' | TenantTextPipe"
                       [icon]="'help_outline'"
                       [label]="'supportLabel' | TenantTextPipe"
                       [initialOpen]="showToolTipOnApplicationLoad"></sebu-info-tooltip>
  </mat-toolbar>
</header>
