import { FileExtension } from './file-extension';

export class FileType {
  public fileExtension: FileExtension;
  public mimeType: string;
}

export const FILE_TYPES: FileType[] = [
  { fileExtension: FileExtension.ZIP, mimeType: 'application/zip' },
  { fileExtension: FileExtension.JPG, mimeType: 'image/jpg' },
  { fileExtension: FileExtension.JPEG, mimeType: 'image/jpeg' },
  { fileExtension: FileExtension.GIF, mimeType: 'image/gif' },
  { fileExtension: FileExtension.TIF, mimeType: 'image/tif' },
  { fileExtension: FileExtension.PNG, mimeType: 'image/png' },
  { fileExtension: FileExtension.BMP, mimeType: 'image/bmp' },
  { fileExtension: FileExtension.PDF, mimeType: 'application/pdf' },
  { fileExtension: FileExtension.SVG, mimeType: 'image/svg+xml' },
  { fileExtension: FileExtension.PSD, mimeType: 'image/vnd.adobe.photoshop' },
  { fileExtension: FileExtension.CSV, mimeType: 'text/comma-separated-values' },
  { fileExtension: FileExtension.RAR, mimeType: 'application/x-rar-compressed' },
  { fileExtension: FileExtension.EPS, mimeType: 'application/postscript' },
  { fileExtension: FileExtension.PS, mimeType: 'application/postscript' },
  { fileExtension: FileExtension.TAR, mimeType: 'application/x-tar' },
  { fileExtension: FileExtension.DOC, mimeType: 'application/msword' },
  { fileExtension: FileExtension.DOT, mimeType: 'application/msword' },
  { fileExtension: FileExtension.XLS, mimeType: 'application/msexcel' },
  {
    fileExtension: FileExtension.DOCX,
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { fileExtension: FileExtension.TXT, mimeType: 'text/plain' },
  { fileExtension: FileExtension.XLSX, mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { fileExtension: FileExtension.ODS, mimeType: 'application/vnd.oasis.opendocument.spreadsheet' },
  { fileExtension: FileExtension.ODT, mimeType: 'application/vnd.oasis.opendocument.text' }
];
