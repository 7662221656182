import { Component, Input, OnInit } from '@angular/core';
import { ProductDescription } from '../../models/product-configuration/product-configuration';
import { ProductMapperHelper } from '../../../core/utils/mapping-helper/product-mapper.helper';
import { Product } from '../../../core/models/product.model';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sebu-product-description-display',
  templateUrl: './product-description-display.component.html',
  styleUrls: ['./product-description-display.component.scss']
})
export class ProductDescriptionDisplayComponent implements OnInit{

  @Input()
  public set product(product: Product) {
    this.productDescription = ProductMapperHelper.mapProductToConfiguration(product.productEnum).description;
    this.productName = product.productName;
  }

  public productDescription: ProductDescription;

  public productName: string;

  public checkIconPath: string;

  ngOnInit(): void {
    if (TenantSettingsHelper.getSettings().customCheckIconName) {
      this.checkIconPath = environment.pathToSVGs + TenantSettingsHelper.getSettings().customCheckIconName;
    }
  }

}
