<form *ngIf="formData" class="container" [formGroup]="summary">
  <sebu-product-summary formControlName="summaryProduct"
                        [productPageModel]="formData.product"
                        [priceModel]="priceModel"
                        (edit)="editProduct.emit()" ngDefaultControl>

  </sebu-product-summary>
  <sebu-company-summary
    [companyData]="formData.company"
    (edit)="editCompany.emit()">

  </sebu-company-summary>
  <sebu-offer-summary
    [offerOrderData]="formData.offerOrder"
    [productControlConfiguration]="productControlConfiguration"
    (edit)="editOffer.emit()">
  </sebu-offer-summary>
  <div *ngIf="(templatedProducts.includes(selectedProduct.productEnum) == false)">
    <sebu-header-with-button [showEditButton]="false">
      {{"summaryInfoHeadline"| TenantTextPipe}}
    </sebu-header-with-button>
    <div class="infoText-wrapper">
      <span class="infoText" [innerHTML]="summaryInfoSubtitle"></span>
    </div>
  </div>
  <sebu-html-template-summary
    *ngIf="templatedProducts.includes(selectedProduct.productEnum)"
    [previewUrl]="previewUrl"
    [previewIsLoading]="previewIsLoading"
    (edit)="editStandardLayout.emit()"></sebu-html-template-summary>
</form>

