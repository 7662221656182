<div class="content">
  <form novalidate [formGroup]="checkoutForm">
    <div fxLayout="row" fxLayoutGap="2em">

      <div fxFlex="40">
        <sebu-header-with-button [showEditButton]="false">{{ "summaryPaymentMethodTitle" | TenantTextPipe }}
        </sebu-header-with-button>
        <div class="checkout-wrapper">
          <h3>Bitte wählen Sie eine Zahlungsart aus:</h3>
          <mat-radio-group formControlName="paymentMethod" fxLayout="column" fxLayoutGap="1em">
            <mat-radio-button *ngIf="paymentMethods.includes('Card')" value="Card">
              <div sebuTenantText>summaryPaymentMethodCard</div>
              <span class="brand visa"></span>
              <span class="brand mastercard"></span>
            </mat-radio-button>
            <mat-radio-button *ngIf="paymentMethods.includes('Sepa')" value="Sepa">
              <div sebuTenantText>summaryPaymentMethodSepa</div>
              <span class="brand sepa"></span>
            </mat-radio-button>
            <mat-radio-button class="e2e-invoice" *ngIf="paymentMethods.includes('Invoice')" value="Invoice">
              <div sebuTenantText>summaryPaymentMethodInvoice</div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div fxFlex="60">
        <sebu-header-with-button [showEditButton]="false">{{ "summaryPaymentDataTitle" | TenantTextPipe }}
        </sebu-header-with-button>

        <div class="checkout-wrapper" fxLayout="column">
          <div [hidden]="paymentMethod !== 'Card'">
            <ngx-stripe-card-group [stripe]="stripe">
              <h3>Bitte geben Sie hier Ihre Kreditkartendaten ein:</h3>
              <mat-form-field floatLabel="always">
                <mat-label>Karteninhaber {{ 'isRequired' | TenantTextPipe }}</mat-label>
                <input matInput placeholder="Name" formControlName="cardOwner" />
              </mat-form-field>

              <div class="payment-section">
                <mat-label>Kreditkartennummer {{ 'isRequired' | TenantTextPipe }}</mat-label>
                <div class="credit-card-image" *ngIf="brand" [ngClass]="brand"></div>
                <mat-icon *ngIf="cardNumberComplete">done</mat-icon>
                <div class="payment-field-wrapper">
                  <div #cardNumber class="payment-field"></div>
                </div>
              </div>
              <div class="payment-section">
                <div fxLayout="row" fxLayoutGap="1em">
                  <div fxFlex="50">
                    <mat-label>Ablaufdatum {{ 'isRequired' | TenantTextPipe }}</mat-label>
                    <mat-icon *ngIf="cardExpiryComplete">done</mat-icon>
                    <div class="payment-field-wrapper">
                      <div #cardExpiry class="payment-field"></div>
                    </div>
                  </div>
                  <div fxFlex="50">
                    <mat-label>Prüfziffer (Rückseite) {{ 'isRequired' | TenantTextPipe }}</mat-label>
                    <mat-icon *ngIf="cardCvcComplete">done</mat-icon>
                    <div class="payment-field-wrapper">
                      <div #cardCvc class="payment-field"></div>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-stripe-card-group>
          </div>

          <div [hidden]="paymentMethod !== 'Sepa'">
            <h3>Bitte geben Sie hier Ihre Kontodaten ein:</h3>
            <mat-form-field floatLabel="always">
              <mat-label>Kontoinhaber {{ 'isRequired' | TenantTextPipe }}</mat-label>
              <input matInput placeholder="Name" formControlName="accountOwner" />
            </mat-form-field>
            <div class="payment-section">
              <mat-label>Kontonummer (IBAN) {{ 'isRequired' | TenantTextPipe }}</mat-label>
              <div #sepa class="payment-field"></div>
            </div>
            <mat-checkbox formControlName="agreeSepaMandat" ><span [innerHTML]="sepaMandat" ></span></mat-checkbox>
          </div>

          <div [hidden]="paymentMethod !== 'Invoice'">
            <h3 sebuTenantText>summaryInvoiceInfo</h3>
          </div>
          <div *ngIf="errorMessage" class="error">{{errorMessage}}</div>
        </div>
      </div>

    </div>
  </form>
</div>
