import { CompanyFormModel } from './company-form.model';
import { OfferOrderFormModel } from './offer-order-form.model';
import { ProductFormModel } from './product-form.model';
import { StandardLayoutFormModel } from './standard-layout-form.model';
import { SummaryFormModel } from './summary-form.model';

export class WizardFormModel {
  product?: ProductFormModel;
  offerOrder?: OfferOrderFormModel;
  company?: CompanyFormModel;
  standardLayout?: StandardLayoutFormModel;
  summary?: SummaryFormModel;

  constructor() {
    this.product = undefined;
    this.offerOrder = undefined;
    this.company = undefined;
    this.standardLayout = undefined;
    this.summary  = undefined;
  }
}
