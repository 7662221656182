import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '../app.config';
import { HttpInterceptorsModule } from '../core/utils/http/http-interceptors.module';
import { BASE_PATH } from './client';


@NgModule({
  imports: [
    HttpClientModule,
    HttpInterceptorsModule
  ],
  // We include the HTTP services separately because we want to reuse the singleton services but mock HTTP in the tests.
  providers: [
    { provide: BASE_PATH, useFactory: basePathFactory, deps: [APP_CONFIG] },
  ]
})

// never import this module in any other module than the AppModule
export class ApiModule {
}

export function basePathFactory(config: IAppConfig): string {
  return config.environment.apiEndpoint;
}
