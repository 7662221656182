import { OfferOrderActionEnum } from '../../api/client';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';

export class StatusPageState {
  public wizardFormModel: WizardFormModel;
  public allowedActions: OfferOrderActionEnum[];

  constructor() {
    // set this undefined so the view gets rendered only if productFormModel is filled
    this.wizardFormModel = undefined;
    this.allowedActions = [];
  }
}
