import { Injectable } from '@angular/core';
import { ResellerService, SebuResellerData } from '../../../api/client';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResellerCachingService {
  private resellerData$: Observable<SebuResellerData>;

  constructor(private resellerService: ResellerService) {
  }

  public getReseller(): Observable<SebuResellerData> {
    if (!this.resellerData$) {
      this.resellerData$ = this.resellerService.getReseller().pipe(shareReplay(1));
    }

    return this.resellerData$;
  }
}
