import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ScrollEventHelper{
  private scrollEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  private resizeEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  emitScrollEvent(data: any){
    this.scrollEventEmitter.emit(data);
  }

  getScrollEvent(): Observable<any> {
    return this.scrollEventEmitter.asObservable();
  }

  emitResizeEvent(data: any){
    this.resizeEventEmitter.emit(data);
  }

  getResizeEvent(): Observable<any> {
    return this.resizeEventEmitter.asObservable();
  }
}
