import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CompanyDetailsComponent } from './company-details.component';
import { SebuFormComponentsModule } from '../../../form-components/sebu-form-components.module';


@NgModule({
  imports: [
    SharedModule,
    SebuFormComponentsModule
  ],
  declarations: [
    CompanyDetailsComponent
  ],
  exports: [CompanyDetailsComponent]
})
export class CompanyDetailsModule {
}
