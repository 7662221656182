<div class="action-dialog">
  <h2 matDialogTitle sebuTenantText>deactivateDialogTitle</h2>
  <form [formGroup]="deactivateOfferOrderForm" (ngSubmit)="submit()" novalidate>
    <mat-dialog-content>
      <div fxLayoutGap="2.5em">
        <div fxLayout="column">
          <h2 sebuTenantText>reasonForDeactivation</h2>
          <mat-form-field>
            <textarea class="large" matInput formControlName="deactivateOfferOrderInformation"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutGap="1em">
      <button mat-raised-button color="primary" fxFlex="" matDialogClose sebuTenantText>dialogAbort</button>
      <button mat-raised-button color="primary" type="submit" fxFlex="" sebuTenantText>deactivateOrderButtonSubmit</button>
    </mat-dialog-actions>

  </form>
</div>
