import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OfferOrderCommentV3 } from '../../../api/client';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sebu-deactivate',
  templateUrl: './deactivate.component.html',
  styleUrls: ['./deactivate.component.scss']
})
export class DeactivateComponent implements OnInit {

  public deactivateOfferOrderForm: UntypedFormGroup;


  constructor(private formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<DeactivateComponent>) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('30%');
    this.deactivateOfferOrderForm = this.createForm();
  }

  public createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      deactivateOfferOrderInformation: []
    });
  }

  public submit(): void {
    const comment: OfferOrderCommentV3 = { comment: this.deactivateOfferOrderForm.get('deactivateOfferOrderInformation').value};
    this.dialogRef.close(comment);
  }
}
