import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';

export  class InlineEditorConfiguration extends ControlConfiguration{
  public initialValue: string;
  public isHeadline: boolean;

  constructor(options: InlineEditorConfigurationArgs) {
    super(ControlType.INLINE_EDITOR, options);
    this.initialValue = options.initialValue;
    this.isHeadline = options.isHeadline;
  }
}

export interface InlineEditorConfigurationArgs extends ControlConfigurationArgs {
  initialValue: string;
  isHeadline?: boolean;
}
