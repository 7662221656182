import * as _ from 'lodash';

export class AttachmentFileType {
  public static BMP: AttachmentFileType = AttachmentFileType.create('.bmp');
  public static CSV: AttachmentFileType = AttachmentFileType.create('.csv');
  public static DOC: AttachmentFileType = AttachmentFileType.create('.doc');
  public static DOCX: AttachmentFileType = AttachmentFileType.create('.docx');
  public static DOT: AttachmentFileType = AttachmentFileType.create('.dot');
  public static EPS: AttachmentFileType = AttachmentFileType.create('.eps');
  public static GIF: AttachmentFileType = AttachmentFileType.create('.gif');
  public static JPEG: AttachmentFileType = AttachmentFileType.create('.jpeg');
  public static JPG: AttachmentFileType = AttachmentFileType.create('.jpg');
  public static ODS: AttachmentFileType = AttachmentFileType.create('.ods');
  public static ODT: AttachmentFileType = AttachmentFileType.create('.odt');
  public static PDF: AttachmentFileType = AttachmentFileType.create('.pdf');
  public static PNG: AttachmentFileType = AttachmentFileType.create('.png');
  public static PS: AttachmentFileType = AttachmentFileType.create('.ps');
  public static PSD: AttachmentFileType = AttachmentFileType.create('.psd');
  public static RAR: AttachmentFileType = AttachmentFileType.create('.rar');
  public static SVG: AttachmentFileType = AttachmentFileType.create('.svg');
  public static TAR: AttachmentFileType = AttachmentFileType.create('.tar');
  public static TIF: AttachmentFileType = AttachmentFileType.create('.tif');
  public static TXT: AttachmentFileType = AttachmentFileType.create('.txt');
  public static XLS: AttachmentFileType = AttachmentFileType.create('.xls');
  public static XLSX: AttachmentFileType = AttachmentFileType.create('.xlsx');
  public static ZIP: AttachmentFileType = AttachmentFileType.create('.zip');

  public static ANY: AttachmentFileType = AttachmentFileType.aggregate(
    AttachmentFileType.ZIP, AttachmentFileType.JPG, AttachmentFileType.JPEG, AttachmentFileType.GIF, AttachmentFileType.TIF,
    AttachmentFileType.PDF, AttachmentFileType.BMP, AttachmentFileType.CSV, AttachmentFileType.RAR, AttachmentFileType.DOCX,
    AttachmentFileType.ODS, AttachmentFileType.ODT, AttachmentFileType.DOC, AttachmentFileType.DOT, AttachmentFileType.TXT,
    AttachmentFileType.XLS, AttachmentFileType.EPS, AttachmentFileType.PS, AttachmentFileType.TAR, AttachmentFileType.PSD,
    AttachmentFileType.PNG, AttachmentFileType.SVG);

  public static RESTRICTED: AttachmentFileType = AttachmentFileType.aggregate(AttachmentFileType.ZIP, AttachmentFileType.JPG,
    AttachmentFileType.JPEG, AttachmentFileType.GIF, AttachmentFileType.PDF,
    AttachmentFileType.DOCX, AttachmentFileType.ODT, AttachmentFileType.PNG);

  private constructor(public readonly extensions: string[]) {
    this.extensions = this.extensions.map(ft => ft.toLowerCase());
  }

  public static create(extension: string): AttachmentFileType {
    if (!extension || !extension.startsWith('.')) {
      throw new Error(`extension should start with a . but was ${extension}`);
    }
    return new AttachmentFileType([extension]);
  }

  public static aggregate(...supportedTypes: AttachmentFileType[]): AttachmentFileType {
    const extensions: string[] = _.uniq(supportedTypes.map(ft => ft.extensions)
      .reduce((a, b) => a.concat(b))
      .map(ft => ft.toLowerCase()));
    return new AttachmentFileType(extensions);
  }

  public static includes(filename: string, supportedType: AttachmentFileType): boolean {
    const fileType: string = '.' + filename.split('.').pop().toLowerCase();
    return supportedType.extensions.includes(fileType);
  }
}
