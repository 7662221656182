import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TenantTextHelper } from './tenant-texts/tenant-text.helper';

@Component({
  selector: 'sebu-title',
  template: ''
})
export class TitleComponent {
  constructor(private title: Title) {
    if (TenantTextHelper.hasValue('sebuTitle')) {
      title.setTitle(TenantTextHelper.findTextByKey('sebuTitle') as string);
    }
  }
}
