import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { StatusPageData } from '../models/status-page-data.model';
import * as OfferOrderActions from './actions';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { UserNotifyService } from '../../core/services/user-notify/user-notify.service';
import { PayloadAction } from '../../core/models/payload-action.model';
import { Router } from '@angular/router';
import {
  OfferService,
  SebuOfferOrderActions,
  ServicesService
} from '../../api/client';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { LocationDisplay } from '../../form-components/components/location-input/location-input.model';
import { LocationDisplayHelper } from '../../form-components/helper/location-display.helper';
import { ResellerCachingService } from '../../core/services/reseller-caching-service/reseller-caching.service';

@Injectable({
  providedIn: 'root',
})
export class StatusPageEffects {
  constructor(private action$: Actions,
              private offerService: OfferService,
              private sazService: ServicesService,
              private resellerCachingService: ResellerCachingService,
              private userNotifyService: UserNotifyService,
              private router: Router) {
  }

  @Effect()
  loadOfferOrderStatusPage: Observable<OfferOrderActions.LoadOfferOrderDataSuccess | OfferOrderActions.ShowStatusPageErrors> = this.action$.pipe(
    ofType(OfferOrderActions.LOAD_OFFER_ORDER_DATA_AND_ACTIONS),
    exhaustMap((action: PayloadAction) => combineLatest([
      this.offerService.getOfferOrderStatus(action.payload),
      this.sazService.categories(),
      this.resellerCachingService.getReseller()
    ])),
    switchMap(([statusData, allCategories, resellerData]) => statusData.offer.job.location ?
      this.sazService.cities(statusData.offer.job.location)
        .pipe(
          map(result => {
            if (statusData.offer.job.locationType === 'Ort') {
              const displayLocation: LocationDisplay = LocationDisplayHelper.toCityOrZipLocationModel(result[0]);
              statusData.offer.job.location = displayLocation.valueToDisplay;
            }
            return new StatusPageData(statusData, allCategories, resellerData);
          })
        )
      // TODO: Check why test is failing if we return the object with out the empty assign
      : of(Object.assign({}, new StatusPageData(statusData, allCategories, resellerData)))
    ),
    map((summary: StatusPageData) => new OfferOrderActions.LoadOfferOrderDataSuccess(summary)),
    catchError(() => of(new OfferOrderActions.ShowStatusPageErrors(TenantTextPipe.transform('loadingSummaryError') as string)))
  );

  @Effect()
  loadOfferOrderStatusPageActions: Observable<OfferOrderActions.LoadOfferOrderActionsSuccess | OfferOrderActions.ShowStatusPageErrors> = this.action$.pipe(
    ofType(OfferOrderActions.LOAD_OFFER_ORDER_DATA_AND_ACTIONS),
    switchMap((action: PayloadAction) => this.offerService.getOfferOrderActions(action.payload)),
    map((actions: SebuOfferOrderActions) => new OfferOrderActions.LoadOfferOrderActionsSuccess(actions)),
    catchError(() => of(new OfferOrderActions.ShowStatusPageErrors(TenantTextPipe.transform('loadingSummaryError') as string)))
  );

  @Effect()
  deleteOfferOrder: Observable<OfferOrderActions.DeleteOfferOrderSuccess | OfferOrderActions.ShowStatusPageErrors> = this.action$.pipe(
    ofType(OfferOrderActions.DELETE_OFFER_ORDER),
    exhaustMap((action: PayloadAction) => this.offerService.deleteOfferOrder(action.payload)),
    map(() => new OfferOrderActions.DeleteOfferOrderSuccess()),
    catchError(() => of(new OfferOrderActions.ShowStatusPageErrors(TenantTextPipe.transform('deleteOfferOrderError') as string)))
  );

  @Effect()
  deactivateOfferOrder: Observable<OfferOrderActions.DeactivateOfferOrderSuccess | OfferOrderActions.ShowStatusPageErrors> = this.action$.pipe(
    ofType(OfferOrderActions.DEACTIVATE_OFFER_ORDER),
    exhaustMap((action: PayloadAction) => this.offerService.deactivateOfferOrder(action.payload.token, action.payload.comment)),
    map((result) => new OfferOrderActions.DeactivateOfferOrderSuccess(result.offer.sebuToken)),
    catchError(() => of(new OfferOrderActions.ShowStatusPageErrors(TenantTextPipe.transform('deactivateOfferOrderError') as string)))
  );

  @Effect()
  activateOfferOrder: Observable<OfferOrderActions.ActivateOfferOrderSuccess | OfferOrderActions.ShowStatusPageErrors> = this.action$.pipe(
    ofType(OfferOrderActions.ACTIVATE_OFFER_ORDER),
    exhaustMap((action: PayloadAction) => this.offerService.activateOfferOrder(action.payload)),
    map((result) => new OfferOrderActions.ActivateOfferOrderSuccess(result.offer.sebuToken)),
    // eslint-disable-next-line
    catchError((a) => of(new OfferOrderActions.ShowStatusPageErrors(TenantTextPipe.transform('activateOfferOrderError') as string)))
  );

  @Effect()
  activateOfferOrderSuccess: Observable<OfferOrderActions.LoadOfferOrderDataAndActions> = this.action$.pipe(
    ofType(OfferOrderActions.ACTIVATE_OFFER_ORDER_SUCCESS),
    map((action: PayloadAction) => {
      this.userNotifyService.regular(TenantTextPipe.transform('activateOfferOrderSuccess') as string);
      return new OfferOrderActions.LoadOfferOrderDataAndActions(action.payload);
    })
  );

  @Effect()
  deactivateOfferOrderSuccess: Observable<OfferOrderActions.LoadOfferOrderDataAndActions> = this.action$.pipe(
    ofType(OfferOrderActions.DEACTIVATE_OFFER_ORDER_SUCCESS),
    map((action: PayloadAction) => {
      this.userNotifyService.regular(TenantTextPipe.transform('deactivateOfferOrderSuccess') as string);
      return new OfferOrderActions.LoadOfferOrderDataAndActions(<string>action.payload);
    })
  );

  // These are empty effects. They don't dispatch any further action at the end so we have to set {dispatch: false} on them
  @Effect({ dispatch: false })
  showDeleteOfferOrderSuccessNotifier: Observable<{}> = this.action$.pipe(
    ofType(OfferOrderActions.DELETE_OFFER_ORDER_SUCCESS),
    exhaustMap(() => {
      this.userNotifyService.regular(TenantTextPipe.transform('deleteOfferOrderSuccess') as string);
      this.router.navigate(['']);
      return of({});
    })
  );

  @Effect({ dispatch: false })
  showOfferOrderErrorNotifier: Observable<{}> = this.action$.pipe(
    ofType(OfferOrderActions.SHOW_STATUS_PAGE_ERRORS),
    exhaustMap((action: PayloadAction) => {
      this.userNotifyService.error(action.payload);
      return of({});
    })
  );
}
