import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sebu-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {

  public color = '#f3f3f3';

  public propagateChange = (_: string) => {};

  // eslint-disable-next-line
  ngOnInit(): void {}

  public propagateColor(color: string): void {
    this.propagateChange(color);
  }

  writeValue(color: string): void {
    if (color) {
      this.color = color;
    }
  }

  registerOnChange(fn: (f: string) => {}): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: (f: string) => {}): void {
  }
}
