<div class="product-description-wrapper description-full-width">
  <div class="content">
    <div class="product-description-content">
      <h1 id="description-product-name">{{productDescription.descriptionHeading | TenantTextPipe:productName }}</h1>
      <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="1.5em" class="product-description-text">
        <div fxLayout="column" fxLayoutAlign="space-between" fxFlex class="description-column">
          <div>
            <p *ngFor="let description of ( productDescription.description | TenantTextPipe )"><span [innerHTML]="description"></span></p>
          </div>
          <p sebuTenantText>priceDisclaimer</p>
        </div>
        <div fxFlex>
          <h3>{{productDescription.descriptionSubHeadline | TenantTextPipe:productName }}</h3>
          <div fxLayout="column" *ngIf="(productDescription.descriptionWhatYouGet | TenantTextPipe) as whatYouGet" class="description-column">
            <div fxLayout="row" class="benefit" *ngFor="let benefit of whatYouGet">
              <div class="custom-icon" *ngIf="checkIconPath; else defaultCheckIcon">
                <sebu-svg class="custom-check" [svgSrc]="checkIconPath"></sebu-svg>
              </div>
              <ng-template #defaultCheckIcon>
                <i class="material-icons list-type-icon">check</i>
              </ng-template>
              <span [innerHTML]="benefit"></span>
            </div>
            <div fxLayout="row" style="margin-top: 1em;" *ngFor="let subText of (productDescription.descriptionWhatYouGetSubText | TenantTextPipe)" >
              <span [innerHTML]="subText"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
