export * from './offer.service';
import { OfferService } from './offer.service';
export * from './other.service';
import { OtherService } from './other.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './preview.service';
import { PreviewService } from './preview.service';
export * from './reseller.service';
import { ResellerService } from './reseller.service';
export * from './services.service';
import { ServicesService } from './services.service';
export const APIS = [OfferService, OtherService, PaymentService, PreviewService, ResellerService, ServicesService];
