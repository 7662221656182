import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  CityLocation,
  Country,
  FederalState, Location,
  OfferLocationTypeEnum,
  ServicesService
} from '../../../api/client/index';
import { LocationDisplay, CountryLocationDisplay, StateLocationDisplay, CityLocationDisplay } from './location-input.model';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocationDisplayHelper } from '../../helper/location-display.helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'sebu-location-input',
  templateUrl: 'location-input.component.html',
  styleUrls: ['location-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationInputComponent),
      multi: true,
    }]
})

export class LocationInputComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Output()
  optionSelected: EventEmitter<MatAutocompleteSelectedEvent>;
  @Output()
  closed: EventEmitter<void>;

  public locationForm: UntypedFormGroup;
  public locationValue: LocationDisplay = new CityLocationDisplay();
  public locationTypes: OfferLocationTypeEnum[] = [
    OfferLocationTypeEnum.Land,
    OfferLocationTypeEnum.Bundesland,
    OfferLocationTypeEnum.Ort
  ];

  public countries$: Observable<CountryLocationDisplay[]>;
  public federalStates$: Observable<StateLocationDisplay[]>;
  public cityLocations$: Observable<CityLocationDisplay[]>;

  public resolvedCities: CityLocationDisplay[];
  private lastCitySearch: string;
  private lastCitySearchResult$: Subject<Location[]> = new Subject();

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private locationService: ServicesService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.locationForm = this.createForm();

    this.createStreams();
    this.cityLocations$.pipe(takeUntil(this.componentDestroyed$)).subscribe(cities => this.resolvedCities = cities);

    this.locationForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.onChange(data);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public compare(first: { value: string }, second: { value: string }): boolean {
    return first && second && first.value === second.value;
  }

  /* Custom Form Control - ControlValueAccessor Interface methods */

  // Set the initial control value
  public writeValue(obj: LocationDisplay): void {
    if (obj) {
      this.locationValue = obj;
      this.mapValueToForm();
    }
  }

  // eslint-disable-next-line
  public onChange(data: LocationDisplay): void {
    this.mapFormToValue();
    this.propagateChange(this.locationValue && this.locationValue.value ? this.locationValue : null);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched(): void {
  }

  /* Autocomplete methods */
  public displayCity(location: CityLocationDisplay): string {
    return location ? location.valueToDisplay : '';
  }

  public handleBlurCity(): void {
    const inputCity: CityLocationDisplay | string = <CityLocationDisplay | string>this.locationForm.get('city').value;
    if (typeof inputCity === 'string') {
      if (this.resolvedCities && this.locationForm.get('city').value) {
        this.locationForm.get('city').setValue(this.resolvedCities[0]);
      }
    }
  }

  /* UI State methods */
  public showCountryList(): boolean {
    return <OfferLocationTypeEnum>this.locationForm.get('type').value === OfferLocationTypeEnum.Land;
  }

  public showFederalStateList(): boolean {
    return <OfferLocationTypeEnum>this.locationForm.get('type').value === OfferLocationTypeEnum.Bundesland;
  }

  public showCitySearch(): boolean {
    return <OfferLocationTypeEnum>this.locationForm.get('type').value === OfferLocationTypeEnum.Ort;
  }

  private getCityLocationsByNameOrZip(query: string): void {
    if (typeof query !== 'string' || query.length < 2) {
      this.lastCitySearch = query;
      this.lastCitySearchResult$.next([]);
      return;
    }

      this.lastCitySearch = query.substring(0, 2);
      this.locationService.cities(this.lastCitySearch).pipe(
        takeUntil(this.componentDestroyed$),
        tap((v) => this.lastCitySearchResult$.next(v))).subscribe();
  }

  private checkIsNewQueryNecessary(query: string): boolean {
    return query && (query.substring(0, 2) !== this.lastCitySearch);
  }

  private filterLastCitySearchResult(lastCitySearchResult: Location[], query: string): CityLocationDisplay[] {
    if (!lastCitySearchResult || typeof query !== 'string') {
      return [];
    }
    return lastCitySearchResult
        .filter(
          (location: Location): boolean => location.name.toLowerCase().startsWith(query.toLowerCase()) ||
                                          (location as CityLocation).zipCode && (location as CityLocation).zipCode.toLowerCase().startsWith(query.toLowerCase()))
        .map<LocationDisplay>((location: Location): LocationDisplay => LocationDisplayHelper.toCityOrZipLocationModel(location));
  }

  /**
   * Maps the "value" of the internal advancedForm to the interface custom input value
   */
  private mapFormToValue(): void {
    if (!this.locationForm.valid) {
      this.locationValue = null;
    }
    switch (<OfferLocationTypeEnum>this.locationForm.get('type').value) {
      case OfferLocationTypeEnum.Land: {
        this.locationValue = this.locationForm.get('country').value;
        break;
      }
      case OfferLocationTypeEnum.Bundesland: {
        this.locationValue = this.locationForm.get('state').value;
        break;
      }
      case OfferLocationTypeEnum.Ort:
      default: {
        this.locationValue = this.locationForm.get('city').value;
        break;
      }
    }
  }

  /**
   * Maps the interface "value" of the custom input to the values of the internal advancedForm
   */
  private mapValueToForm(): void {
    if (!this.locationValue) {
      this.clearFormValues();
      return;
    }
    this.locationForm.get('type').setValue(this.locationValue.type || OfferLocationTypeEnum.Ort, {emitEvent: false});
    switch (this.locationValue.type) {
      case OfferLocationTypeEnum.Land: {
        this.locationForm.get('country').setValue(this.locationValue, {emitEvent: false});
        break;
      }
      case OfferLocationTypeEnum.Bundesland: {
        // Todo remove replacement when fixed in backend (wrong database entries)
        this.locationValue.value = this.locationValue.value.replace('Bundesland ', '');
        this.locationValue.valueToDisplay = this.locationValue.valueToDisplay.replace('Bundesland ', '');
        this.locationForm.get('state').setValue(this.locationValue, {emitEvent: false});
        break;
      }
      case OfferLocationTypeEnum.Ort: {
        this.locationForm.get('city').setValue(this.locationValue, {emitEvent: false});
        break;
      }
      // eslint-disable-next-line
      default: {
      }
    }
  }

  private clearFormValues(): void {
    this.locationForm.get('type').setValue(OfferLocationTypeEnum.Ort);
    this.locationForm.get('country').reset();
    this.locationForm.get('state').reset();
    this.locationForm.get('city').reset();
  }

  private createStreams(): void {
    this.countries$ = this.locationService.countries()
      .pipe(
        map((countries: Array<Country>) => {
          const mappedCountries: CountryLocationDisplay[] = [];
          countries.forEach((country: Country) => mappedCountries.push(new CountryLocationDisplay(country.name)));
          return mappedCountries;
        })
      );

    this.federalStates$ = this.locationService.federalStates()
      .pipe(map<Array<FederalState>, Array<StateLocationDisplay>>((fsArray: Array<FederalState>) => {
          return this.mapFederalStateArrayToStateLocationArray(fsArray);
        })
      );

    this.locationForm.get('city').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(v => !!v),
        filter(v => typeof v === 'string' || v.length > 1),
        filter((value) => this.checkIsNewQueryNecessary(value)),
        tap((value) => this.getCityLocationsByNameOrZip(value)),
        ).subscribe();

    this.cityLocations$ = combineLatest([this.lastCitySearchResult$, this.locationForm.get('city').valueChanges])
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(([lastCitySearch, query]) => of(this.filterLastCitySearchResult(lastCitySearch, query)))
        );

  }

  private mapFederalStateArrayToStateLocationArray(fsArray: FederalState[]): StateLocationDisplay[] {
    return fsArray.map<StateLocationDisplay>((fs: FederalState) => {
      return this.mapFederalStateToStateLocation(fs);
    });
  }

  private mapFederalStateToStateLocation(state: FederalState): StateLocationDisplay {
    let modifiedStateLocation: StateLocationDisplay;
    switch (state.name.toLowerCase()) {
      case 'berlin':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Berlin');
        break;
      case 'brandenburg':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Brandenburg');
        break;
      case 'bremen':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Bremen');
        break;
      case 'hamburg':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Hamburg');
        break;
      case 'hessen':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Hessen');
        break;
      case 'sachsen':
        modifiedStateLocation = new StateLocationDisplay('Bundesland Sachsen');
        break;
      default:
        modifiedStateLocation = new StateLocationDisplay(state.name);
    }
    return modifiedStateLocation;
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      'type': OfferLocationTypeEnum.Ort,
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'country': ['', Validators.required]
    });
  }

  // This is a declaration for the propagateChange method. Will be set externally through registerOnChange
  private propagateChange = (_: LocationDisplay) => {
  }
}
