import { ErrorHelper } from '../core/utils/error/error.helper';
import { defaultTexts } from './default-texts';
import { customTexts } from '../../assets/custom-texts';
import { ITexts } from './ITexts';

export class TenantTextHelper {

  public static readonly texts: ITexts = Object.assign({}, defaultTexts as ITexts, customTexts as ITexts);

  /**
   * Finds text specified by key.
   * @param {string} key is the text you are locking for. Must equal a variable of IText.
   * @returns {string} the value that is stored for the passed key
   */
  public static findTextByKey(key: string): string | Array<string> {
    if (key) {
      key = key.trim();
    }
    if (!TenantTextHelper.hasValue(key)) {
      ErrorHelper.throwError(`No value defined for ${key} in tenant texts`);
    }
    return TenantTextHelper.replaceDefaultDomain(TenantTextHelper.texts[key]);
  }

  // Check for default domain replacements
  private static replaceDefaultDomain(text: string | Array<string>): string | Array<string> {
    if (TenantTextHelper.hasValue('sebuDomain')) {

      const replace: (t: string) => string = (t: string) =>
        t.replace(/{domain}/g, TenantTextHelper.texts['sebuDomain']);

      text = Array.isArray(text) ? text.map(replace) : replace(text);
    }
    return text;
  }

  /**
   * Returns whether a text has any value
   * @param {string} key is the text you are locking for. Must equal a variable of IText.
   * @returns {boolean} true if value is not undefined or null
   * */
  public static hasValue(key: string): boolean {
    if (key) {
      key = key.trim();
    }
    const value: string | Array<string> = TenantTextHelper.texts[key];
    const found: boolean = value !== undefined && value !== null && value !== '';
    return found;
  }

  /**
   * Finds text specified by key and replaces "{}" by the given replacement.
   * @param key key is the text you are locking for. Must equal a variable of IText.
   * @param replacement the replacement text
   * @returns {string} the value that is stored for the passed key with the replaced value
   */
  public static findTextByKeyAndReplace(key: string, replacement: string): string {
    return (TenantTextHelper.findTextByKey(key) as string).replace('{}', replacement);
  }
}
