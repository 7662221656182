import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TenantTextHelper } from './tenant-texts/tenant-text.helper';

@Component({
  selector: 'sebu-meta-description',
  template: ''
})
export class MetaComponent {
  constructor(private metaDescription: Meta) {
    if (TenantTextHelper.hasValue('metaDescription')) {
      metaDescription.addTag({name: 'description', content: TenantTextHelper.findTextByKey('metaDescription') as string});
    }
  }
}
