/**
 * Sa.Sebu.Api
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OfferOrderActionEnum = 'Send' | 'Edit' | 'Correct' | 'ReplaceAttachment' | 'Delete' | 'Deactivate' | 'Activate' | 'Cancel' | 'Upgrade' | 'Reassign' | 'UpdateOfferOrderNumber';

export const OfferOrderActionEnum = {
    Send: 'Send' as OfferOrderActionEnum,
    Edit: 'Edit' as OfferOrderActionEnum,
    Correct: 'Correct' as OfferOrderActionEnum,
    ReplaceAttachment: 'ReplaceAttachment' as OfferOrderActionEnum,
    Delete: 'Delete' as OfferOrderActionEnum,
    Deactivate: 'Deactivate' as OfferOrderActionEnum,
    Activate: 'Activate' as OfferOrderActionEnum,
    Cancel: 'Cancel' as OfferOrderActionEnum,
    Upgrade: 'Upgrade' as OfferOrderActionEnum,
    Reassign: 'Reassign' as OfferOrderActionEnum,
    UpdateOfferOrderNumber: 'UpdateOfferOrderNumber' as OfferOrderActionEnum
}
