import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SebuFormComponentsModule } from '../form-components/sebu-form-components.module';
import { SharedModule } from '../shared/shared.module';
import { OfferOrderContainerComponent } from './components/offer-order-container.component';
import { SummaryModule } from './components/summary/summary.module';
import { OfferOrderDetailsModule } from './components/offer-order-details/offer-order-details.module';
import { CompanyDetailsModule } from './components/company-details/company-details.module';
import { ProductSelectionModule } from './components/product-selection/product-selection.module';
import { OfferOrderWizardEffects } from './ngrx/effects';
import { wizardReducer } from './ngrx/reducer/reducer';
import { DynamicFormsModule } from '../dynamic-forms/dynamic-forms.module';
import { StandardLayoutDetailsModule } from './components/standard-layout-details/standard-layout-details.module';
import { MomentModule } from 'ngx-moment';

@NgModule({
    imports: [
        SharedModule,
        SebuFormComponentsModule,
        DynamicFormsModule,
        ProductSelectionModule,
        CompanyDetailsModule,
        OfferOrderDetailsModule,
        StandardLayoutDetailsModule,
        SummaryModule,
        MomentModule,
        StoreModule.forFeature('wizard', wizardReducer),
        EffectsModule.forFeature([OfferOrderWizardEffects]),
    ],
    declarations: [
        OfferOrderContainerComponent,
    ]
})
export class WizardModule { }

