/**
 * Sa.Sebu.Api
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Product ID.
 */
export type ProductEnum = 'RegioPokJpg' | 'RegioPokStandardlayoutFuerFliesstextanzeigen' | 'PremiumPokPdfUndSonstigesFormat' | 'OnlineOnlyPdfUndSonstigesFormat' | 'RegioOnlineOnlyStandardlayout' | 'RegioPokHtml' | 'RegioPokStandardlayout' | 'RegioOnlineOnlyHtml' | 'RegioOnlineOnlyJpg' | 'RegioOnlineOnlyStandardlayoutFuerFliesstextanzeigen' | 'PremiumPokHtml' | 'OnlineOnlyHtml' | 'PortalPokVonStellenanzeigenDeErstellt' | 'PortalOnlineOnlyVonStellenanzeigenDeErstellt' | 'SegaRegioOnlineOnlyHtml' | 'SegaKontingentRegioOnlineOnlyHtml' | 'SegaPremiumOnlineOnlyHtml' | 'PortalPokPlusVonStellenanzeigenDeErstellt' | 'RegioPokStandardHtml' | 'RegioOnlineOnlyAzubi' | 'PortalOnlineOnlyAzubi' | 'OnlineOnlyAzubi' | 'PremiumPokAzubi' | 'RegioOnlineOnlyMinijobs';

export const ProductEnum = {
    RegioPokJpg: 'RegioPokJpg' as ProductEnum,
    RegioPokStandardlayoutFuerFliesstextanzeigen: 'RegioPokStandardlayoutFuerFliesstextanzeigen' as ProductEnum,
    PremiumPokPdfUndSonstigesFormat: 'PremiumPokPdfUndSonstigesFormat' as ProductEnum,
    OnlineOnlyPdfUndSonstigesFormat: 'OnlineOnlyPdfUndSonstigesFormat' as ProductEnum,
    RegioOnlineOnlyStandardlayout: 'RegioOnlineOnlyStandardlayout' as ProductEnum,
    RegioPokHtml: 'RegioPokHtml' as ProductEnum,
    RegioPokStandardlayout: 'RegioPokStandardlayout' as ProductEnum,
    RegioOnlineOnlyHtml: 'RegioOnlineOnlyHtml' as ProductEnum,
    RegioOnlineOnlyJpg: 'RegioOnlineOnlyJpg' as ProductEnum,
    RegioOnlineOnlyStandardlayoutFuerFliesstextanzeigen: 'RegioOnlineOnlyStandardlayoutFuerFliesstextanzeigen' as ProductEnum,
    PremiumPokHtml: 'PremiumPokHtml' as ProductEnum,
    OnlineOnlyHtml: 'OnlineOnlyHtml' as ProductEnum,
    PortalPokVonStellenanzeigenDeErstellt: 'PortalPokVonStellenanzeigenDeErstellt' as ProductEnum,
    PortalOnlineOnlyVonStellenanzeigenDeErstellt: 'PortalOnlineOnlyVonStellenanzeigenDeErstellt' as ProductEnum,
    SegaRegioOnlineOnlyHtml: 'SegaRegioOnlineOnlyHtml' as ProductEnum,
    SegaKontingentRegioOnlineOnlyHtml: 'SegaKontingentRegioOnlineOnlyHtml' as ProductEnum,
    SegaPremiumOnlineOnlyHtml: 'SegaPremiumOnlineOnlyHtml' as ProductEnum,
    PortalPokPlusVonStellenanzeigenDeErstellt: 'PortalPokPlusVonStellenanzeigenDeErstellt' as ProductEnum,
    RegioPokStandardHtml: 'RegioPokStandardHtml' as ProductEnum,
    RegioOnlineOnlyAzubi: 'RegioOnlineOnlyAzubi' as ProductEnum,
    PortalOnlineOnlyAzubi: 'PortalOnlineOnlyAzubi' as ProductEnum,
    OnlineOnlyAzubi: 'OnlineOnlyAzubi' as ProductEnum,
    PremiumPokAzubi: 'PremiumPokAzubi' as ProductEnum,
    RegioOnlineOnlyMinijobs: 'RegioOnlineOnlyMinijobs' as ProductEnum
}
