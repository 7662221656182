/**
 * Sa.Sebu.Api
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OfferOrderCommentV3 } from '../model/offerOrderCommentV3';
import { SebuOfferOrderActions } from '../model/sebuOfferOrderActions';
import { SebuOfferOrderConfirmation } from '../model/sebuOfferOrderConfirmation';
import { SebuOfferOrderCreate } from '../model/sebuOfferOrderCreate';
import { SebuOfferOrderStatus } from '../model/sebuOfferOrderStatus';
import { SebuRecordLocation } from '../model/sebuRecordLocation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { OfferServiceInterface }                            from './offer.serviceInterface';


@Injectable({
  providedIn: 'root'
})
export class OfferService implements OfferServiceInterface {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates an offer order with a given {token}.
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateOfferOrder(token: string, observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderStatus>;
    public activateOfferOrder(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderStatus>>;
    public activateOfferOrder(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderStatus>>;
    public activateOfferOrder(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling activateOfferOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<SebuOfferOrderStatus>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(token))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirms an offer order with a given {token} and {date}.
     * 
     * @param date 
     * @param token 
     * @param paymentMethod 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmOfferOrder(date: string, token: string, paymentMethod?: 'Invoice' | 'Card' | 'Sepa', observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderConfirmation>;
    public confirmOfferOrder(date: string, token: string, paymentMethod?: 'Invoice' | 'Card' | 'Sepa', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderConfirmation>>;
    public confirmOfferOrder(date: string, token: string, paymentMethod?: 'Invoice' | 'Card' | 'Sepa', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderConfirmation>>;
    public confirmOfferOrder(date: string, token: string, paymentMethod?: 'Invoice' | 'Card' | 'Sepa', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling confirmOfferOrder.');
        }
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling confirmOfferOrder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentMethod !== undefined) {
            queryParameters = queryParameters.set('paymentMethod', <any>paymentMethod);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<SebuOfferOrderConfirmation>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(date))}/${encodeURIComponent(String(token))}/confirm`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new offer order.
     * 
     * @param offerOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOfferOrder(offerOrder: SebuOfferOrderCreate, observe?: 'body', reportProgress?: boolean): Observable<SebuRecordLocation>;
    public createOfferOrder(offerOrder: SebuOfferOrderCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuRecordLocation>>;
    public createOfferOrder(offerOrder: SebuOfferOrderCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuRecordLocation>>;
    public createOfferOrder(offerOrder: SebuOfferOrderCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (offerOrder === null || offerOrder === undefined) {
            throw new Error('Required parameter offerOrder was null or undefined when calling createOfferOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<SebuRecordLocation>(`${this.basePath}/api/v1/offerOrders`,
            offerOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deactivates an offer order with a given {token}.
     * 
     * @param token 
     * @param offerOrderComment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivateOfferOrder(token: string, offerOrderComment: OfferOrderCommentV3, observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderStatus>;
    public deactivateOfferOrder(token: string, offerOrderComment: OfferOrderCommentV3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderStatus>>;
    public deactivateOfferOrder(token: string, offerOrderComment: OfferOrderCommentV3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderStatus>>;
    public deactivateOfferOrder(token: string, offerOrderComment: OfferOrderCommentV3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling deactivateOfferOrder.');
        }
        if (offerOrderComment === null || offerOrderComment === undefined) {
            throw new Error('Required parameter offerOrderComment was null or undefined when calling deactivateOfferOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<SebuOfferOrderStatus>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(token))}/deactivate`,
            offerOrderComment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an offer order with a given {token} and delete the company afterwards, if possible.
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOfferOrder(token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOfferOrder(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOfferOrder(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOfferOrder(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling deleteOfferOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(token))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the actions of an offer order with a given {token}.
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfferOrderActions(token: string, observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderActions>;
    public getOfferOrderActions(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderActions>>;
    public getOfferOrderActions(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderActions>>;
    public getOfferOrderActions(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getOfferOrderActions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SebuOfferOrderActions>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(token))}/actions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the offer order status.
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfferOrderStatus(token: string, observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderStatus>;
    public getOfferOrderStatus(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderStatus>>;
    public getOfferOrderStatus(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderStatus>>;
    public getOfferOrderStatus(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getOfferOrderStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SebuOfferOrderStatus>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(token))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a summary of the created sebu offer order with a given {token} and {date}.
     * 
     * @param date 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOfferOrderSummary(date: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<SebuOfferOrderCreate>;
    public getOfferOrderSummary(date: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SebuOfferOrderCreate>>;
    public getOfferOrderSummary(date: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SebuOfferOrderCreate>>;
    public getOfferOrderSummary(date: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getOfferOrderSummary.');
        }
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getOfferOrderSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SebuOfferOrderCreate>(`${this.basePath}/api/v1/offerOrders/${encodeURIComponent(String(date))}/${encodeURIComponent(String(token))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
