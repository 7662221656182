import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import { CustomValidators } from '../../../shared/helper/custom-validation.helper';
import { ValidationSetter } from '../../../shared/helper/validation-setter.helper';
import { CompanyFormModel } from '../../models/wizard-form-models/company-form.model';
import {
  catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import { Salutation } from './salutation.model';
import { CityLocation, Location, ServicesService } from '../../../api/client';
import { Observable, of, Subject } from 'rxjs';


@Component({
  selector: 'sebu-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyDetailsComponent),
    multi: true
  }]
})
export class CompanyDetailsComponent implements OnInit, ControlValueAccessor, OnDestroy {

  public companyDetailsFormGroup: UntypedFormGroup;
  public salutations: Salutation[] = [
    { displayValue: 'Herr', value: 'Herr' },
    { displayValue: 'Frau', value: 'Frau' },
    { displayValue: 'Keine Anrede', value: '' }
  ];
  private componentDestroyed$: Subject<void> = new Subject<void>();
  private defaultCountryValue = 'Deutschland';

  public propagateChange = (_: CompanyFormModel) => {
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private locationService: ServicesService) {
  }

  ngOnInit(): void {
    this.companyDetailsFormGroup = this.formBuilder.group(new CompanyFormModel());
    this.setUpValidators();
    this.setupAutomaticCityCompletion();
    this.companyDetailsFormGroup.valueChanges.pipe(takeUntil(this.componentDestroyed$), debounceTime(600)).subscribe(() => this.propagateCompany());

    // pre-populate the country input with the default value
    setTimeout(() => {
      this.companyDetailsFormGroup.controls.country.setValue(this.defaultCountryValue);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  registerOnChange(fn: (_: CompanyFormModel) => {}): void {
    this.propagateChange = fn;
  }

  writeValue(companyDetails: CompanyFormModel): void {
    if (companyDetails) {
      this.companyDetailsFormGroup.setValue(companyDetails);
    } else {
      this.companyDetailsFormGroup.reset();
    }
  }

  propagateCompany(): void {
    const companyDetails: CompanyFormModel = this.companyDetailsFormGroup.getRawValue();
    this.propagateChange(this.companyDetailsFormGroup.valid ? companyDetails : undefined);
  }

  // eslint-disable-next-line
  registerOnTouched(fn: CompanyFormModel): void {
  }

  private setUpValidators(): void {
    this.companyDetailsFormGroup.controls.email.setValidators([
      CustomValidators.sareEmail,
      Validators.maxLength(255)]);
    this.companyDetailsFormGroup.controls.telephoneNumber.setValidators([
      CustomValidators.phoneNumber,
      Validators.maxLength(255)]);
    this.companyDetailsFormGroup.controls.salutation.setValidators(Validators.maxLength(10));
    this.companyDetailsFormGroup.controls.title.setValidators(Validators.maxLength(10));

    ValidationSetter.setRequired([
      this.companyDetailsFormGroup.controls.companyName,
      this.companyDetailsFormGroup.controls.telephoneNumber,
      this.companyDetailsFormGroup.controls.street,
      this.companyDetailsFormGroup.controls.city,
      this.companyDetailsFormGroup.controls.firstName,
      this.companyDetailsFormGroup.controls.email,
      this.companyDetailsFormGroup.controls.lastName,
      this.companyDetailsFormGroup.controls.postcode
    ]);
    ValidationSetter.setCharValidation(this.companyDetailsFormGroup);
    this.companyDetailsFormGroup.updateValueAndValidity();
  }

  private setupAutomaticCityCompletion(): void {
    const cityControl: AbstractControl = this.companyDetailsFormGroup.controls.city;
    this.companyDetailsFormGroup.controls.postcode.valueChanges.pipe(
      takeUntil(this.componentDestroyed$),
      debounceTime(200),
      filter((postcode: string) => postcode && postcode.length === 5),
      distinctUntilChanged(),
      switchMap((postcode: string) => this.getCityNameByPostcode(postcode))
    ).subscribe((city: string) => cityControl.setValue(city));
  }

  private getCityNameByPostcode(postcode: string): Observable<string> {
    const currentCityValue: string = this.companyDetailsFormGroup.controls.city.value ? this.companyDetailsFormGroup.controls.city.value.toString() : '';
    return this.locationService.cities(postcode)
      .pipe(map((apiSearchResultArray: Location[]) => {
        const location: CityLocation = apiSearchResultArray[0] as CityLocation;
        return (location && location.name && location.zipCode && location.zipCode === postcode.trim()) ? location.name : currentCityValue;
      }), catchError(() => {
        return of(currentCityValue);
      }));
  }
}
