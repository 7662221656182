import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenantTextHelper } from '../../../../tenant-texts/tenant-text.helper';
import { TenantSettingsHelper } from '../../../../tenant-settings/tenant-settings.helper';

class PrivacyStatementModel {
  agreePrivacyStatement = false;
}

@Component({
  selector: 'sebu-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit, OnDestroy {

  public privacyStatementHtml: string;
  public showCheckbox: boolean;
  public model: UntypedFormGroup;

  private static readonly agbPlaceholder: RegExp = /\[AGB]/g;
  private static readonly privacyProtectionPlaceholder: RegExp = /\[DATENSCHUTZ]/g;

  @Input()
  public set disabled(disabled: boolean) {
    if (!this.model) {
      this.disabledDefaultValue = disabled;
      return;
    }
    if (disabled) {
      this.model.controls['agreePrivacyStatement'].disable();
    } else {
      this.model.controls['agreePrivacyStatement'].enable();
    }
  }

  @Output()
  public privacyStatementChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private componentDestroyed$: Subject<void> = new Subject<void>();
  private disabledDefaultValue = false;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  ngOnInit(): void {
    this.buildPrivacyStatementHtml();
    this.showCheckbox = TenantSettingsHelper.getSettings().showSummaryPrivacyStatementCheckbox;
    const privacyModel: PrivacyStatementModel = new PrivacyStatementModel();
    this.model = this.formBuilder.group({
      ...privacyModel,
      agreePrivacyStatement: [{value: false, disabled: this.disabledDefaultValue}]
    });
    this.model.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((model: PrivacyStatementModel) => {
      this.privacyStatementChanged.emit(model.agreePrivacyStatement);
    });
    if (!this.showCheckbox) {
      privacyModel.agreePrivacyStatement = true;
      this.privacyStatementChanged.emit(privacyModel.agreePrivacyStatement);
    }
  }

  private buildPrivacyStatementHtml(): void{
    const agbLink: string = TenantTextHelper.findTextByKey('footerAgbLink') as string;
    const privacyLink: string = TenantTextHelper.findTextByKey('footerPrivacyProtectionLink') as string;

    this.privacyStatementHtml = (TenantTextHelper.findTextByKey('summaryPrivacyStatementText') as string)
      .replace(PrivacyStatementComponent.privacyProtectionPlaceholder, privacyLink)
      .replace(PrivacyStatementComponent.agbPlaceholder, agbLink);
  }
}
