import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayoutResizeOption } from './layout-resize-option';
import { TenantTextPipe } from '../../../../tenant-texts/tenant-text.pipe';

@Component({
  selector: 'sebu-html-template-summary',
  templateUrl: 'standard-layout-summary.component.html',
  styleUrls: ['standard-layout-summary.component.scss']
})
export class StandardLayoutSummaryComponent implements OnChanges, OnInit {
  @Input()
  public isStepEditable = true;
  @Input()
  public previewUrl: string;
  @Input()
  public previewIsLoading: boolean;

  @Output()
  public edit: EventEmitter<void> = new EventEmitter<void>();

  public availableResizeOptions: LayoutResizeOption[] = [];
  public windowWidth: number;
  public saveResourceUrl: SafeResourceUrl;
  public selectedResizeOption: LayoutResizeOption;

  private mobileWidth = 375;

  private resizeOptions: LayoutResizeOption[] = [
    {
      name: TenantTextPipe.transform('summaryPreviewButtonPortal') as string,
      width: 960
    }, {
      name: TenantTextPipe.transform('summaryPreviewButtonTablet') as string,
      width: 768
    },
    {
      name: TenantTextPipe.transform('summaryPreviewButtonMobile') as string,
      width: this.mobileWidth
    }
  ];


  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line
  public onResize($event: Event): void {
    this.filterResizeOptionsByScreenWidth();
  }

  constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.filterResizeOptionsByScreenWidth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.previewUrl && changes.previewUrl.currentValue) {
      this.saveResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrl);
      this.previewIsLoading = false;
    }
  }

  public changeResizeOption(resizeOption: LayoutResizeOption): void {
    this.selectedResizeOption = resizeOption;
  }

  private filterResizeOptionsByScreenWidth(): void {
    this.windowWidth = window.innerWidth;
    this.availableResizeOptions = [...this.resizeOptions]
      .sort(o => o.width)
      .filter((resizeOption: LayoutResizeOption) =>
        // mobile should always be selectable, the others only if screen is big enough to display them
        resizeOption.width <= window.innerWidth || resizeOption.width === this.mobileWidth);

    // if previous selection not available because of the window width, select the first available
    if (!this.selectedResizeOption || !this.availableResizeOptions
      .find((resizeOption: LayoutResizeOption) => resizeOption.name === this.selectedResizeOption.name)) {
      this.selectedResizeOption = this.availableResizeOptions[0];
      this.changeDetectorRef.detectChanges();
    }
  }
}
