import { CustomValidators } from '../../../shared/helper/custom-validation.helper';
import { HtmlTemplateConfiguration } from './standard-layout-configuration';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { FormGroupConfiguration } from '../../../dynamic-forms/models/form-group-configuration';
import { FormControlNameEnum } from '../../../dynamic-forms/models/form-control-name.enum';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { AttachmentControlConfiguration } from '../../../dynamic-forms/models/attachment-control-configuration';
import { ImageUploadControlConfiguration } from '../../../dynamic-forms/models/image-upload-control-configuration';
import { ColorPickerConfiguration } from '../../../dynamic-forms/models/color-picker-configuration';
import { FileExtension } from '../file-extension';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { InlineEditorConfiguration } from '../../../dynamic-forms/models/inline-editor-configuration';
import { HeaderControlConfiguration } from '../../../dynamic-forms/models/header-control-configuration';

export class RegioOnlineOnlyTemplateConfiguration implements HtmlTemplateConfiguration {

  formConfiguration: ControlConfiguration[] = [

    new ColorPickerConfiguration({
      formControlName: FormControlNameEnum.htmlTemplateColorPicker,
      displayWidth: 31

    }),

    new AttachmentControlConfiguration({
    formControlName: FormControlNameEnum.htmlTemplateAttachment,
    label: TenantTextPipe.transform('htmlTemplateAttachment') as string,
    placeholder: null,
    deletable: true,
    show: TenantSettingsHelper.getSettings().showAttachmentForStandardlayout,
    iconOnly: true,
          allowedFileTypes: [FileExtension.PDF, FileExtension.ZIP, FileExtension.CSV,
          FileExtension.RAR, FileExtension.ODS, FileExtension.DOCX, FileExtension.DOT, FileExtension.DOC, FileExtension.TXT,
          FileExtension.TAR, FileExtension.XLS, FileExtension.XLSX],
    validators: [
      CustomValidators.charsetAttachment,
      CustomValidators.fileSize(15 * 1024 * 1024), // 15 MB
            CustomValidators.fileExtension([FileExtension.PDF, FileExtension.ZIP, FileExtension.CSV,
            FileExtension.RAR, FileExtension.ODS, FileExtension.DOCX, FileExtension.DOT, FileExtension.DOC, FileExtension.TXT,
            FileExtension.TAR, FileExtension.XLS, FileExtension.XLSX])],
  }),

    new ImageUploadControlConfiguration({
      formControlName: FormControlNameEnum.htmlTemplateLogo,
      placeholder: TenantTextPipe.transform('htmlTemplateLogo') as string,
      imageUploadOptions: {
        allowedFileTypes: [FileExtension.JPG, FileExtension.JPEG, FileExtension.PNG, FileExtension.BMP, FileExtension.GIF],
      },
      displayWidth: 31,
    }),

    new InlineEditorConfiguration({
      formControlName: FormControlNameEnum.htmlTemplateCompanyName,
      initialValue: TenantTextPipe.transform('htmlTemplateCompanyName') as string,
      inputType: 'no-formatting',
      maxLength: 255,
      displayWidth: 48.5,
      validators: [CustomValidators.requiredNotBlank, CustomValidators.charset]
    }),

    new FormGroupConfiguration({
      formControlName: FormControlNameEnum.htmlTemplateBodyFormGroup,
      formGroupName: 'htmlTemplateBodyFormGroup',
      displayWidth: 100,

      controls: [

      new InlineEditorConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateIntroductionText,
        initialValue: TenantTextPipe.transform('htmlTemplateIntroductionText') as string,
        displayWidth: 100,
        validators: [CustomValidators.charset]
      }),

      new HeaderControlConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateCompanyDescription,
        header: 'Zum Unternehmen',
        displayWidth: 100,
        validators: [CustomValidators.charset]
      }),

      new InlineEditorConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateCompanyDescription,
        initialValue: TenantTextPipe.transform('htmlTemplateCompanyDescription') as string,
        displayWidth: 100,
        validators: [CustomValidators.charset]
      }),

      new FormGroupConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateYourAssignmentFormGroup,
        formGroupName: 'htmlTemplateYourAssignmentFormGroup',
        displayWidth: 100,
        controls: [
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateYourAssignmentsHeadline,
            initialValue: TenantTextPipe.transform('htmlTemplateYourAssignmentsHeadline') as string,
            inputType: 'text',
            validators: [CustomValidators.charset],
            maxLength: 255
          }),
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateYourAssignmentsText,
            initialValue: TenantTextPipe.transform('htmlTemplateYourAssignmentsText') as string,
            validators: [CustomValidators.charset]
          })
        ]
      }),

      new FormGroupConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateYourProfileFormGroup,
        formGroupName: 'htmlTemplateYourProfileFormGroup',
        displayWidth: 100,

        controls: [
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateYourProfileHeadline,
            initialValue: TenantTextPipe.transform('htmlTemplateYourProfileHeadline') as string,
            inputType: 'text',
            validators: [CustomValidators.charset],
            maxLength: 255
          }),
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateYourProfileText,
            initialValue: TenantTextPipe.transform('htmlTemplateYourProfileText') as string,
            validators: [CustomValidators.charset]
          })
        ]
      }),

      new FormGroupConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateWeOfferYouFormGroup,
        formGroupName: 'htmlTemplateWeOfferYouFormGroup',
        displayWidth: 100,
          controls: [
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateWeOfferYouHeadline,
            initialValue: TenantTextPipe.transform('htmlTemplateWeOfferYouHeadline') as string,
            inputType: 'text',
            validators: [CustomValidators.charset],
            maxLength: 255
          }),
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateWeOfferYouText,
            initialValue: TenantTextPipe.transform('htmlTemplateWeOfferYouText') as string,
            validators: [CustomValidators.charset]
          })
        ]
      }),

      new FormGroupConfiguration({
        formControlName: FormControlNameEnum.htmlTemplateJobApplyFormGroup,
        formGroupName: 'htmlTemplateJobApplyFormGroup',
        displayWidth: 100,

        controls: [
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateJobApplyHeadline,
            initialValue: TenantTextPipe.transform('htmlTemplateJobApplyHeadline') as string,
            inputType: 'text',
            validators: [CustomValidators.charset],
            maxLength: 255
          }),
          new InlineEditorConfiguration({
            formControlName: FormControlNameEnum.htmlTemplateJobApplyText,
            initialValue: TenantTextPipe.transform('htmlTemplateJobApplyText') as string,
            displayWidth: 100,
            validators: [CustomValidators.charset]
          })
        ]
      }),
      ]
    }),
    new FormGroupConfiguration({
      formControlName: FormControlNameEnum.htmlTemplateJobApplyContactFormGroup,
      formGroupName: 'htmlTemplateJobApplyFormGroup',
      displayWidth: 100,

      controls: [
        new InlineEditorConfiguration({
          formControlName: FormControlNameEnum.htmlTemplateJobApplyContactHeadline,
          initialValue: TenantTextPipe.transform('htmlTemplateJobApplyContactHeadline') as string,
          inputType: 'text',
          validators: [CustomValidators.charset],
          maxLength: 255
        }),
        new InlineEditorConfiguration({
          formControlName: FormControlNameEnum.htmlTemplateJobApplyContact,
          initialValue: TenantTextPipe.transform('htmlTemplateJobApplyContact') as string,
          validators: [CustomValidators.charset]
        })
      ]
    }),
  ];
}
