import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sebu-header-with-button',
  templateUrl: 'header-with-button.component.html',
  styleUrls: ['header-with-button.component.scss']
})
export class HeaderWithButtonComponent {
  @Input()
  public icon = 'mode_edit';

  @Input()
  public showEditButton = true;

  @Output()
  public buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public edit(): void {
    if (this.showEditButton) {
      this.buttonClick.emit();
    }
  }
}
