import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';


/**
 * Diese Klasse wird benutzt um SVGs direkt im HTML einzubinden.
 * Dadurch können diese per CSS gestyled werden.
 *
 * WICHTIG! CSS braucht die /::ng-deep anweisung für den path um die Encapsulation zu ignorieren
 *
 * Beispiel:
 *
 *  sebu-svg {
 *    ::ng-deep path {
 *        fill: your-favourite-color
 *    }
 *  }
 *
 *  Achtung! hierbei werden alle Pfade des SVGs gefärbt. Es muss evenutell per Hand über Klassen geregelt werden,
 *  welche der SVG Pfade eingefärbt werden sollen.
 */


@Component({
  selector: 'sebu-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit, OnDestroy, OnChanges {

  @Input() svgSrc: string;

  private icnSub: Subscription;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    this.loadSvg(this.svgSrc).pipe(takeUntil(this.componentDestroyed$)).subscribe(svg => this.setSvg(svg));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  // eslint-disable-next-line
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  private loadSvg(url: string): Observable<SVGElement> {
    return <Observable<SVGElement>> this.http.get(url, {responseType: 'text'})
      .pipe(map(res => {
        const div: HTMLElement = document.createElement('DIV');
        div.innerHTML = res;

        const svg: SVGElement = <SVGElement>div.querySelector('svg');
        return this.setupSVGScaling(svg);
      }));
  }

  private setSvg(svg: SVGElement): void {
    try {
      const icon: SVGElement = <SVGElement>svg.cloneNode(true);
      const elem: HTMLElement = this.element.nativeElement;
      elem.innerHTML = '';
      this.renderer.appendChild(elem, icon);
    } catch (error) {
      console.log(error);
    }
  }

  private setupSVGScaling(svg: SVGElement): SVGElement {
    svg.setAttribute('class', 'svg-max-height');
    const width: string = svg.getAttribute('width');
    const height: string = svg.getAttribute('height');


    if (width && height) {
      // eslint-disable-next-line
      svg.setAttribute('viewBox', `0 0 ${width.replace(/[^(\d*\.?\d+)]/igm, '')} ${height.replace(/[^(\d*\.?\d+)]/igm, '')}`);
    }

    // for autoscaling svgs matching into the parent container
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');

    return svg;
  }
}
