import { SummaryProductModel } from './summary-product.model';


export class SummaryFormModel {
  summaryProduct: SummaryProductModel;

  constructor() {
    this.summaryProduct = new SummaryProductModel();
  }
}
