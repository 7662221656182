import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmDialogService } from '../../../../core/services/confirm-dialog/confirm-dialog.service';
import { AvailableProduct } from '../../../models/available-product.model';
import { ProductFormModel } from '../../../models/wizard-form-models/product-form.model';
import { ProductEnum } from '../../../../api/client/index';
import { ProductSelectionComponent } from '../product-selection.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenantSettingsHelper } from '../../../../tenant-settings/tenant-settings.helper';
import { TenantTextHelper } from '../../../../tenant-texts/tenant-text.helper';
import { ProductArrayHelper } from '../product-array.helper';

@Component({
  selector: 'sebu-product-selection',
  templateUrl: './product-selection-dropdown.component.html',
  styleUrls: ['./product-selection-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProductSelectionDropdownComponent),
    multi: true
  }]
})
export class ProductSelectionDropdownComponent extends ProductSelectionComponent implements OnInit, OnDestroy {
  @Input()
  public set availableProducts(value: AvailableProduct[]){
    this._availableProductsDropDown = ProductArrayHelper.fillProductEnumInDurations(value)
      .sort(ProductArrayHelper.productsByWeightSorter);
  }
  public get availableProducts(): AvailableProduct[] {
    return this._availableProductsDropDown;
  }

  @Input()
  public willLooseData = false;

  @Output()
  public willLooseDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _availableProductsDropDown: AvailableProduct[];
  public productsAndDurations: UntypedFormArray;
  public selectedProduct: ProductFormModel = undefined;
  public showAsteriscPriceHint: boolean = TenantSettingsHelper.getSettings().showAsteriscPriceHint;
  public hasSebuProductIcon: boolean = TenantSettingsHelper.getSettings().hasSebuProductIcon;
  public ankerLinkText = TenantTextHelper.findTextByKey('productDescriptionAnkerLinkText');
  public showMoreInformationAnker: boolean = TenantSettingsHelper.getSettings().showMoreInformationAnker;

  private componentDestroyed$: Subject<void> = new Subject<void>();

  public propagateChange = (_: ProductFormModel) => {
  }

  constructor(protected confirmDialog: ConfirmDialogService,
              private formBuilder: UntypedFormBuilder) {
    super(confirmDialog);
  }

  ngOnInit(): void {
    this.productsAndDurations = this.formBuilder.array(this.availableProducts);
    this.productsAndDurations.controls.forEach(control => {
      control.setValue(control.value.availableDurations[0].value);
      control.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((changes: ProductFormModel) => this.selectProduct(changes));
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  writeValue(product: ProductFormModel): void {
    if (product.productEnum) {
      if (this.isNewProduct(product.productEnum)) {
        this.propagateChange(this.selectedProduct);
        this.triggerClearDialog(product);
      } else {
        this.getProductFormControl(product.productEnum).setValue(product);
      }
    }
  }

  public selectCard(product: ProductEnum): void {
    this.selectProduct(this.getProductFormControl(product).value);
  }

  public selectProduct(product: ProductFormModel): void {
    if (this.isNewProduct(product.productEnum)) {
      this.triggerClearDialog(product);
    } else {
      this.propagateChange(product);
      this.selectedProduct = product;
    }
  }

  private isNewProduct(productEnum: ProductEnum): boolean {
    return this.willLooseData && this.selectedProduct && productEnum !== this.selectedProduct.productEnum;
  }

  private triggerClearDialog(product: ProductFormModel): void {
    this.warnForClear().pipe(takeUntil(this.componentDestroyed$)).subscribe((clear: boolean) => {
      if (clear) {
        this.propagateChange(product);
        this.selectedProduct = product;
        this.willLooseDataChange.emit(false);
      }
    });
  }

  private compare(first: ProductFormModel, second: ProductFormModel): boolean {
    return first && second && first.duration === second.duration;
  }

  private getProductFormControl(productEnum: ProductEnum): AbstractControl {
    return this.productsAndDurations.controls.find((control: UntypedFormControl) => control.value.productEnum === productEnum);
  }
}
