<div class="thank-you-page">

  <div class="thank-you-container content">
    <div class="cloud-container">
    </div>
    <div class="party">
      <sebu-svg *ngIf="!thankYouOverSvg" [svgSrc]="getSVG('party.svg')"></sebu-svg>
      <div class="thank-you-heading">
        <h1>{{'thanksHeading' | TenantTextPipe }}</h1>
        <p sebuTenantText>thanksHeadingAddition</p>
      </div>
      <sebu-svg *ngIf="thankYouOverSvg" [svgSrc]="getSVG('party.svg')"></sebu-svg>
    </div>
  </div>

  <div class="procedure-container content" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="center start">

    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="40">
      <div *ngIf="customMailIconName; else defaultMailIcon">
        <img [src]=getSVG(customMailIconName) width="150" height="150"/>
      </div>
      <ng-template #defaultMailIcon><mat-icon>email</mat-icon></ng-template>
      <h2 sebuTenantText>checkMail</h2>
      <h3 sebuTenantText>checkMailAddition</h3>
    </div>

    <div class="dart-container" fxFlexAlign="center">
      <sebu-svg [svgSrc]="getSVG('arrow-thank-you.svg')"></sebu-svg>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="40">
      <div *ngIf="customWeekendIconName; else defaultWeekendIcon">
        <img [src]=getSVG(customWeekendIconName) width="150" height="150"/>
      </div>
      <ng-template #defaultWeekendIcon><mat-icon>weekend</mat-icon></ng-template>
      <h2 sebuTenantText>thenWhat</h2>
      <h3 sebuTenantText>thenWhatAddition</h3>
    </div>

  </div>

  <div class="content" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="28">
      <button mat-raised-button *ngIf="showHomeButton"
              (click)="goToStartPage()"
              sebuTenantText>buttonGoToStartPage</button>
    </div>
  </div>

</div>
