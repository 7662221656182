<sebu-header-with-button [showEditButton]="false">{{"summaryProductAndOptionsTitle"|TenantTextPipe}}
</sebu-header-with-button>
<form [formGroup]="summaryProduct"
      class="product-summary-wrapper">
  <div class="options-startdate-wrapper">
    <div class="startdate-wrapper">
      <!-- start date -->
      <h2>{{ 'summaryStartDate' | TenantTextPipe}}</h2>
      <mat-form-field class="full-width-input">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <input matInput
              [min]="minDate"
              formControlName="publicationDate"
              (dateChange)="propagateDateChanged()"
              (dateInput)="setDate($event)"
              (click)="picker.open()"
              [matDatepicker]="picker"
              placeholder="Geben Sie ein Startdatum Ihrer Anzeige an"
              readonly>
        <mat-datepicker [disabled]="false" #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="options-wrapper" *ngIf="hasAddonActivated">
      <h2>{{ 'summaryAddExtras' | TenantTextPipe}}</h2>
      <!-- refresh option -->
      <sebu-refresh-option-input [duration]="productPageModel.duration"
                                 [priceModel]="priceModel"
                                 formControlName="refreshOption"
                                 ngDefaultControl *ngIf="productPageModel.duration && priceModel.refreshPrice != null"
                                 class="refresh-option-feature">
      </sebu-refresh-option-input>

      <!-- top job -->
      <div
        class="option top-job-feature"
        *ngIf="priceModel.topJobPrice != null">
        <div fxLayoutAlign="space-between center" fxLayout="row">
          <label fxLayoutAlign="center center" class="cost-name" fxLayout="row" fxLayoutGap="0.5em">
            <span class="title">{{ 'topJob' | TenantTextPipe }}</span>
            <sebu-info-tooltip *ngIf="displayTopJobTooltip"
                               fxFlexAlign="end"
                               [content]="'topJobInfo' | TenantTextPipe"></sebu-info-tooltip>
          </label>
          <mat-checkbox class="price" formControlName="topJob"
                        labelPosition="before">{{priceModel.topJobPrice| currency:'EUR' }} <span
            *ngIf="showAsteriscPriceHint">*</span>
          </mat-checkbox>
        </div>
        <div *ngIf="priceModel.tableEntryPrice != null && priceModel.socialMediaPrice == null">
          <hr/>
        </div>
      </div>

      <!-- social media -->
      <div
        class="option social-media-feature"
        *ngIf="priceModel.socialMediaPrice != null">
        <div fxLayoutAlign="space-between center" fxLayout="row">
          <label fxLayoutAlign="center center" class="cost-name" fxLayout="row" fxLayoutGap="0.5em">
            <span class="title">{{ 'socialMedia' | TenantTextPipe }}</span>
            <sebu-info-tooltip *ngIf="displaySocialMediaTooltip"
                               fxFlexAlign="end"
                               [content]="'socialMediaInfo' | TenantTextPipe"></sebu-info-tooltip>
          </label>
          <mat-checkbox class="price" formControlName="socialMedia"
                        labelPosition="before">{{priceModel.socialMediaPrice| currency:'EUR' }} <span
            *ngIf="showAsteriscPriceHint">*</span>
          </mat-checkbox>
        </div>
        <div *ngIf="priceModel.tableEntryPrice != null">
          <hr/>
        </div>
      </div>

      <!-- table entry -->
      <div class="option table-entry-feature"
           *ngIf="priceModel.tableEntryPrice != null">
        <div fxLayoutAlign="space-between" fxLayout="row wrap">
          <label fxLayoutAlign="center center" class="cost-name" fxLayout="row" fxLayoutGap="0.5em">
            <span class="title">{{ 'tableEntry' | TenantTextPipe }}</span>
            <sebu-info-tooltip *ngIf="displayTableEntryTooltip"
                               fxFlexAlign="end"
                               [content]="'tableEntryInfo' | TenantTextPipe"></sebu-info-tooltip>
          </label>
          <mat-checkbox class="price" formControlName="tableEntry"
                        labelPosition="before">{{(priceModel.tableEntryPrice) | currency:'EUR'}} <span
            *ngIf="showAsteriscPriceHint">*</span>
          </mat-checkbox>
        </div>
      </div>
      <div class="disclaimer">
        <div fxLayout="row" *ngIf="(hasVat$| async) && showAsteriscPriceHint">{{'summaryPriceDisclaimer' | TenantTextPipe}}</div>
        <div class="refresh-price-disclaimer" *ngIf="displayRefreshPriceDisclaimer"
             fxLayout="row">{{'summaryRefreshPriceDisclaimer' | TenantTextPipe}}</div>
      </div>
    </div>
  </div>

  <div class="cost-summary-wrapper" *ngIf="productPageModel.duration">
    <div class="coupon-wrapper" *ngIf="paymentEnabled">
      <h2>{{ 'summaryCouponHeadline' | TenantTextPipe}}</h2>
      <sebu-coupon formControlName="coupon" ></sebu-coupon>
    </div>

    <h2>{{productPageModel.productName}}</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h4
        class="cost-name">{{'summaryProductTitle' | TenantTextPipe}} {{productPageModel.duration | DurationIsoPipe}}</h4>
      <h4 class="cost-value">{{(productPrice$ | async) | currency:'EUR' }} <span
        *ngIf="!(hasVat$| async) && showAsteriscPriceHint">*</span>
      </h4>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="(addOnPrice$| async)">
      <h4 class="cost-name">{{'summaryAddOns' | TenantTextPipe }}</h4>
      <h4 class="cost-value">{{(addOnPrice$ | async) | currency:'EUR' }} <span
        *ngIf="!(hasVat$| async) && showAsteriscPriceHint">*</span></h4>
    </div>

    <div class="discount-display" fxLayout="row" fxLayoutAlign="space-between" *ngIf="(summaryProduct.controls.coupon?.value)">
      <div class="discount" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.05em">
        <h4 class="cost-name-discount">{{'summaryDiscount' | TenantTextPipe }}</h4>
        <mat-icon class="discount-delete" (click)="clearDiscount()">delete_outline</mat-icon>
      </div>
      <h4 class="cost-value">- {{(discountAmount$ | async) | currency:'EUR'}} </h4>
    </div>

    <hr/>
    <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="(hasVat$| async)">
      <h4 class="cost-name">{{'summaryPriceSum' | TenantTextPipe }}</h4>
      <h4 class="cost-value">{{(totalPrice$ | async) | currency:'EUR' }}</h4>
    </div>
    <div class="vat-dispay" fxLayout="row" fxLayoutAlign="space-between" *ngIf="(hasVat$| async)">
      <h4 class="cost-name">{{'summaryVAT' | TenantTextPipe }} ({{priceModel.vatRate |  percent:'1.0-2'}})</h4>
      <h4 class="cost-value">{{(vatPrice$ | async) | currency:'EUR' }}</h4>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h3 class="cost-name">{{'summaryTotalPrice' | TenantTextPipe }}</h3>
      <h3 class="cost-value">{{(totalWithVatPrice$| async) | currency:'EUR' }} <span
        *ngIf="!(hasVat$| async) && showAsteriscPriceHint">*</span>
      </h3>
    </div>
    <div fxLayout="row" *ngIf="!(hasVat$| async) && showAsteriscPriceHint">{{'summaryPriceDisclaimer' | TenantTextPipe}}</div>
  </div>
</form>
