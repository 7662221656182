import * as _ from 'lodash';
import { CUSTOM_TENANT_SETTINGS } from '../../assets/custom-settings';
import { TenantSettings } from './tenant-settings.model';
import { DEFAULT_TENANT_SETTINGS } from './default-settings';

export class TenantSettingsHelper {

  /**
   * Returns a 1-level merge of the given default and custom settings
   * @returns {T}
   */
  public static combineSettings<T>( defaultSettings: T, customSettings: T): T {
    return _.defaults(_.cloneDeep(customSettings), _.cloneDeep(defaultSettings));
  }

  /**
   * Returns a 1-level merge of the given default and custom settings
   * @returns {TenantSettings}
   */
  public static getSettings(): TenantSettings {
    return _.defaults(_.cloneDeep(CUSTOM_TENANT_SETTINGS), _.cloneDeep(DEFAULT_TENANT_SETTINGS)) as TenantSettings;
  }
}
