export class StringHelper {
  public static capitalizeFirstLetter(original: string): string {
    return original ? `${original.charAt(0).toUpperCase()}${original.slice(1)}` : original;
  }

  public static querySortComparator(query: string): (a: string, b: string) => number {
    return (a: string, b: string) => {
      if (a.toLowerCase().startsWith(query) && !b.toLowerCase().startsWith(query)) {
        return -1;
      } else if (b.toLowerCase().startsWith(query) && !a.toLowerCase().startsWith(query)) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    };
  }
}
