import { Component } from '@angular/core';
import { TenantSettingsHelper } from '../../tenant-settings/tenant-settings.helper';
import { TenantTextHelper } from '../../tenant-texts/tenant-text.helper';

@Component({
  selector: 'sebu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public showToolTipOnApplicationLoad = false;
  public logoLink: string;
  public showSebuHeader = true;

  constructor() {
    this.showToolTipOnApplicationLoad = TenantSettingsHelper.getSettings().showSupportDialogOnApplicationStart;
    this.showSebuHeader = TenantSettingsHelper.getSettings().showSebuHeader;
    if (TenantTextHelper.hasValue('sebuHeaderLogoLink')) {
      this.logoLink = TenantTextHelper.texts.sebuHeaderLogoLink;
    } else {
      this.logoLink = null;
    }
  }

}
