import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, AppConfig } from './app.config';
import { ConfirmationPageModule } from './confirmation-page/confirmation-page.module';
import { CoreModule } from './core/core.module';
import { StatusPageModule } from './status-page/status-page.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { WizardModule } from './wizard/wizard.module';
import { provideErrorHandler } from './core/sentry/raven-error-handler';
import { SebuFormComponentsModule } from './form-components/sebu-form-components.module';
import { BrowserModule } from '@angular/platform-browser';
import { TenantSettingsHelper } from './tenant-settings/tenant-settings.helper';
import { SnippetComponent } from '../assets/snippet.component';
import { TitleComponent } from './title.component';
import { MetaComponent } from './meta.component';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AttachmentUploadModule } from './shared/attachment-upload/attachment-upload.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    SnippetComponent,
    TitleComponent,
    MetaComponent
  ],
  exports: [
    SnippetComponent,
    TitleComponent,
    MetaComponent
  ],
    imports: [
        ApiModule,
        UiComponentsModule,
        WizardModule,
        ConfirmationPageModule,
        StatusPageModule,
        CoreModule,
        AppRoutingModule,
        BrowserModule,
        SebuFormComponentsModule,
        AttachmentUploadModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([AppEffects]),
        SharedModule
    ],
  providers: [
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: 'CantLeave', useValue: () => false},
    { provide: 'AllowLeaveWhenRequired', useValue: () => TenantSettingsHelper.getSettings().showHomeButtonOnThankYouPage},
    { provide: ErrorHandler, useFactory: provideErrorHandler }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
