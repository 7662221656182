import { ProductEnum } from '../../api/client';
import * as Actions from '../../confirmation-page/ngrx/actions';
import { PayloadAction } from '../../core/models/payload-action.model';
import { ProductMapperHelper } from '../../core/utils/mapping-helper/product-mapper.helper';
import { ConfirmationPageState } from '../models/confirmation-page.model';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';

export function confirmationPageReducer(state: ConfirmationPageState = new ConfirmationPageState(), action: PayloadAction): ConfirmationPageState {
  switch (action.type) {
    /*eslint-disable no-case-declarations*/
    case Actions.LOAD_OFFER_ORDER_DATA_SUCCESS:
      const payload = action.payload;
      return {
        ...state,
        productConfiguration: ProductMapperHelper.mapProductToConfiguration(payload.createData.product),
        offerOrderData: payload.createData,
        formModels: {
          product: ProductMapperHelper.mapProductDataToProductFormModel(payload.createData, payload.productName),
          company: ProductMapperHelper.mapCompanyDataToCompanyFormModel(payload.createData.company),
          offerOrder: ProductMapperHelper.mapJobDataToOfferOrderFormModel(payload),
          standardLayout: (payload.createData.product === ProductEnum.RegioOnlineOnlyStandardlayout
            || payload.createData.product === ProductEnum.RegioOnlineOnlyAzubi
            || payload.createData.product === ProductEnum.RegioOnlineOnlyMinijobs) ?
            ProductMapperHelper.mapProductToStandardLayoutFormModel(payload.createData) : null,
          summary: ProductMapperHelper.mapProductDataToSummaryFormModel(payload.createData)
        }
      };

    default:
      return state;
  }
}
export const getWizzardFormModelsEntities: (_: ConfirmationPageState) => WizardFormModel = (state: ConfirmationPageState) => state.formModels;
