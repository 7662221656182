import { Component, OnDestroy, OnInit } from '@angular/core';
import { AttachmentModel } from '../attachment-model';
import { Subject } from 'rxjs/internal/Subject';
import { FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from '../../helper/custom-validation.helper';
import { AttachmentFileType } from '../file-types.model';
import * as JSZip from 'jszip';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../spinner.service';

@Component({
  selector: 'sebu-zip-upload-dialog',
  templateUrl: './zip-upload-dialog.component.html',
  styleUrls: ['./zip-upload-dialog.component.scss']
})

export class ZipUploadDialogComponent implements OnInit, OnDestroy{

  public attachmentFiles$: Map<string, {buffer: ArrayBuffer, base64String: string}> = new Map<string, {buffer: ArrayBuffer, base64String: string}>();
  public attachmentSizeInBytes = 0;
  public allowedFileTypeHint: string;
  public warningMessage = '';
  public allowedFileTypes: string;
  public isAttachmentChanged = false;
  public formDisabled = false;
  public attachment: AttachmentModel;
  public attachmentCallback: (attachment: AttachmentModel) => void;
  public MegaByteToByteConst = 1048576;

  private fileReader: FileReader = new FileReader();
  private componentDestroyed$: Subject<void> = new Subject<void>();
  private validators: ((control: FormControl) => ValidationErrors)[] = [
    CustomValidators.charsetAttachment,
    CustomValidators.attachmentFileExtension(AttachmentFileType.RESTRICTED),
    Validators.required
  ];
  private MAX_ALLOWED_FILE_SIZE_IN_BYTES: number = 15 * 1024 * 1024;

  constructor(public dialogRef: MatDialogRef<ZipUploadDialogComponent>, private spinnerService: SpinnerService) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  ngOnInit(): void {
    this.checkFormat(this.attachment);
  }

  public save(): void {
    if (this.attachmentFiles$.size === 0) {
      this.attachmentCallback(null);
    } else if (this.attachmentFiles$.size === 1) {
      let attachment: AttachmentModel;
      this.attachmentFiles$.forEach((value, key) => {
        attachment = {
          name: key,
          bytes: value.base64String
        };
      });
      this.attachmentCallback(attachment);
      this.dialogRef.close();
    } else {
      this.spinnerService.start(50);
      this.formDisabled = true;
      const jsZip: JSZip = new JSZip();
      this.attachmentFiles$.forEach((value, key) => {
        jsZip.file(key, value.buffer);
      });
      jsZip.generateAsync({ type: 'blob' })
        .then((content: Blob) => {
          this.fileReader.readAsDataURL(content);
          this.fileReader.onloadend = () => {
            const attachment: AttachmentModel = {
              name: 'attachment.zip',
              bytes: this.fileReader.result.toString().split(',')[1]
            };
            this.attachmentCallback(attachment);
            this.spinnerService.stop();
            this.dialogRef.close();
          };
        }).catch(() => {
        this.formDisabled = false;
        this.spinnerService.stop();
        console.error('Failed to pack zip file with samples');
      });
    }
  }

  public replaceFile(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files[0];
    if (!file) {
      return;
    }
    if (file.size > this.MAX_ALLOWED_FILE_SIZE_IN_BYTES) {
      this.warningMessage = 'Durch das Hochladen der Datei "' + file.name + '" würde der Anhang die Maximalgröße von 15 MB überschreiten.';
    } else {
      this.attachmentFiles$.delete(this.attachmentFiles$.entries().next().value[0]);
      this.uploadFile(event, true);
    }
  }

  public uploadFile(event: Event, isReplace: boolean, fileIndex = 0): void {
    if ((<HTMLInputElement>event.target).files.length <= fileIndex) {
      return;
    }
    const file: File = (<HTMLInputElement>event.target).files[fileIndex];
    if (!isReplace && !file) {
      return;
    }
    if (!isReplace && this.attachmentSizeInBytes + file.size > this.MAX_ALLOWED_FILE_SIZE_IN_BYTES) { // 15MB * 1024 * 1024
      this.warningMessage = 'Durch das Hochladen der Datei "' + file.name + '" würde der Anhang die Maximalgröße von 15 MB überschreiten.';
    } else {
      const validationErrors: ValidationErrors = this.isFileInputValid(file, this.validators);
      if (!validationErrors) {
        this.warningMessage = '';
        let base64: string;
        let buffer: ArrayBuffer;
        this.fileReader.readAsDataURL(file);
        this.fileReader.onloadend = () => {
          base64 = this.fileReader.result.toString().split(',')[1];
          this.fileReader.readAsArrayBuffer(file);
          this.fileReader.onloadend = () => {
            buffer = this.fileReader.result as ArrayBuffer;
            this.addToAttachmentFiles(file.name, buffer, base64);
            // load the next file
            this.uploadFile(event, false, fileIndex + 1);
          };
        };
      } else {
        // eslint-disable-next-line
        if (!!validationErrors.invalidFileType) {
          this.warningMessage = 'Ungültiger Dateityp, verwenden Sie stattdessen: ' + this.allowedFileTypes + '.';
          // eslint-disable-next-line
        } else if (!!validationErrors.invalidChars) {
          this.warningMessage = 'Der Dateiname enthält ein ungültiges Zeichen.';
        }
      }
    }
  }

  public removeFromAttachmentFiles(attachmentName: string): void {
    if (!this.isAttachmentChanged) {
      this.isAttachmentChanged = true;
    }
    this.warningMessage = '';
    this.attachmentFiles$.delete(attachmentName);
    this.calculateAttachmentSize();
  }

  private checkFormat(attachment: AttachmentModel): void {
    if (attachment) {
      if (attachment.name.split('.')[1] === 'zip') {
        this.setExistingZipAttachment(attachment);
      } else {
        this.setExistingUnzippedAttachment(attachment);
      }
    }
  }

  private isFileInputValid(file: File, validators: ValidatorFn[]): ValidationErrors | null {
    const formControl: FormControl = new FormControl();
    formControl.setValidators(validators);
    formControl.setValue(file);
    formControl.updateValueAndValidity();
    return formControl.errors;
  }

  private addToAttachmentFiles(name: string, buffer: ArrayBuffer, base64String: string): void {
    if (!this.isAttachmentChanged) {
      this.isAttachmentChanged = true;
    }
    this.attachmentFiles$.set(name, {buffer, base64String});
    this.calculateAttachmentSize();
  }


  private setExistingUnzippedAttachment(uploadedFile: AttachmentModel): void {
    this.attachmentFiles$.set(uploadedFile.name, {buffer: this.base64ToArrayBuffer(uploadedFile.bytes), base64String: uploadedFile.bytes});
    this.calculateAttachmentSize();
  }

  private setExistingZipAttachment(attachment: AttachmentModel): void {
    const zip: JSZip = new JSZip();
    zip.loadAsync(attachment.bytes, {base64: true})
      .then((contents) => {Object.keys(contents.files)
        .forEach((filename) => {zip.file(filename).async('arraybuffer')
          .then((content) => {
            this.attachmentFiles$.set(filename, {buffer: content, base64String: attachment.bytes});
            this.calculateAttachmentSize();
          });
        });
      });
  }

  private calculateAttachmentSize(): void {
    let size = 0;
    this.attachmentFiles$.forEach((file) => {
      size += file.buffer.byteLength;
    });
    this.attachmentSizeInBytes = size;
  }

  private base64ToArrayBuffer(base64: string): ArrayBufferLike {
    const binary_string: string = window.atob(base64);
    const len: number = binary_string.length;
    const bytes: Uint8Array = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
