import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../shared/helper/custom-validation.helper';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { AttachmentControlConfiguration } from '../../models/attachment-control-configuration';
import { ControlConfiguration } from '../../models/control-configuration';
import { ControlType } from '../../models/control-type.enum';
import { FileExtension } from '../../../wizard/models/file-extension';
import { ImageUploadControlConfiguration } from '../../models/image-upload-control-configuration';
import { FileExtensionHelper } from '../../../shared/helper/file-extension-helper';

@Component({
  selector: 'sebu-form-control-factory',
  templateUrl: './form-control-factory.component.html',
  styleUrls: ['./form-control-factory.component.scss']
})
export class FormControlFactoryComponent {
  @Input()
  public control: ControlConfiguration;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public formControlClass: string;

  public TenantTextPipeRef: TenantTextPipe = TenantTextPipe;

  public CONTROL_TYPE: typeof ControlType = ControlType;

  public hasError(formControlName: string, errorType: string): boolean {
    const formControl: AbstractControl = this.form.get(formControlName);
    return formControl ? formControl.hasError(errorType) : false;
  }

  public isRequired(): string {
    if (this.control.validators
        .map(validator => validator === Validators.required || validator === CustomValidators.requiredNotBlank)[0]) {
        return ' '.concat(TenantTextPipe.transform('isRequired') as string);
    } else {
      return '';
    }
  }

  public getAllowedFileTypesToDisplay(): string {
    let allowedTypes: FileExtension[];
    if (this.control instanceof AttachmentControlConfiguration) {
      allowedTypes = this.control.allowedFileTypes;
    } else if (this.control instanceof ImageUploadControlConfiguration) {
      allowedTypes = this.control.imageUploadOptions.allowedFileTypes;
    }
    return FileExtensionHelper.getFileTypesAsString(allowedTypes);
  }
}
