import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { OfferOrderDetailsComponent } from './offer-order-details.component';
import { DynamicFormsModule } from '../../../dynamic-forms/dynamic-forms.module';
import { SebuFormComponentsModule } from '../../../form-components/sebu-form-components.module';
import { StoreModule } from '@ngrx/store';
import { wizardReducer } from '../../ngrx/reducer/reducer';

@NgModule({
  imports: [
    SharedModule,
    SebuFormComponentsModule,
    DynamicFormsModule,
    StoreModule.forFeature('wizard', wizardReducer),
  ],
  declarations: [
    OfferOrderDetailsComponent
  ],
  exports: [OfferOrderDetailsComponent]
})
export class OfferOrderDetailsModule { }
