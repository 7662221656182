import { OfferLocationTypeEnum } from '../../../api/client';


export class LocationDisplay {
  public type: OfferLocationTypeEnum; // Ort, Bundesland or Land
  public value: string;
  public valueToDisplay: string;

  constructor(type: OfferLocationTypeEnum, value: string, display?: string) {
    this.type = type;
    this.value = value;
    this.valueToDisplay = display || value;
  }

  public equals(other: LocationDisplay): boolean {
    if (!other) {
      return false;
    }
    return this.type === other.type
      && this.value === other.value
      && this.valueToDisplay === other.valueToDisplay;
  }
}

export class CountryLocationDisplay extends LocationDisplay {
  constructor(countryName: string) {
    super(OfferLocationTypeEnum.Land, countryName);
  }
}

export class StateLocationDisplay extends LocationDisplay {
  constructor(stateName: string, display?: string) {
    super(OfferLocationTypeEnum.Bundesland, stateName, display);
  }
}

export class CityLocationDisplay extends LocationDisplay {
  constructor(id?: string, display?: string) {
    super(OfferLocationTypeEnum.Ort, id || '', display);
  }
}
