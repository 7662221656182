import { ErrorHelper } from '../../core/utils/error/error.helper';
import { StandardLayoutFormModel } from '../../wizard/models/wizard-form-models/standard-layout-form.model';

export class FormFactoryHelper {

  /***
   * This method flattens an deeply nested object that comes in.
   * We need it to flatten the values that are created by the FormFactory if it contains FormGroups.
   * Example:
   *  {                           {
   *    someString1: '',            someString1 = ''
   *    someObject: {               someString2 = ''
   *      someString2: '',  =>      someString3 = ''
   *      someString3: '',        }
   *    }
   *  }
   * @param {Object} object that needs to be flattened
   * @returns {Object} flattened object
   */
  static flattenObject(object: Object): Object {
    let finalObject: Object = {};
    Object.keys(object)
      .forEach(key => {
        const value: Object = object[key as string];
        const isObject: boolean = (typeof value === 'object') && value !== null;

        // eslint-disable-next-line
        if ( isObject ? finalObject.hasOwnProperty(Object.keys(value)[0]) : finalObject.hasOwnProperty(key)) {
          const val: string = typeof value === 'object' ? Object.keys(value)[0] : key;
          ErrorHelper.throwError(`Value ${val} already defined for object!`);
        }

        isObject ? finalObject = { ...finalObject, ...this.flattenObject(value) } : finalObject = { ...finalObject, [key]: value };
      });
    return finalObject;
  }

  /* tslint:disable:no-any */
  static parseHtmlConfigurationToFormModel(templateData: any): StandardLayoutFormModel {
    const flatenTemplateData: any = FormFactoryHelper.flattenObject(templateData);
    const formModel: StandardLayoutFormModel = {
      companyName: flatenTemplateData.htmlTemplateCompanyName,
      introductionText: flatenTemplateData.htmlTemplateIntroductionText,
      yourAssignmentsHeadline: flatenTemplateData.htmlTemplateYourAssignmentsHeadline,
      yourAssignmentsText: flatenTemplateData.htmlTemplateYourAssignmentsText,
      yourProfileHeadline: flatenTemplateData.htmlTemplateYourProfileHeadline,
      yourProfileText: flatenTemplateData.htmlTemplateYourProfileText,
      weOfferYouHeadline: flatenTemplateData.htmlTemplateWeOfferYouHeadline,
      weOfferYouText: flatenTemplateData.htmlTemplateWeOfferYouText,
      companyDescription: flatenTemplateData.htmlTemplateCompanyDescription,
      jobApplyHeadline: flatenTemplateData.htmlTemplateJobApplyHeadline,
      jobApplyText: flatenTemplateData.htmlTemplateJobApplyText,
      jobApplyContactHeadline: flatenTemplateData.htmlTemplateJobApplyContactHeadline,
      jobApplyContact: flatenTemplateData.htmlTemplateJobApplyContact,
      logo: templateData.htmlTemplateLogo,
      attachment: templateData.htmlTemplateAttachment,
      attachmentHeadline: templateData.htmlTemplateAttachmentHeadline,
      color: flatenTemplateData.htmlTemplateColorPicker
    };
    return formModel;
  }
  /* tslint:enable:no-any */

}
