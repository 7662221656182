import { Pipe, PipeTransform } from '@angular/core';
import { TenantTextHelper } from './tenant-text.helper';

@Pipe({
  name: 'TenantTextPipe'
})
export class TenantTextPipe implements PipeTransform {

  transform(key: string, replacement?: string): string | Array<string> {
    return TenantTextPipe.transform(key, replacement);
  }

  static transform(key: string, replacement?: string): string | Array<string>  {
    const value: string | Array<string> = replacement
      ? TenantTextHelper.findTextByKeyAndReplace(key, replacement)
      : TenantTextHelper.findTextByKey(key)
    ;
    return value;
  }
}
