import { throwError as observableThrowError, Observable } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import * as Raven from 'raven-js';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpLoggerInterceptor implements HttpInterceptor {

  private static counter = 0;

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    const id: number = HttpLoggerInterceptor.counter++;
    if (environment.logHttp) { console.log(`HTTP REQUEST ${id}: ${req.method} ${req.url}`, req); }
    return next.handle(req)
      .pipe(
        tap((res: HttpEvent<Object>) => {
          let prefix = 'HttpEvent';
          if (res instanceof HttpResponse) {
            prefix = 'HTTP RESPONSE';
          }
          if (environment.logHttp) { console.log(`${prefix} ${id}: `, res); }
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 500 || err.status === 0 || err.status === 400 || err.status === 503) {
            Raven.captureException(err.message || err);
          }
          return observableThrowError(err);
        })
      );
  }

}
