import { CategoryData, SebuOfferOrderCreate, SebuResellerData } from '../../api/client';

export class ConfirmationPageData  {
  createData: SebuOfferOrderCreate;
  allCategories?: CategoryData[];
  productName?: string;

  constructor(createData: SebuOfferOrderCreate,
              allCategories?: CategoryData[],
              reseller?: SebuResellerData) {
    this.createData = createData;
    this.allCategories = allCategories;

    // Search reseller products for name
    this.productName = reseller.products.find(p => p.productId === createData.product).resellerProductName;
  }
}
