// feature selector
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { OfferOrderActionEnum } from '../../api/client';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';
import { StatusPageState } from '../models/status-page';
import {
  getFormModelEntities, getStatusPageAllowedActionsEntities
} from './reducer/reducer';

export const getOfferOrderStatusPageState: MemoizedSelector<Object, StatusPageState> =
  createFeatureSelector<StatusPageState>('statusPage');

export const getStatusPageAllowedActionsSelector: MemoizedSelector<StatusPageState, OfferOrderActionEnum[]>
  = createSelector(getOfferOrderStatusPageState, getStatusPageAllowedActionsEntities);

export const getFormModelsSelector: MemoizedSelector<StatusPageState, WizardFormModel>
  = createSelector(getOfferOrderStatusPageState, getFormModelEntities);
