import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public status: Subject<boolean> = new Subject();
  private queue: Array<boolean> = [];

  public get active(): boolean {
    return this.queue.length > 0;
  }

  public start(delay = 2000): void {
    this.queue.push(true);
    timer(delay).subscribe(() => this.update());
  }

  public stop(): void {
    this.queue.pop();
    this.update();
  }

  private update(): void {
    this.status.next(this.active);
  }
}
