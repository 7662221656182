import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyFormModel } from '../../../models/wizard-form-models/company-form.model';
import { WizardState } from '../../../ngrx/reducer/wizard-state.model';

@Component({
  selector: 'sebu-company-summary',
  templateUrl: 'company-summary.component.html',
  styleUrls: [ 'company-summary.component.scss' ]
})
export class CompanySummaryComponent {

  @Output()
  public edit: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public companyData: CompanyFormModel;

  @Input()
  public isStepEditable = true;

  constructor( private store: Store<WizardState> ) {}


  public clickEdit(): void {
    this.edit.emit();
  }
}
