import { PayloadAction } from '../../core/models/payload-action.model'; import { ProductConfiguration } from '../models/product-configuration/product-configuration';
import { ProductFormModel } from '../models/wizard-form-models/product-form.model';
import { WizardFormModel } from '../models/wizard-form-models/wizard-form.model';
import { SebuResellerData } from '../../api/client';
import { PopupWindow } from '../services/new-window-opener.service';

export const SAVE_PRODUCT_STEP_ON_SUMMARY_PAGE = '[Product step] save product';
export const LOAD_PRODUCTS_AND_ADDONS = '[Offer Order Wizard] load products and addons';
export const LOAD_PRODUCTS_AND_ADDONS_SUCCESS = '[Offer Order Wizard] load products and addons SUCCESS';
export const LOAD_PRODUCTS_AND_ADDONS_ERROR = '[Offer Order Wizard] load products and addons ERROR';
export const SAVE_ORDER_FORM_CONFIGURATION = '[Offer Order Wizard] save selected product configuration';
export const LOAD_ORDER_FORM_CONFIGURATION = '[Offer Order Wizard] get selected product configuration';
export const CREATE_SEBU_OFFER_ORDER_ERROR = '[Offer Order Wizard] error while creating an sebu offer offerOrder';
export const SAVE_ALL_STEPS = '[Save all steps] saved all steps';
export const FETCH_PREVIEW = '[Fetch preview] get html preview';
export const FETCH_PREVIEW_SUCCESS = '[Fetch preview] get html preview successfully';
export const THROW_ERROR = '[Throw error] ein Fehler ist aufgetreten';


export class SaveProductStep implements PayloadAction {
  readonly type: string = SAVE_PRODUCT_STEP_ON_SUMMARY_PAGE;
  constructor(public payload: ProductFormModel) {
  }
}

export class LoadProductsAndAddons implements PayloadAction {
  readonly type: string = LOAD_PRODUCTS_AND_ADDONS;
}

export class LoadProductsAndAddonsSuccess implements PayloadAction {
  readonly type: string = LOAD_PRODUCTS_AND_ADDONS_SUCCESS;
  constructor(public payload: SebuResellerData) {
  }
}

export class LoadProductsAndAddonsError implements PayloadAction {
  readonly type: string = LOAD_PRODUCTS_AND_ADDONS_ERROR;
}

export class SaveOrderFormConfiguration implements PayloadAction {
  readonly type: string = SAVE_ORDER_FORM_CONFIGURATION;
  constructor(public payload: ProductConfiguration) {
  }
}

export class LoadOrderFormFormConfiguration implements PayloadAction {
  readonly type: string = LOAD_ORDER_FORM_CONFIGURATION;
}

export class SaveAllSteps implements PayloadAction {
  readonly type: string = SAVE_ALL_STEPS;
  constructor(public payload: WizardFormModel) {
  }
}

export class PreviewActionPayload {
  constructor(public readonly model: WizardFormModel, public readonly popupWindow: PopupWindow) {}
}

export class FetchPreview implements PayloadAction {
  readonly type: string = FETCH_PREVIEW;
  constructor(public payload: WizardFormModel) {}
}

export class FetchPreviewSuccessfully implements PayloadAction {
  readonly type: string = FETCH_PREVIEW_SUCCESS;
  constructor(public payload: string) {}
}

export class ThrowError implements PayloadAction {
  readonly type: string = THROW_ERROR;
}

export type Actions =
  SaveProductStep
  | LoadProductsAndAddons
  | LoadProductsAndAddonsSuccess
  | LoadProductsAndAddonsError
  | SaveOrderFormConfiguration
  | LoadOrderFormFormConfiguration
  | SaveAllSteps
  | FetchPreview
  | FetchPreviewSuccessfully
  | ThrowError;
