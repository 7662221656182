export const EDITOR_STYLES: String = "<style>" +
  "p {margin: 0; padding: 0;}" +
  ".ql-indent-1 {text-indent: 3em}" +
  ".ql-indent-2 {text-indent: 6em}" +
  ".ql-indent-3 {text-indent: 9em}" +
  ".ql-indent-4 {text-indent: 12em}" +
  ".ql-indent-5 {text-indent: 15em}" +
  ".ql-indent-6 {text-indent: 18em}" +
  ".ql-indent-7 {text-indent: 21em}" +
  ".ql-indent-8 {text-indent: 24em}"
  +"</style>";
