import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StandardLayoutFormModel } from '../../models/wizard-form-models/standard-layout-form.model';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { Subject, Subscription } from 'rxjs';
import { FormBuilderHelper } from '../../../dynamic-forms/form-builder.helper';
import { FormFactoryHelper } from '../../../dynamic-forms/helper/form-factory.helper';
import { takeUntil } from 'rxjs/operators';
import { FormControlNameEnum } from '../../../dynamic-forms/models/form-control-name.enum';
import { ControlType } from '../../../dynamic-forms/models/control-type.enum';
import { OfferOrderFormModel } from '../../models/wizard-form-models/offer-order-form.model';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';

@Component({
  selector: 'sebu-html-template-details',
  templateUrl: './standard-layout-details.component.html',
  styleUrls: ['./standard-layout-details.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StandardLayoutDetailsComponent),
    multi: true
  }]
})
export class StandardLayoutDetailsComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input()
  public controlConfiguration: ControlConfiguration[] = [];

  @Input()
  public offerOrderValue: OfferOrderFormModel;

  public FormControlNameEnum: typeof FormControlNameEnum = FormControlNameEnum;
  public ControlType: typeof ControlType = ControlType;

  public standardLayoutDetailsFormGroup: UntypedFormGroup;
  public showAttachmentForStandardlayout = true;

  private standardLayoutFormSubscription: Subscription;
  private componentDestroyed$: Subject<void> = new Subject<void>();

  public propagateChange = (_: StandardLayoutFormModel) => {
  }

  ngOnInit(): void {
    this.standardLayoutDetailsFormGroup = FormBuilderHelper.group(this.controlConfiguration);
    this.standardLayoutFormSubscription = this.standardLayoutDetailsFormGroup.valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.propagateData();
      });
    this.showAttachmentForStandardlayout = TenantSettingsHelper.getSettings().showAttachmentForStandardlayout;
    this.propagateData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  // eslint-disable-next-line
  writeValue(offerOrderDetailsModel: StandardLayoutFormModel): void {
  }

  registerOnChange(fn: (_: StandardLayoutFormModel) => {}): void {
    this.propagateChange = fn;
  }

  propagateData(): void {
    const templateData: StandardLayoutFormModel = FormFactoryHelper
      .parseHtmlConfigurationToFormModel(this.standardLayoutDetailsFormGroup.getRawValue());
    this.standardLayoutDetailsFormGroup.valid ? this.propagateChange(templateData) : this.propagateChange(undefined);
  }

  // eslint-disable-next-line
  registerOnTouched(fn: string): void {
  }

  // eslint-disable-next-line
  filterControlConfiguration(formControlName: FormControlNameEnum): any {
    return this.controlConfiguration.filter(x => x.formControlName === formControlName)[0];
  }
}
