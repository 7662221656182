export enum ControlType {
  INPUT,
  TEXTAREA,
  AUTOCOMPLETE,
  ATTACHMENT,
  SELECT,
  LOCATION,
  CATEGORIES,
  FORM_GROUP,
  HTML_EDITOR,
  LOGO,
  COLOR_PICKER,
  HEADER,
  INLINE_EDITOR
}
