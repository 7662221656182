import { NgModule } from '@angular/core';
import { SebuFormComponentsModule } from '../form-components/sebu-form-components.module';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared.module';
import { ConfirmDialogComponent } from '../core/services/confirm-dialog/confirm-dialog.component';

@NgModule({
    imports: [
        // all dialog related modules and and general components are registered here
        MaterialModule,
        SebuFormComponentsModule,
        SharedModule
    ],
    declarations: [
        ConfirmDialogComponent
    ],
    exports: [
        ConfirmDialogComponent
    ]
})

export class DialogModule {
}
