import { Validators } from '@angular/forms';
import { AttachmentControlConfiguration } from '../../../dynamic-forms/models/attachment-control-configuration';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { FormControlNameEnum } from '../../../dynamic-forms/models/form-control-name.enum';
import { InputControlConfiguration } from '../../../dynamic-forms/models/input-control-configuration';
import { TextareaControlConfiguration } from '../../../dynamic-forms/models/textarea-control-configuration';
import { CustomValidators } from '../../../shared/helper/custom-validation.helper';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { ProductConfiguration, ProductDescription } from './product-configuration';
import { ProductEnum } from '../../../api/client';
import { FileExtensionHelper } from '../../../shared/helper/file-extension-helper';

export class PremiumOnlineOnlyProductConfiguration implements ProductConfiguration {
  public iconName = 'premium-online-only.svg';
  public productEnum: ProductEnum = ProductEnum.OnlineOnlyPdfUndSonstigesFormat;
  public description: ProductDescription = {
    descriptionHeading: 'premiumOnlineOnlyDescriptionHeading',
    description: 'premiumOnlineOnlyDescription',
    descriptionSubHeadline: 'premiumOnlineOnlyDescriptionSubHeadline',
    descriptionWhatYouGet: 'premiumOnlineOnlyDescriptionWhatYouGet',
    descriptionWhatYouGetSubText:'premiumOnlineOnlyDescriptionWhatYouGetSubText'
  };
  public formConfiguration: Array<ControlConfiguration> = [
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.title,
      placeholder: TenantTextPipe.transform('offerOrderTitle') as string,
      inputType: 'text',
      validators: [CustomValidators.requiredNotBlank, CustomValidators.charset],
      maxLength: 255,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.applicationEmail,
      placeholder: TenantTextPipe.transform('applicationEmail') as string,
      inputType: 'text',
      validators: [CustomValidators.sareEmail],
      maxLength: 100,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.applicationUrl,
      placeholder: TenantTextPipe.transform('applicationUrl') as string,
      inputType: 'url',
      validators: [CustomValidators.charset, CustomValidators.url],
      maxLength: 2083,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.alternativeCompany,
      placeholder: TenantTextPipe.transform('alternativeCompany') as string,
      inputType: 'text',
      validators: [CustomValidators.charset],
      maxLength: 255,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new AttachmentControlConfiguration({
      formControlName: FormControlNameEnum.offerOrderAttachment,
      label: TenantTextPipe.transform('attachment') as string,
      placeholder: TenantTextPipe.transform('upload') as string,
      allowedFileTypes: FileExtensionHelper.RESTRICTED,
      validators: [
        Validators.required,
        CustomValidators.charsetAttachment,
        CustomValidators.fileSize(15 * 1024 * 1024), // 15 MB
        CustomValidators.fileExtension(FileExtensionHelper.RESTRICTED)],
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new TextareaControlConfiguration({
      formControlName: FormControlNameEnum.comment,
      placeholder: TenantTextPipe.transform('comment') as string,
      inputType: 'text',
      validators: [CustomValidators.charset],
      displayWidth: 100
    }),
  ];

}

