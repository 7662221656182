import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ResellerCachingService } from './core/services/reseller-caching-service/reseller-caching.service';
import { ScrollEventHelper } from './shared/helper/scroll-event-helper';

@Component({
  selector: 'sebu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private resellerCachingService: ResellerCachingService, private scrollEvevtHelper: ScrollEventHelper) {
  }
  windowScrolled: boolean;
  @ViewChild('scrollcontainer') scrollContainer : ElementRef<HTMLDivElement>;

  public ngOnInit(): void {
    // load initial app data
    this.resellerCachingService.getReseller();
  }

  public scrollOnTop(): void {
    window.scrollTo(0, 0);
  }

  public onScroll(): void {
    this.windowScrolled = this.scrollContainer.nativeElement.scrollTop > 250;
    this.scrollEvevtHelper.emitScrollEvent({position: this.scrollContainer.nativeElement.scrollTop});
  }

    onResize() {
        this.scrollEvevtHelper.emitResizeEvent({width: window.innerWidth});
    }
}

