import { AvailableAddon } from '../../models/available-addon.model';
import { AvailableProduct } from '../../models/available-product.model';
import { WizardFormModel } from '../../models/wizard-form-models/wizard-form.model';
import { ProductConfiguration } from '../../models/product-configuration/product-configuration';
import { UiStateModel } from '../../models/ui-state.model';

export class WizardState {
  public formModel?: WizardFormModel;
  public previewUrl?: string;
  public orderFormConfiguration?: ProductConfiguration;
  public availableProducts?: AvailableProduct[];
  public topJob?: AvailableAddon;
  public uiState: UiStateModel;

  constructor() {
    this.formModel = new WizardFormModel();
    this.orderFormConfiguration = undefined;
    this.availableProducts = undefined;
    this.topJob = undefined;
    this.previewUrl = undefined;
    this.uiState = {
      previewLoadingSpinner: {
        isLoading: false
      }
    };
  }
}
