<ng-container *ngIf="control.show">
  <div [formGroup]="form" [ngClass]="formControlClass || ''" fxLayout="column" [ngSwitch]="control.controlType">
    <mat-form-field *ngSwitchCase="CONTROL_TYPE.INPUT" class="full-width-input">
      <input matInput [placeholder]="control.placeholder + isRequired()" formControlName="{{control.formControlName}}"
             type="{{control.inputType}}" [maxLength]="control.maxLength" name="{{control.formControlName}}">
      <mat-error [sebuCustomErrors]="form.get(control.formControlName.toString()).errors"></mat-error>
    </mat-form-field>

    <h4 class="headline" *ngSwitchCase="CONTROL_TYPE.HEADER">{{control.header}}</h4>

    <mat-form-field *ngSwitchCase="CONTROL_TYPE.TEXTAREA" class="full-width-input textarea">
      <textarea matInput cdkTextareaAutosize [placeholder]="control.placeholder" [maxLength]="control.maxLength"
                formControlName="{{control.formControlName}}" name="{{control.formControlName}}"></textarea>
      <mat-error [sebuCustomErrors]="form.get(control.formControlName.toString()).errors"></mat-error>
    </mat-form-field>

    <ng-container *ngSwitchCase="CONTROL_TYPE.ATTACHMENT">
      <div class="attachment-input-wrapper">
        <div *ngIf="control.placeholder">
          <h3>{{ control.placeholder }} {{isRequired()}}</h3>
        </div>
        <p *ngIf="!control.iconOnly"
           class="file-types-text">{{'allowedFileTypes' | TenantTextPipe }} {{getAllowedFileTypesToDisplay()}}</p>
        <div fxLayout="row">
          <sebu-file-upload fxFlex="0 0 calc(100%)" formControlName="{{control.formControlName}}"
                            [controlConfiguration]="control"
                            [icon]="form.get(control.formControlName.toString()).value ? 'cached' : 'file_upload'"
                            [delete]="'delete'"
                            [label]="control.iconOnly ? '' : (form.get(control.formControlName.toString()).value
                                        ? form.get(control.formControlName.toString()).value.name
                                        : TenantTextPipeRef.transform('upload'))"
                            ngDefaultControl>
          </sebu-file-upload>
        </div>
        <div [sebuCustomErrors]="form.get(control.formControlName.toString()).errors | replaceErrorsForUpload" class="custom-error"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="CONTROL_TYPE.LOGO">
      <h3>{{ control.placeholder }} {{isRequired()}}</h3>
      <p class="file-types-text">{{'allowedFileTypes' | TenantTextPipe }} {{getAllowedFileTypesToDisplay()}}</p>
      <sebu-image-upload formControlName="{{control.formControlName}}"
                         [options]="control.imageUploadOptions"></sebu-image-upload>
      <div class="custom-error" *ngIf="hasError(control.formControlName.toString(), 'exceededFileSize')" sebuTenantText>
        exceededFileSize
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="CONTROL_TYPE.COLOR_PICKER">
      <h3>{{ 'htmlTemplateColorPickerHeading' | TenantTextPipe }} {{isRequired()}}</h3>
      <sebu-color-picker formControlName="{{control.formControlName}}"></sebu-color-picker>
    </ng-container>

    <sebu-location-input *ngSwitchCase="CONTROL_TYPE.LOCATION"
                         formControlName="{{control.formControlName}}"></sebu-location-input>

    <sebu-category-input *ngSwitchCase="CONTROL_TYPE.CATEGORIES"
                         formControlName="{{control.formControlName}}"></sebu-category-input>


    <ng-container *ngSwitchCase="CONTROL_TYPE.FORM_GROUP">
      <sebu-form-control-factory *ngFor="let subcontrol of control.controls"
                                 [formControlClass]="'no-padding-and-margin'"
                                 [control]="subcontrol"
                                 [form]="form.get(control.formControlName.toString())"></sebu-form-control-factory>
    </ng-container>

    <ng-container *ngSwitchCase="CONTROL_TYPE.INLINE_EDITOR">
      <sebu-tiny-editor class="inline-edit-headline" *ngIf="control.inputType == 'text'" [inputType]="control.inputType"
                        formControlName="{{control.formControlName}}" elementId="{{control.formControlName}}"
                        [showToolbar]="false"
                        [initialValue]="control.initialValue"></sebu-tiny-editor>
      <sebu-tiny-editor class="inline-edit" *ngIf="control.inputType != 'text'" [inputType]="control.inputType"
                        formControlName="{{control.formControlName}}" elementId="{{control.formControlName}}"
                        [initialValue]="control.initialValue"></sebu-tiny-editor>
      <div [sebuCustomErrors]="form.get(control.formControlName.toString()).errors" class="custom-error"></div>
    </ng-container>
  </div>
</ng-container>
