<form [formGroup]="couponForm">
    <div  class="coupon-wrapper" >
    <mat-form-field appearance="outline">
      <mat-label>{{'summaryCoupon' | TenantTextPipe}} </mat-label>
      <input matInput formControlName="couponName" name="coupon">
      <mat-error [sebuCustomErrors]="couponForm.get('couponName').errors"></mat-error>
    </mat-form-field>
    <button mat-raised-button
              color="secondary"
              sebuTenantText
              [disabled]="couponApplied"
              (click)="applyCoupon()">summaryCouponApply</button>
    </div>
</form>
