import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { OfferOrderActionEnum, OfferOrderCommentV3 } from '../../api/client';
import { ProductEnum } from '../../api/client';
import { ConfirmDialogService } from '../../core/services/confirm-dialog/confirm-dialog.service';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';
import { FetchPreview } from '../../wizard/ngrx/actions';
import { WizardState } from '../../wizard/ngrx/reducer/wizard-state.model';
import { DeactivatePayload } from '../models/deactivate-payload';
import { StatusPageState } from '../models/status-page';
import {
  ActivateOfferOrder, DeactivateOfferOrder, DeleteOfferOrder, LoadOfferOrderDataAndActions
} from '../ngrx/actions';
import { getFormModelsSelector, getStatusPageAllowedActionsSelector } from '../ngrx/selectors';
import { DeactivateComponent } from './deactivate/deactivate.component';
import { UiStateModel } from '../../wizard/models/ui-state.model';
import { getPreviewUrlSelector, getUiStateSelector } from '../../wizard/ngrx/reducer/selectors';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TemplatedProducts } from '../../wizard/models/product-configuration/product-registry';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sebu-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss']
})
export class StatusPageComponent implements OnDestroy, OnInit {
  public formModels$: Observable<WizardFormModel>;
  public allowedActions$: Observable<OfferOrderActionEnum[]>;
  public deactivateOfferOrderDialogRef: MatDialogRef<DeactivateComponent>;
  public uiStateWizard$: Observable<UiStateModel>;
  public token: string;
  public ORDER_ACTIONS: typeof OfferOrderActionEnum = OfferOrderActionEnum;
  public previewUrl$: Observable<string>;
  public templatedProducts: ProductEnum[] = TemplatedProducts;

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private store: Store<StatusPageState>,
              private wizardStoreSlice: Store<WizardState>,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private confirmDialog: ConfirmDialogService,
              private viewContainerRef: ViewContainerRef) {
  }

  public ngOnInit(): void {
    // load requested offerOrder
    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((params: Params) => params['token']),
        tap(token => {
          this.token = token;
          this.store.dispatch(new LoadOfferOrderDataAndActions(token));
          this.formModels$ = this.store.select(getFormModelsSelector);
          this.allowedActions$ = this.store.select(getStatusPageAllowedActionsSelector);
        }))
      .subscribe();

    // fetch rendered offerOrder part for products with templates
    this.store.select(getFormModelsSelector).pipe(takeUntil(this.componentDestroyed$)).subscribe((model: WizardFormModel) => {
      if (model && (TemplatedProducts.includes(model.product.productEnum))) {
        this.wizardStoreSlice.dispatch(new FetchPreview(model));
      }
    });

    this.previewUrl$ = this.wizardStoreSlice.select(getPreviewUrlSelector);
    this.uiStateWizard$ = this.wizardStoreSlice.select(getUiStateSelector);
  }

  public showDeleteDialog(): void {
    this.confirmDialog.confirm(TenantTextPipe.transform('dialogDeleteOfferOrder') as string,
      TenantTextPipe.transform('dialogDeleteOfferOrderDescription') as string,
      TenantTextPipe.transform('dialogAbort') as string,
      TenantTextPipe.transform('dialogConfirmDelete') as string,
      'accent',
      'warn')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (res) {
          this.deleteOfferOrder();
        }
      });
  }

  public showDeactivateDialog(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    this.deactivateOfferOrderDialogRef = this.dialog.open(DeactivateComponent, config);
    this.deactivateOfferOrderDialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(result => {
        if (result) {
          this.deactivateOfferOrder(result);
        }
      });
  }

  public activateOfferOrder(): void {
    this.store.dispatch(new ActivateOfferOrder(this.token));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  private deactivateOfferOrder(comment: OfferOrderCommentV3): void {
    const payload: DeactivatePayload = { token: this.token, comment };
    this.store.dispatch(new DeactivateOfferOrder(payload));
  }

  private deleteOfferOrder(): void {
    this.store.dispatch(new DeleteOfferOrder(this.token));
  }
}
