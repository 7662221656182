import { NgModule } from '@angular/core';
import { SebuFormComponentsModule } from '../../../form-components/sebu-form-components.module';
import { DialogModule } from '../../../shared/dialog.module';
import { SharedModule } from '../../../shared/shared.module';
import { SvgComponent } from '../../../shared/svg/svg.component';
import { UiComponentsModule } from '../../../ui-components/ui-components.module';
import { ProductDescriptionDisplayComponent } from '../product-description-display/product-description-display.component';
import { ProductSelectionDropdownComponent } from './product-selection-dropdown/product-selection-dropdown.component';
import { environment } from '../../../../environments/environment';
import { ProductSelectionRadioButtonsComponent } from './product-selection-radio-buttons/product-selection-radio-buttons.component';
import { ProductCardDescriptionComponent } from './product-selection-dropdown/product-card-description/product-card-description.component';

@NgModule({
  imports: [
    SharedModule,
    SebuFormComponentsModule,
    DialogModule,
    UiComponentsModule
  ],
  declarations: [
    ProductSelectionRadioButtonsComponent,
    ProductSelectionDropdownComponent,
    ProductDescriptionDisplayComponent,
    SvgComponent,
    ProductCardDescriptionComponent,
  ],
  exports: [
    environment.features.radioProductSelection ? ProductSelectionRadioButtonsComponent : ProductSelectionDropdownComponent,
    SvgComponent,
    ProductDescriptionDisplayComponent
  ]
})
export class ProductSelectionModule {
}
