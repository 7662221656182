<form [formGroup]="companyDetailsFormGroup" class="container company-details-container" fxLayout="column">
  <h3 sebuTenantText>resellerOrCompanyHeader</h3>
  <div fxLayout="row wrap">
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="255"
             placeholder="{{'companyName' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="companyName">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('companyName').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="50"
             placeholder="{{'homepage' | TenantTextPipe}}"
             formControlName="homepage">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('homepage').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="50"
             type="tel"
             placeholder="{{'telephoneNumber' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="telephoneNumber">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('telephoneNumber').errors"></mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap">
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="255"
             placeholder="{{'street' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="street">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('street').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="10"
             placeholder="{{'postcode' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="postcode">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('postcode').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="255"
             placeholder="{{'city' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="city">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('city').errors"></mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row wrap">
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="50"
             placeholder="{{'postOffice' | TenantTextPipe}}"
             formControlName="postOffice">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('postOffice').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="100"
             placeholder="{{'country' | TenantTextPipe}}"
             formControlName="country">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('country').errors"></mat-error>
    </mat-form-field>
  </div>

  <h3 sebuTenantText>contactPersonHeader</h3>
  <div fxLayout="row wrap">

    <mat-form-field fxFlex="">
      <mat-select formControlName="salutation" placeholder="{{'salutation' | TenantTextPipe}}">
        <mat-option *ngFor="let salutation of salutations" [value]="salutation.value">
          {{salutation.displayValue}}
        </mat-option>
      </mat-select>
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('salutation').errors"></mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="10"
             placeholder="{{'personalTitle' | TenantTextPipe}}"
             formControlName="title">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('title').errors"></mat-error>
    </mat-form-field>
  </div>
    <div fxLayout="row wrap">

    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="100"
             placeholder="{{'firstName' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="firstName">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('firstName').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             [maxLength]="100"
             placeholder="{{'lastName' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="lastName">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('lastName').errors"></mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="">
      <input matInput
             type="email"
             [maxLength]="100"
             placeholder="{{ 'emailContactPerson' | TenantTextPipe}} {{ 'isRequired' | TenantTextPipe }}"
             formControlName="email">
      <mat-error [sebuCustomErrors]="companyDetailsFormGroup.get('email').errors"></mat-error>
    </mat-form-field>
  </div>
</form>

