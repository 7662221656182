export class DateHelper {

  public static secondsPerDay: number = 24 * 60 * 60 * 1000;

  public static todayZeroHours(): Date {
    return DateHelper.getZeroHoursDate(new Date());
  }

  public static getZeroHoursDate(date: Date | string): Date {
    return date && new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  /*For non ISO durations only*/
  public static calculateEndDateFromDuration(startDate: Date = new Date(), duration = 'P0D'): Date {
    let durationValue: string = duration;
    durationValue = durationValue.replace(/\s+/g, '');
    if (durationValue.match(/^([P])[0-9]+([D])$/g)) {
      const durationDays = Number(durationValue.split(/(\d+)/)[1]);
      return new Date(DateHelper.addDays(startDate || new Date(), durationDays).setHours(23, 59, 59));
    } else if (durationValue.match(/^([P])[0-9]+([M])$/g)) {
      // for now we support months durations
      const durationMonths = Number(durationValue.split(/(\d+)/)[1]);
      return new Date(DateHelper.addDays(startDate || new Date(), durationMonths * 30).setHours(23, 59, 59));
    }
    throw new Error('Given value ' + duration + ' is not a valid duration.');
  }

  public static addDays(startDate: Date, numberOfDays: number): Date {
    return new Date(new Date(startDate).getTime() + (numberOfDays * DateHelper.secondsPerDay));
  }

  public static getRefreshMinDate(startDate: Date): Date {
    return new Date(startDate) < DateHelper.todayZeroHours() ? DateHelper.todayZeroHours() : DateHelper.addDays(startDate, 1);
  }
}
