<sebu-header-with-button [showEditButton]="false">{{"summaryPrivacyStatementTitle"| TenantTextPipe}}
</sebu-header-with-button>

<div class="privacy-statement-wrapper" fxLayout="row" fxLayoutGap="3em">
  <form [formGroup]="model" *ngIf="model">
    <p>
      <mat-checkbox *ngIf="showCheckbox" formControlName="agreePrivacyStatement" [attr.disabled]="disabled"><span [innerHTML]="privacyStatementHtml"></span></mat-checkbox>
      <span *ngIf="!showCheckbox" [innerHTML]="privacyStatementHtml"></span>
    </p>
  </form>
</div>
