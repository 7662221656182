import { PayloadAction } from '../../core/models/payload-action.model';
import { ConfirmationPageData } from '../models/confirmation-page-data.model';
import { SebuRecordLocation } from '../../api/client';

export const LOAD_OFFER_ORDER_DATA = '[Confirmation Page] load offer order for confirmation page';
export const LOAD_OFFER_ORDER_DATA_SUCCESS = '[Confirmation Page] load offer order success';

export class LoadOfferOrderData implements PayloadAction {
  readonly type: string = LOAD_OFFER_ORDER_DATA;
  constructor(public payload: SebuRecordLocation) {}
}

export class LoadOfferOrderDataSuccess implements PayloadAction {
  readonly type: string = LOAD_OFFER_ORDER_DATA_SUCCESS;
  constructor(public payload: ConfirmationPageData) {}
}

export type Actions =
  LoadOfferOrderData
  | LoadOfferOrderDataSuccess;
