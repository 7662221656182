import {
  Directive, ElementRef, Input, OnChanges, Renderer2
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';

@Directive({
  selector: '[sebuCustomErrors]',
})
export class CustomErrorsDirective implements OnChanges {

  @Input()
  sebuCustomErrors: ValidationErrors;

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnChanges(): void {
    const value: string = this.sebuCustomErrors ? this.getErrorString(this.sebuCustomErrors) : '';
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
  }

  private getKey(errors: ValidationErrors): string {
    return Object.keys(errors)[0];
  }

  private getErrorString(errors: ValidationErrors): string {
    const errorString: string = TenantTextPipe.transform(this.getKey(errors)) as string;
    return this.getKey(errors) === 'invalidChars' ?
      errorString + ' ' + this.sebuCustomErrors.invalidChars.char :
      errorString;
  }
}
