<input
        name="fileUpload"
        #fileUpload
        type="file"
        [hidden]='true'
        (change)="uploadFile($event)"
        accept="{{getAllowedFileTypes()}}"/>

<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
    <button mat-icon-button (click)="openZipAttachmentDialog()">
      <mat-icon color="primary">
        {{icon}}
      </mat-icon>
    </button>
    <button *ngIf="controlConfiguration.deletable && file"
            mat-icon-button
            (click)="deleteAttachment()">
      <mat-icon color="warn">
        {{delete}}
      </mat-icon>
    </button>
    <h4 class="filename" (click)="openZipAttachmentDialog()">
      {{label}}
    </h4>
  </div>
  <div *ngIf="warningMessage" class="validationMessage" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
    <mat-icon [color]="'warn'">warning</mat-icon>
    {{warningMessage}}
  </div>
</div>

