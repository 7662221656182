import { TenantSettings } from './tenant-settings.model';
import { ProductEnum } from '../api/client/model/productEnum';

export const DEFAULT_TENANT_SETTINGS: TenantSettings = {
  showSupportDialogOnApplicationStart: false,
  showAttachmentForStandardlayout: true,
  showHomeButtonOnThankYouPage: false,
  showAsteriscPriceHint: true,
  hasSebuProductIcon: true,
  showSummaryPrivacyStatementCheckbox: false,
  showSebuHeader: true,
  showMoreInformationAnker: false,

  customCheckIconName: '',
  buttonProductInfoCustomExternalLink: '../unternehmen',

  tooltips: {
    categories: false,
    tableEntry: true,
    socialMedia: true,
    topJob: true,
    refreshOption: true
  },

  productSortOrder: [
    ProductEnum.OnlineOnlyPdfUndSonstigesFormat,
    ProductEnum.OnlineOnlyAzubi,
    ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt,
    ProductEnum.PortalOnlineOnlyAzubi,
    ProductEnum.RegioOnlineOnlyStandardlayout,
    ProductEnum.RegioOnlineOnlyAzubi,
    ProductEnum.RegioOnlineOnlyMinijobs
  ],

  preselectedProduct: ProductEnum.RegioOnlineOnlyStandardlayout
};
