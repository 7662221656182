<footer class="header-footer-full-width">
  <span class="version">{{version$ | async}}</span>
  <div class="content footer-content">
    <div fxLayoutGap="1em" class="footer-content">
      <a href="{{'footerPrivacyProtectionLink' | TenantTextPipe }}" target="_blank" sebuTenantText>footerPrivacyProtection</a>
      <span *ngIf="showPipeOnFooter">|</span>
      <a href="{{'footerAgbLink' | TenantTextPipe }}" target="_blank" sebuTenantText>footerPrivacyAgb</a>
      <span *ngIf="showPipeOnFooter">|</span>
      <a href="{{'footerImpressumLink' | TenantTextPipe }}" target="_blank" sebuTenantText>footerImpressum</a>
      <span *ngIf="showPipeOnFooter && footerFreeToChoose2">|</span>
      <a *ngIf="footerFreeToChoose2" href="{{'footerFreeToChoose2Link' | TenantTextPipe }}" target="_blank">{{footerFreeToChoose2}}</a>
      <span *ngIf="showPipeOnFooter && footerFreeToChooseButton">|</span>
      <span *ngIf="footerFreeToChooseButton" [innerHTML]="footerFreeToChooseButton | safeHtml"></span>
      <span *ngIf="showPipeOnFooter && footerFreeToChoose">|</span>
      <span *ngIf="footerFreeToChoose" [innerHTML]="footerFreeToChoose | safeHtml"></span>
    </div>
  </div>
</footer>
