import { ConfirmDialogComponent } from './confirm-dialog.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

/* Opens a confirm dialog which returns true or false, depending on the clicked button.
 * Inject service and call confirm method with the required parameters.
 * Default: ButtonConfirm "Ja"    ButtonConfirmColor "accent"
 *          ButtonCancel  "Nein"  ButtonCancelColor "warn" */

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {


  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string,  buttonCancel?: string, buttonConfirm?: string,
                 buttonCancelColor?: string, buttonConfirmColor?: string): Observable<boolean> {

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.buttonConfirm = buttonConfirm;
    dialogRef.componentInstance.buttonCancel = buttonCancel;
    dialogRef.componentInstance.buttonConfirmColor = buttonConfirmColor;
    dialogRef.componentInstance.buttonCancelColor = buttonCancelColor;

    return dialogRef.afterClosed();
  }
}
