import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ConfirmationPageState } from '../models/confirmation-page.model';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';
import { getWizzardFormModelsEntities } from './reducer';

export const getConfirmationPageState: MemoizedSelector<Object, ConfirmationPageState> =
  createFeatureSelector<ConfirmationPageState>('confirmationPage');

export const getWizardFormModel: MemoizedSelector<ConfirmationPageState, WizardFormModel>
  = createSelector(getConfirmationPageState, getWizzardFormModelsEntities);


