import { OfferOrderRefreshOptionEnum } from '../../api/client';
import { DateHelper } from './date.helper';

export class RefreshDatesHelper {

  public static calculatePeriodicRefreshDates(refreshOption: OfferOrderRefreshOptionEnum | null, startDate: Date, duration: string, endDate?: Date): Date[] {
    startDate = startDate < new Date() ? new Date() : startDate;
    endDate = endDate ? endDate : DateHelper.calculateEndDateFromDuration(startDate, duration);
    switch (refreshOption) {
      case OfferOrderRefreshOptionEnum.Nach15Tagen:
        // offer will be refreshed only one time at 15th day
        return this.calculatePeriodicDates(startDate, endDate, 0, 15);
      case OfferOrderRefreshOptionEnum.Alle30Tage:
        // offer will be refreshed first at the 15th day, then every 30th day
        return this.calculatePeriodicDates(startDate, endDate, 30, 15);
      case OfferOrderRefreshOptionEnum.Alle7Tage:
        return this.calculatePeriodicDates(startDate, endDate, 7);
      case OfferOrderRefreshOptionEnum.Alle3Tage:
        return this.calculatePeriodicDates(startDate, endDate, 3);
      default:
        return [];
    }
  }

  private static calculatePeriodicDates(startDate: Date, endDate: Date, period: number, offset?: number): Date[] {
    const refreshDates: Date[] = [];
    let currentRefreshDate: Date = startDate;
    const lastRefreshDate: Date = this.getLastRefreshDate(endDate);

    if (offset > 0) {
      currentRefreshDate = new Date(DateHelper.addDays(startDate, offset));
      currentRefreshDate = DateHelper.getZeroHoursDate(currentRefreshDate);
      if (currentRefreshDate <= lastRefreshDate) {
        refreshDates.push(currentRefreshDate);
      }
    }

    while (period > 0) {
      currentRefreshDate = new Date(DateHelper.addDays(currentRefreshDate, period));
      currentRefreshDate = DateHelper.getZeroHoursDate(currentRefreshDate);
      if (currentRefreshDate > lastRefreshDate) {
        break;
      }
      refreshDates.push(currentRefreshDate);
    }

    return refreshDates.map(d => DateHelper.getZeroHoursDate(d));
  }

  public static getLastRefreshDate(offerEndDate: Date = null): Date {
    return offerEndDate && DateHelper.addDays(DateHelper.getZeroHoursDate(offerEndDate), -1);
  }
}
