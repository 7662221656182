/* tslint:disable:no-any */
export enum FileExtension {
  ZIP = <any> 'ZIP',
  JPG = <any> 'JPG',
  JPEG = <any> 'JPEG',
  GIF = <any> 'GIF',
  TIF = <any> 'TIF',
  PNG = <any> 'PNG',
  BMP = <any> 'BMP',
  PDF = <any> 'PDF',
  SVG = <any> 'SVG',
  PSD = <any> 'PSD',
  CSV = <any> 'CSV',
  RAR = <any> 'RAR',
  EPS = <any> 'EPS',
  PS = <any> 'PS',
  TAR = <any> 'TAR',
  DOC = <any> 'DOC',
  DOT = <any> 'DOT',
  XLS = <any> 'XLS',
  DOCX = <any> 'DOCX',
  TXT = <any> 'TXT',
  XLSX = <any> 'XLSX',
  ODS = <any> 'ODS',
  ODT = <any> 'ODT',
}
