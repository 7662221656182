import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { SebuDirectivesAndPipesModule } from './sebu-directives-and-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    SebuDirectivesAndPipesModule,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    SebuDirectivesAndPipesModule,
  ]
})
export class SharedModule { }
