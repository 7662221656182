<sebu-header-with-button
  [showEditButton]="isStepEditable"
  (buttonClick)="clickEdit()">{{"summaryCompanyDetailsTitle" | TenantTextPipe}}</sebu-header-with-button>

<div class="company-summary-wrapper" fxLayout="row" fxLayoutGap="2em">
  <div fxLayout="column" fxFlex="48" fxLayoutGap="0.5em">
    <h4 sebuTenantText>resellerOrCompanyHeader</h4>
    <span class="highlight">{{companyData?.companyName}}</span>
    <div fxLayout="column">
      <span>{{companyData?.street}}</span>
      <span *ngIf="companyData?.postOffice">
        <span sebuTenantText>postOffice</span>&nbsp;<span>{{companyData?.postOffice}}</span>
      </span>
      <span>
        <span>{{companyData?.postcode}}</span>&nbsp;<span>{{companyData?.city}}</span>
      </span>
      <span>{{companyData?.country}}</span>
    </div>
    <div fxLayout="column">
      <span><mat-icon>phone</mat-icon>&nbsp;{{companyData?.telephoneNumber}}</span>
      <span *ngIf="companyData?.homepage"><mat-icon>language</mat-icon>&nbsp;{{companyData?.homepage}}</span>
    </div>
  </div>
  <div fxLayout="column" fxFlex="48" fxLayoutGap="0.5em">
    <h4 sebuTenantText>contactPersonHeader</h4>
    <div fxLayout="column">
      <span *ngIf="companyData?.salutation">{{companyData?.salutation}}</span>
      <span class="highlight">
        <span *ngIf="companyData?.title">{{companyData?.title}}&nbsp;</span><span>{{companyData?.firstName}}</span>&nbsp;<span>{{companyData?.lastName}}</span>
      </span>
    </div>
    <span><mat-icon>email</mat-icon>&nbsp;{{companyData?.email}}</span>
  </div>
</div>
