<div class="product-container" *ngIf="availableProducts">
  <div class="single-product-wrapper"
       [id]="'e2e-'+product.productName"
       *ngFor="let product of availableProducts;let i = index" [attr.data-index]="i"
       [class.product-is-selected]="selectedProduct?.productEnum === product.productEnum"
       [class.product-not-selected]="selectedProduct?.productEnum !== product.productEnum"
       (click)="selectCard(product.productEnum)">
    <div *ngIf="hasSebuProductIcon" class="icon">
      <sebu-svg [svgSrc]="getSVG(product.icon)"></sebu-svg>
    </div>
    <div class="product-label" fxLayoutAlign="center center">
      <h3>{{product.productName}}</h3>
    </div>
    <div fxLayout="column" class="content-wrapper" *ngIf="product.availableDurations.length > 1; else singleAvailableDuration">
      <mat-form-field>
        <mat-select matInput [formControl]="productsAndDurations.controls[i]"
                    placeholder="{{'duration' | TenantTextPipe}}"
                    [value]="productsAndDurations.controls[i].value" [compareWith]="compare">
          <mat-option *ngFor="let duration of product.availableDurations" [value]="duration.value">
            {{duration.value.duration | DurationIsoPipe}} - {{duration.price | currency:'EUR'}} {{showAsteriscPriceHint ? '*' : ''}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-template #singleAvailableDuration>
      <p class="single-duration-headline-wrapper">Laufzeit</p>
      <div  class="single-duration-wrapper"  *ngFor="let duration of product.availableDurations">
        {{duration.value.duration | DurationIsoPipe}} - {{duration.price | currency:'EUR'}} {{showAsteriscPriceHint ? '*' : ''}}
      </div>
    </ng-template>
    <sebu-product-card-description [product]="product.productEnum"></sebu-product-card-description>
    <div *ngIf="showMoreInformationAnker" class="more-information-anker-link-wrapper">
      <mat-icon class="more-information-icon" aria-label="arrow right icon">arrow_right</mat-icon>
      <a class="more-information-anker-link" href="#description-product-name">{{ankerLinkText}}</a>
    </div>
  </div>
</div>

