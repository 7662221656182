import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { ControlType } from './control-type.enum';
import { FormControlNameEnum } from './form-control-name.enum';

export class ControlConfiguration {
  public readonly controlType: ControlType;
  public readonly formControlName: FormControlNameEnum;
  public readonly label?: string;
  public readonly inputType?: string;
  public readonly placeholder: string;
  public readonly validators?: ValidatorFn[];
  public readonly asyncValidators?: AsyncValidatorFn[];
  public readonly maxLength?: number;
  public readonly displayWidth?: number;
  public readonly displayMinWidth?: string;
  public readonly show?: boolean;

  constructor(controlType: ControlType, options: ControlConfigurationArgs) {
    this.controlType = controlType;
    this.formControlName = options.formControlName || undefined;
    this.label = options.label || '';
    this.inputType = options.inputType || '';
    this.placeholder = options.placeholder || '';
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidators || [];
    this.maxLength = options.maxLength || 5000;
    this.displayWidth = options.displayWidth || 31;
    this.displayMinWidth = options.displayMinWidth || undefined;
    this.show = options.show === undefined ? true : options.show;
    if (!this.show) {
      this.displayWidth = 0;
      this.displayMinWidth = undefined;
    }
  }
}

export interface ControlConfigurationArgs {
  formControlName: FormControlNameEnum;
  label?: string;
  inputType?: string;
  placeholder?: string;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  maxLength?: number;
  displayWidth?: number;
  displayMinWidth?: string;
  show?: boolean;
}
