import { ProductFormModel } from './wizard-form-models/product-form.model';
import { ProductEnum } from '../../api/client/model/productEnum';

export class AvailableProductDuration {
  price: number;
  value: ProductFormModel;

  constructor(duration: string, price: number, product: ProductEnum, name: string) {
    this.value = new ProductFormModel(product, duration, name);
    this.price = price ? price : 0;
  }
}
