import { InjectionToken } from '@angular/core';
import { IEnvironment } from 'environment.type';
import { environment } from '../environments/environment';

export const APP_CONFIG: InjectionToken<IAppConfig> = new InjectionToken<IAppConfig>('app.config');

export interface IAppConfig {
    environment: IEnvironment;
}
// Global App Config
export const AppConfig: IAppConfig = {
  environment: environment
};
