import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ControlConfiguration } from './models/control-configuration';
import { FormGroupConfiguration } from './models/form-group-configuration';
import { ControlType } from './models/control-type.enum';
import * as _ from 'lodash';

export class FormBuilderHelper {

  public static group(controlConfiguration: ControlConfiguration[]): UntypedFormGroup {

    const controls: { [key: string]: AbstractControl } = {};
    controlConfiguration.forEach( control => {
      if (control.controlType === ControlType.FORM_GROUP) {
        controls[control.formControlName] = FormBuilderHelper.group((control as FormGroupConfiguration).controls);
      } else {
        controls[control.formControlName] = FormBuilderHelper.control(control);
      }
    });
    return new UntypedFormGroup(controls);
  }

  public static control(control: ControlConfiguration): UntypedFormControl {
    return new UntypedFormControl(
      '',
      _.cloneDeep(control.validators),
      _.cloneDeep(control.asyncValidators)
    );
  }
}
