import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { OfferService, ProductEnum, SebuRecordLocation, SebuResellerData } from '../../api/client';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';
import { FetchPreview } from '../../wizard/ngrx/actions';
import { WizardState } from '../../wizard/ngrx/reducer/wizard-state.model';
import { ConfirmationPageState } from '../models/confirmation-page.model';
import { LoadOfferOrderData } from '../ngrx/actions';
import { getConfirmationPageState, getWizardFormModel } from '../ngrx/selectors';
import { UiStateModel } from '../../wizard/models/ui-state.model';
import { getPreviewUrlSelector, getUiStateSelector } from '../../wizard/ngrx/reducer/selectors';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { TemplatedProducts } from '../../wizard/models/product-configuration/product-registry';
import { ResellerCachingService } from '../../core/services/reseller-caching-service/reseller-caching.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CheckoutData } from './checkout/checkout.model';
import { UserNotifyService } from '../../core/services/user-notify/user-notify.service';
import { ConfirmButtonTextEnum } from '../models/confirm-button-text.enum';
import {
  PaymentIntent,
  PaymentMethodCreateParams,
  StripeCardNumberElement,
  StripeError,
  StripeIbanElement
} from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { CompanyFormModel } from '../../wizard/models/wizard-form-models/company-form.model';
import { ScrollEventHelper } from '../../shared/helper/scroll-event-helper';

@Component({
  selector: 'sebu-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})

export class ConfirmationPageComponent implements OnInit, OnDestroy, AfterViewInit {

  public constructor(private store: Store<ConfirmationPageState>,
                     private wizardStoreSlice: Store<WizardState>,
                     private formBuilder: UntypedFormBuilder,
                     private route: ActivatedRoute,
                     private router: Router,
                     private userNotifyService: UserNotifyService,
                     private offerService: OfferService,
                     private resellerCachingService: ResellerCachingService,
                     private scrollEventHelper: ScrollEventHelper) {
  }

  public paymentForm: UntypedFormGroup;
  public isLoading = false;

  public confirmButtonText: ConfirmButtonTextEnum = ConfirmButtonTextEnum.ConfirmOrder;
  public confirmButtonDisabled = true;
  public ConfirmButtonTextEnum: typeof ConfirmButtonTextEnum = ConfirmButtonTextEnum;
  public orderAlreadySent = false;

  public state$: Observable<ConfirmationPageState>;
  public sebuResellerData$: Observable<SebuResellerData>;

  public uiStateWizard$: Observable<UiStateModel>;
  public previewUrl$: Observable<string>;
  public templatedProducts: ProductEnum[] = TemplatedProducts;

  /*stepper configuration*/
  public linearStepper: boolean = environment.linearStepper;
  public disableAnimationsForStepper = true;

  private componentDestroyed$: Subject<void> = new Subject<void>();
  private recordLocation: SebuRecordLocation;
  private company: CompanyFormModel;
  private checkoutData: CheckoutData = {};

  private scrollEventSubscription: Subscription;
  private resizeEventSubscription: Subscription;

  ngOnInit(): void {
    this.state$ = this.store.select(getConfirmationPageState)
      .pipe(takeUntil(this.componentDestroyed$));

    this.state$.subscribe(state => {
      this.company = state && state.formModels && state.formModels.company;
    });

    this.route.params.subscribe((params: Params) => {
      this.recordLocation = {date: params['date'], token: params['token']};
      this.store.dispatch(new LoadOfferOrderData(this.recordLocation));
    });

    // fetch rendered offerOrder part for products with template
    this.store.select(getWizardFormModel)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((model: WizardFormModel) => {
        if (model && (TemplatedProducts.includes(model.product.productEnum))) {
          this.wizardStoreSlice.dispatch(new FetchPreview(model));
        }
      });

    this.previewUrl$ = this.store.select(getPreviewUrlSelector);
    this.uiStateWizard$ = this.wizardStoreSlice.select(getUiStateSelector);
    this.sebuResellerData$ = this.resellerCachingService.getReseller();

    this.paymentForm = this.formBuilder.group({});
    this.sebuResellerData$.subscribe(reseller => {
      if (reseller.paymentEnabled) {
        this.paymentForm.addControl('payment', new UntypedFormControl('', Validators.required));
        this.paymentForm.get('payment').valueChanges.subscribe(value => {
          this.checkoutData = value;
        });
      }
    });

    this.scrollEventSubscription = this.scrollEventHelper
      .getScrollEvent()
      .subscribe((data) => {
        this.handleScroll(data.position);
      });

      this.resizeEventSubscription = this.scrollEventHelper
          .getResizeEvent()
          .subscribe((data) => {
              this.handleWindowResize();
          });
  }

  ngAfterViewInit (){
    const domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(domStepperPlaceHolder.length === 0){
      this.addStepperWrapper();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.scrollEventSubscription.unsubscribe();
    this.resizeEventSubscription.unsubscribe();
  }

  public sendOrder(): void {
    // Note: in some cases (depending on the validation) the UI takes longer to disable the confirm button and the user
    // can click the button again even if the field confirmButtonDisabled is true. This will cause the call to the
    // service to be executed more than once (more confirmation emails, payments and invoices which is not correct).
    if (this.orderAlreadySent) {
      return;
    }
    this.confirmButtonText = ConfirmButtonTextEnum.IsLoading;
    this.confirmButtonDisabled = true;
    this.orderAlreadySent = true;

    this.offerService.confirmOfferOrder(this.recordLocation.date, this.recordLocation.token, this.checkoutData.method)
      .pipe(
        switchMap((confirmation) => {
          if (!confirmation.paymentSecret) {
            return this.router.navigate(['/created-offer-order']);
          }
          return this.confirmPayment(confirmation.paymentSecret, this.checkoutData)
            .pipe(switchMap(response => {
              if (response.error) {
                // TODO: New page where you can pay after the offer is already in the system
                return this.router.navigate([`/status/${confirmation.sebuToken}`]);
              }
              return this.router.navigate(['/created-offer-order']);
            }));
        }),
        // eslint-disable-next-line
        catchError((value) => {
          this.confirmButtonText = ConfirmButtonTextEnum.ConfirmOrder;
          this.confirmButtonDisabled = false;
          this.orderAlreadySent = false;
          this.userNotifyService.error(TenantTextPipe.transform('error') as string);
          return of();
        }),
      ).subscribe();
  }

  //event is being listened to by another component
  handleScroll(scrollPosition: number){
    const headerHeight = document.querySelectorAll('.header-toolbar')[0].clientHeight;
    const windowScroll = window.scrollY + headerHeight;

    if(windowScroll < scrollPosition){
      this.addStickyClassToStepper();
    }
    if(windowScroll >= scrollPosition){
      this.removeStickyClassFromStepper();
    }
  }

  public togglePrivacyAgreed(value: boolean): void {
    this.confirmButtonDisabled = !value;
  }

  private handleWindowResize(){
      const domStepperWrapper = document.querySelectorAll('.mat-horizontal-stepper-wrapper');
      const width = domStepperWrapper[0].clientWidth;
      const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
      domStepper[0].setAttribute('style', `width:${width}px;`);
  }

  private addStepperWrapper(){

    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'stepperPlaceHolder');

    const domRepresentation = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    if(domRepresentation && domRepresentation[0]){
      domRepresentation[0].insertAdjacentElement('afterend', newDiv)
    }
  }

  private addStickyClassToStepper(): void {
    const sticky = "sticky";

    const domStepperWrapper = document.querySelectorAll('.mat-horizontal-stepper-wrapper');
    const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    this.adjustPlaceHolderForSticky(domStepper);

    const width = domStepperWrapper[0].clientWidth;
    domStepper[0].setAttribute('style', `width:${width}px;`);
    domStepper[0].classList.add(sticky);
  }

  private adjustPlaceHolderForSticky(domStepper: any){
    let domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(!domStepperPlaceHolder || domStepperPlaceHolder.length == 0)
    {
      // add wrapper if it was not added correctly yet
      this.addStepperWrapper();
      domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    }
    if(domStepperPlaceHolder && domStepperPlaceHolder[0]){
      const height = domStepper[0].clientHeight;
      const margin = parseInt(window.getComputedStyle(domStepper[0]).getPropertyValue('margin-bottom'))

      domStepperPlaceHolder[0].setAttribute('style', `height:${height}px; margin-bottom:${margin}px;`);
    }
  }

  private removeStickyClassFromStepper(): void {
    const sticky = "sticky";

    const domStepper = document.querySelectorAll('.mat-horizontal-stepper-header-container');
    this.removStyleFromPlaceHolder();

    domStepper[0].removeAttribute('style');
    domStepper[0].classList.remove(sticky);
  }

  private removStyleFromPlaceHolder(){
    const domStepperPlaceHolder = document.querySelectorAll('#stepperPlaceHolder');
    if(domStepperPlaceHolder.length > 0){
      domStepperPlaceHolder[0].removeAttribute('style');
    }
  }

  private confirmPayment(clientSecret: string, checkoutData: CheckoutData): Observable<{
    paymentIntent?: PaymentIntent;
    error?: StripeError;
  }> {
    switch (checkoutData.method) {
      case 'Card':
        return checkoutData.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: <StripeCardNumberElement>checkoutData.element,
            billing_details: {
              ...this.getBillingDetails(),
              name: checkoutData.cardOwner,
            }
          }
        });
      case 'Sepa':
        return checkoutData.stripe.confirmSepaDebitPayment(clientSecret, {
          payment_method: {
            sepa_debit: <StripeIbanElement>checkoutData.element,
            billing_details: {
              ...this.getBillingDetails(),
              name: checkoutData.accountOwner,
            }
          }
        });
      default:
        return of({});
    }
  }

  private getBillingDetails(): PaymentMethodCreateParams.BillingDetails & { email: string } {
    return {
      email: this.company.email,
      phone: this.company.telephoneNumber,
      address: {
        line1: this.company.street,
        line2: this.company.postOffice ? 'Postfach ' + this.company.postOffice : undefined,
        postal_code: this.company.postcode,
        city: this.company.city,
        // NOTE: "country" must be country code and we do not have it,
        // maybe later when we add drop downs for DE/AT/CH for hr-jobs.
      },
    };
  }
}
