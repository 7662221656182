<div fxLayout="row wrap" fxLayoutGap="1em" class="category-input-wrapper">

  <form [formGroup]="categoryForm" novalidate fxLayout="column" fxLayoutGap="0.5em" class="fifty-percent-width-input">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5em">
      <label sebuTenantText>categories</label>
      <sebu-info-tooltip *ngIf="displayCategoryInfo" fxFlexAlign="end" [content]="'categories' | TenantTextPipe"></sebu-info-tooltip>
    </div>
    <span sebuTenantText>categoriesDescription</span>

    <div fxLayoutAlign="start center" fxLayoutGap="2em">
      <mat-form-field class="full-width-input" fxFlex="">
        <mat-select formControlName="selectedCategory"
                    placeholder="{{'categoryPlaceholder' | TenantTextPipe}} {{'isRequired' | TenantTextPipe}}">
          <mat-option
            *ngFor="let category of categories$ | async"
            [value]="category">
            {{category.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayoutAlign="start center" fxLayoutGap="2em">
      <mat-form-field class="full-width-input">
        <mat-select formControlName="selectedSubCategory"
                    placeholder="{{ getSubcategoryPlaceholder() }}">
          <mat-option *ngFor="let subCategory of subcategoryOptions$ | async"
                      [value]="subCategory">
            {{subCategory.label}}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="!selectedSubCategories.length && categoryForm.controls['selectedCategory'].value"
                  sebuTenantText>noCategoryError</mat-hint>
      </mat-form-field>
    </div>
  </form>

  <sebu-category-display (categoryDelete)="removeSubCategoryAction($event)"
                         [compressedDisplay]="false"
                         [selectedSubCategories]="selectedSubCategories"></sebu-category-display>
</div>
