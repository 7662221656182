import { Observable } from 'rxjs';
import { CategoryData, Subcategory } from '../../api/client';
import { ArrayHelper } from '../../shared/helper/array.helper';
import { StringHelper } from '../../shared/helper/string.helper';
import { map } from 'rxjs/operators';


export class CategoryHelper {

  public static getCategoryById(categories$: Observable<CategoryData[]>, id: number, categoryGroupIdsToFilterOut: number[] = []): Observable<CategoryData> {
    return categories$
      .pipe(
        map((categories: CategoryData[]) => CategoryHelper.findCategoryById(categories, id, categoryGroupIdsToFilterOut))
      );
  }

  public static getCategoriesWithCategoryGroupIds(categories$: Observable<CategoryData[]>, categoryGroupIds: number[]): Observable<CategoryData[]> {
    return categories$
      .pipe(
        map((categories: CategoryData[]) => CategoryHelper.filterCategoriesWithGroupIds(categories, categoryGroupIds))
      );
  }

  public static getCategoryGroupIds(subcategories: Subcategory[]): number[] {
    return subcategories ?
      subcategories.map((sc: Subcategory) => sc.categoryGroupId).filter(ArrayHelper.filterUnique)
      : [];
  }

  public static findCategoriesByName(categories: CategoryData[], query = '', rubIdsToFilterOut: number[] = []): CategoryData[] {
    query = query.toLowerCase();
    return CategoryHelper.filterCategoriesWithoutGroupIds(categories, rubIdsToFilterOut)
      .filter((category: CategoryData) => category.label.toLowerCase().includes(query))
      .sort(CategoryHelper.querySortComparator(query));
  }

  public static filterCategoriesWithGroupIds(categories: CategoryData[], categoryGroupIds: number[]): CategoryData[] {
    return categories
      .map((category) => {
        // New cloned object to avoid modifications by reference
        const modifiedCategory: CategoryData = Object.assign({}, category);
        modifiedCategory.subcategories = category.subcategories.filter(CategoryHelper.includeSubcategoriesWithRubidsFilter(categoryGroupIds));
        return modifiedCategory;
      })
      .filter((category: CategoryData) => category.subcategories.length);
  }


  public static filterCategoriesWithoutGroupIds(categories: CategoryData[], categoryGroupIds: number[]): CategoryData[] {
    return categories
      .map((category) => {
        // New cloned object to avoid modifications by reference
        const modifiedCategory: CategoryData = Object.assign({}, category);
        modifiedCategory.subcategories = category.subcategories.filter(this.excludeSubcategoriesWithRubidsFilter(categoryGroupIds));
        return modifiedCategory;
      })
      .filter((category: CategoryData) => category.subcategories.length);
  }

  public static findSubCategoriesWithGroupIds(categories: CategoryData[], categoryGroupIds: number[]): Subcategory[] {
    if (categories) {
      // eslint-disable-next-line
      const subcategories: Subcategory[] = [].concat.apply([], categories
        .map((category: CategoryData) => category.subcategories));

      // fetch all subcategories with the given rubIds and take the first one (V1 Logic)
      return categoryGroupIds
        .map((cgId: number) => subcategories.find((sc: Subcategory) => sc.categoryGroupId === cgId))
        .filter((sc) => !!sc);
    }
    return [];
  }

  public static findCategoryById(categories: CategoryData[], id: number, categoryGroupIdsToFilterOut: number[] = []): CategoryData {
    const category: CategoryData = categories.find((cat: CategoryData) => cat.id === id);
    if (!category) {
      return null;
    }
    const modifiedCategory: CategoryData = Object.assign({}, category);
    modifiedCategory.subcategories = category.subcategories.filter(this.excludeSubcategoriesWithRubidsFilter(categoryGroupIdsToFilterOut));
    return modifiedCategory;
  }

  public static querySortComparator(query: string): (a: CategoryData, b: CategoryData) => number {
    return (a: CategoryData, b: CategoryData) => StringHelper.querySortComparator(query)(a.label, b.label);
  }

  private static includeSubcategoriesWithRubidsFilter(categoryGroupIds: number[]): (s: Subcategory) => boolean {
    // If the subId is contained in the given array, it's true
    return (sub) => categoryGroupIds.includes(sub.categoryGroupId);
  }

  private static excludeSubcategoriesWithRubidsFilter(categoryGroupIds: number[]): (s: Subcategory) => boolean {
    // If the subId is contained in the given array, it's false
    return (sub) => !categoryGroupIds.includes(sub.categoryGroupId);
  }
}
