<sebu-header-with-button
  [showEditButton]="isStepEditable"
  (buttonClick)="clickEdit()">{{"summaryOfferDetailsTitle"| TenantTextPipe}}
</sebu-header-with-button>

<div fxLayout="row wrap" class="offer-order-details-summary-wrapper">
  <div class="offer-data">
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.title)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryOfferOrderTitle</p>
      <p>{{offerOrderData?.title || '-'}}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.alternativeCompany)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryOfferOrderAlternativeCompany</p>
      <p>{{offerOrderData?.alternativeCompany || '-'}}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.applicationEmail)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryApplicationEmail</p>
      <p>{{offerOrderData?.applicationEmail || '-'}}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.applicationUrl)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryApplicationUrl</p>
      <p>{{offerOrderData?.applicationUrl || '-'}}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.comment)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryOfferOrderComment</p>
      <p>{{offerOrderData?.comment || '-' }}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.location)" class="offer-property">
      <p class="subtitle" sebuTenantText>summaryOfferOrderLocation</p>
      <p>{{offerOrderData?.location?.valueToDisplay || '-'}}</p>
    </div>
    <div *ngIf="containedByProduct(FORM_CONTROL_NAME.offerOrderAttachment)" class="offer-property">
      <div class="subtitle" sebuTenantText>summaryOfferOrderAttachment</div>
      <div fxLayoutAlign="start center">
        <div *ngIf="offerOrderData?.offerOrderAttachmentLink?.startsWith('http'); else attachmentButton">
          <a href="{{offerOrderData?.offerOrderAttachmentLink}}" fxLayoutAlign="center center" fxLayoutGap="0.2em">
            <mat-icon>get_app</mat-icon>
            <span sebuTenantText>summaryOfferOrderUpload</span>
          </a>
        </div>
        <ng-template #attachmentButton>
          <button
            type="button"
            (click)="downloadAttachment(offerOrderData?.offerOrderAttachment)"
            mat-icon-button>
            <mat-icon>get_app</mat-icon>
          </button>
          <span sebuTenantText
                class="cursor-pointer"
                (click)="this.downloadAttachment(this.offerOrderData?.offerOrderAttachment)">summaryOfferOrderUpload</span>
        </ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="containedByProduct(FORM_CONTROL_NAME.categories)" class="categories">
    <div>
      <p class="subtitle" sebuTenantText>summaryOfferOrderCategoriesHeading</p>
      <sebu-category-display [compressedDisplay]="true"
                             [selectedSubCategories]="offerOrderData?.categories"></sebu-category-display>
    </div>
  </div>
</div>
