import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UserNotifyService {
  private autoHide = 5000;

  constructor(private snackBar: MatSnackBar) { }

  public notify(message: string, addExtraClassName?: string, autoHide?: number): void {
    this.notifyWithAction(message,
      false,
      null,
      addExtraClassName,
      autoHide);
  }

  // We can use this later on, if we want to add buttons to the snackbar
  public notifyWithAction(message: string, action: boolean, actionButtonLabel: string, addExtraClassName?: string, autoHide?: number): void {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = autoHide ? autoHide : this.autoHide;
    config.panelClass = addExtraClassName ? [addExtraClassName] : null;
    this.snackBar.open(message, action && actionButtonLabel, config);
  }

  public error(message: string, autoHide?: number): void {
    this.notify(message, 'errorSnackBar', autoHide);
  }

  public defaultError(): void {
    this.notify('Ein Fehler ist aufgetreten', 'errorSnackBar');
  }

  public errorWithAction(message: string, action: boolean, actionButtonLabel: string, autoHide?: number): void {
    this.notifyWithAction(message, action, actionButtonLabel, 'errorSnackBar', autoHide);
  }

  public regular(message: string, autoHide?: number): void {
    this.notify(message, 'defaultSnackBar', autoHide);
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}
