import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'sebu-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent implements AfterViewInit {

  @Input()
  public content: string;

  @Input()
  public icon = 'info_outline';

  @Input()
  public label = '';

  @Input()
  public initialOpen = false;

  @ViewChild(MatMenuTrigger, { static: true })
  private infoTrigger: MatMenuTrigger;

  public ngAfterViewInit(): void {
    if (this.initialOpen) {
      // This call is intended to make the execution wait one tick to avoid conflicts with Angular's value change checks
      setTimeout(() => {
        this.infoTrigger.openMenu();
      });
    }
  }
}
