/* tslint:disable:no-any */
export enum FormControlNameEnum {
  title = <any> 'title',
  alternativeCompany = <any> 'alternativeCompany',
  categories = <any> 'categories',
  comment = <any> 'comment',
  location = <any> 'location',
  offerOrderAttachment = <any> 'offerOrderAttachment',
  applicationEmail = <any> 'applicationEmail',
  applicationUrl = <any> 'applicationUrl',

  htmlTemplateYourAssignmentFormGroup = <any> 'htmlTemplateYourAssignmentFormGroup',
  htmlTemplateYourProfileFormGroup = <any> 'htmlTemplateYourProfileFormGroup',
  htmlTemplateWeOfferYouFormGroup = <any> 'htmlTemplateWeOfferYouFormGroup',
  htmlTemplateJobApplyContactFormGroup = <any> 'htmlTemplateJobApplyContactFormGroup',
  htmlTemplateJobApplyFormGroup = <any> 'htmlTemplateJobApplyFormGroup',
  htmlTemplateAttachmentFormGroup = <any> 'htmlTemplateAttachmentFormGroup',
  htmlTemplateBodyFormGroup = <any> 'htmlTemplateBodyFormGroup',

  htmlTemplateCompanyName = <any> 'htmlTemplateCompanyName',
  htmlTemplateIntroductionText = <any> 'htmlTemplateIntroductionText',
  htmlTemplateYourAssignmentsHeadline = <any> 'htmlTemplateYourAssignmentsHeadline',
  htmlTemplateYourAssignmentsText = <any> 'htmlTemplateYourAssignmentsText',
  htmlTemplateYourProfileHeadline = <any> 'htmlTemplateYourProfileHeadline',
  htmlTemplateYourProfileText = <any> 'htmlTemplateYourProfileText',
  htmlTemplateWeOfferYouHeadline = <any> 'htmlTemplateWeOfferYouHeadline',
  htmlTemplateWeOfferYouText = <any> 'htmlTemplateWeOfferYouText',
  htmlTemplateCompanyDescription = <any> 'htmlTemplateCompanyDescription',
  htmlTemplateJobApplyHeadline = <any> 'htmlTemplateJobApplyHeadline',
  htmlTemplateJobApplyText = <any> 'htmlTemplateJobApplyText',
  htmlTemplateJobApplyContactHeadline = <any> 'htmlTemplateJobApplyContactHeadline',
  htmlTemplateJobApplyContact = <any> 'htmlTemplateJobApplyContact',
  htmlTemplateLogo = <any> 'htmlTemplateLogo',
  htmlTemplateAttachment = <any> 'htmlTemplateAttachment',
  htmlTemplateColorPicker = <any> 'htmlTemplateColorPicker',
  htmlTemplateAttachmentHeadline = <any> 'htmlTemplateAttachmentHeadline',
  htmlTemplateAttachmentFormGroupHeadline = <any> 'htmlTemplateAttachmentFormGroupHeadline',


  companyHeader = <any> 'companyHeader',
  jobHeader = <any> 'job',
}
