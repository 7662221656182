<sebu-header-with-button
  [showEditButton]="isStepEditable"
  (buttonClick)="edit.emit()">{{"summaryHtmlTemplateDetailsTitle"| TenantTextPipe}}
</sebu-header-with-button>
<ng-template #spinner>
  <mat-spinner *ngIf="previewIsLoading" mode="indeterminate" color="primary"></mat-spinner>
</ng-template>
<div></div>
<div *ngIf="!previewIsLoading; else spinner" fxLayout="column">
  <div class="resize-options" fxLayoutAlign="center">
    <mat-button-toggle-group [value]="selectedResizeOption">
      <mat-button-toggle class="resize-button" [checked]="resizeOption.name === selectedResizeOption?.name" *ngFor="let resizeOption of availableResizeOptions" (click)="changeResizeOption(resizeOption)">{{ resizeOption?.name }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="saveResourceUrl" class="container">
    <div class="iframe-wrapper">
      <iframe [src]="saveResourceUrl" width="{{selectedResizeOption?.width}}px"></iframe>
    </div>
  </div>
</div>
