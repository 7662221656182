import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from '../../../../api/client';
import { ControlConfiguration } from '../../../../dynamic-forms/models/control-configuration';
import { FormControlNameEnum } from '../../../../dynamic-forms/models/form-control-name.enum';
import { AttachmentHelper } from '../../../../shared/helper/attachment.helper';
import { OfferOrderFormModel } from '../../../models/wizard-form-models/offer-order-form.model';

@Component({
  selector: 'sebu-offer-summary',
  templateUrl: 'offer-summary.component.html',
  styleUrls: ['offer-summary.component.scss']
})
export class OfferSummaryComponent {

  @Input()
  public offerOrderData: OfferOrderFormModel;

  @Input()
  public productControlConfiguration: ControlConfiguration[] = [];

  @Input()
  public isStepEditable = true;

  @Output()
  public edit: EventEmitter<void> = new EventEmitter<void>();

  public FORM_CONTROL_NAME: typeof FormControlNameEnum = FormControlNameEnum;

  public containedByProduct(formControlName: FormControlNameEnum): boolean {
    if (this.productControlConfiguration === null) {
      return false;
    }
    return !!this.productControlConfiguration
      .find(control => control.formControlName === formControlName);
  }

  public downloadAttachment(file: File | Attachment): void {
    let url: string;
    let blob: Blob;
    if (file instanceof File) {
      blob = new Blob([file], { type: file.type });
      url = window.URL.createObjectURL(blob);
    } else {
      url = this.offerOrderData.offerOrderAttachmentLink ? this.offerOrderData.offerOrderAttachmentLink :
        AttachmentHelper.getAttachmentLink(file);
    }
    AttachmentHelper.openAttachment(url, file.name);
  }

  public clickEdit(): void {
    this.edit.emit();
  }
}
