import { Coupon, OfferOrderRefreshOptionEnum } from '../../../api/client';

export class SummaryProductModel {
  publicationDate?: Date;
  topJob?: boolean;
  socialMedia?: boolean;
  tableEntry?: boolean;
  refreshOption?: OfferOrderRefreshOptionEnum;
  coupon?: Coupon;

  constructor(date: Date = new Date(), topJob = false, tableEntry = false, socialMedia = false, refreshOption: OfferOrderRefreshOptionEnum = null, coupon: Coupon = null) {
    date.setDate(date.getDate() + 1);
    this.publicationDate = date;
    this.topJob = topJob;
    this.socialMedia = socialMedia;
    this.tableEntry = tableEntry;
    this.refreshOption = refreshOption;
    this.coupon = coupon;
  }
}
