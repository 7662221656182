import { ProductConfiguration } from './product-configuration';
import { PortalOnlineOnlyProductConfiguration } from './portal-online-only-product-configuration';
import { PremiumOnlineOnlyProductConfiguration } from './premium-online-only-product-configuration';
import { ProductEnum } from '../../../api/client';
import { RegioOnlineOnlyProductConfiguration } from './regio-online-only-product-configuration';
import { RegioOnlineOnlyAzubiProductConfiguration } from './regio-online-only-azubi-product-configuration';
import { PortalOnlineOnlyAzubiProductConfiguration } from './portal-online-only-azubi-product-configuration';
import { PremiumOnlineOnlyAzubiProductConfiguration } from './premium-online-only-azubi-product-configuration';
import { RegioOnlineOnlyMinijobsProductConfiguration } from './regio-online-only-minijobs-product-configuration';

/**
 * Contains all configurations of products. Register new products here so the ProductMapperHelper can find the configuration
 * corresponding to the product you need
 * @type {Array}
 */
export const ProductRegistry: ProductConfiguration[] = [];

ProductRegistry[ProductEnum.OnlineOnlyPdfUndSonstigesFormat] = new PremiumOnlineOnlyProductConfiguration();
ProductRegistry[ProductEnum.OnlineOnlyAzubi] = new PremiumOnlineOnlyAzubiProductConfiguration();
ProductRegistry[ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt] = new PortalOnlineOnlyProductConfiguration();
ProductRegistry[ProductEnum.RegioOnlineOnlyStandardlayout] = new RegioOnlineOnlyProductConfiguration();
ProductRegistry[ProductEnum.RegioOnlineOnlyAzubi] = new RegioOnlineOnlyAzubiProductConfiguration();
ProductRegistry[ProductEnum.PortalOnlineOnlyAzubi] = new PortalOnlineOnlyAzubiProductConfiguration();
ProductRegistry[ProductEnum.RegioOnlineOnlyMinijobs] = new RegioOnlineOnlyMinijobsProductConfiguration();

/**
 * Contains all products with rendered offers.
 * @type {Array}
 */
export const TemplatedProducts: ProductEnum[] = [
  ProductEnum.RegioOnlineOnlyStandardlayout,
  ProductEnum.RegioOnlineOnlyAzubi,
  ProductEnum.RegioOnlineOnlyMinijobs
];

