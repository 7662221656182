import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductStatusPageFormModel } from '../../models/product-status-page-form.model';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { RefreshDatesHelper } from '../../../shared/helper/refresh-dates-helper';
import { BookedFeaturePrices } from '../../../api/client';
import { CouponDiscountHelper } from '../../../wizard/components/summary/coupon/coupon-discount.helper';

@Component({
  selector: 'sebu-status-page-product-component',
  templateUrl: './status-page-product.component.html',
  styleUrls: ['./status-page-product.component.scss']
})
export class StatusPageProductComponent implements OnChanges, OnInit {
  @Input()
  productModel: ProductStatusPageFormModel;

  public publicationDate: Date;
  public svgPath: string;
  public priceSum: number;
  public vatRate: number;
  public vatPrice: number;
  public totalPrice: number;
  public showAsteriscPriceHint: boolean;
  public amountOfRefreshes: number;
  public discount?: number;

  ngOnInit(): void {
    this.amountOfRefreshes = this.productModel && (this.productModel.refreshOption == null) ? 0 : this.calculateAmountOfRefreshes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productModel) {
      this.publicationDate = this.productModel.publicationDate ? new Date(this.productModel.publicationDate) : new Date();
      this.setTotalPrice();
    }
  }

  public setTotalPrice(): void {
    this.showAsteriscPriceHint = TenantSettingsHelper.getSettings().showAsteriscPriceHint && this.productModel.bookedFeaturePrices.sebuVatRate === undefined;
    const productPrice: number = this.productModel.bookedFeaturePrices.sebuProductPrice ? this.productModel.bookedFeaturePrices.sebuProductPrice : 0;

    const topJobPrice: number = this.productModel.bookedFeaturePrices.sebuTopJobPrice ? this.productModel.bookedFeaturePrices.sebuTopJobPrice : 0;
    const socialMediaPrice: number = this.productModel.bookedFeaturePrices.sebuSocialMediaPrice ? this.productModel.bookedFeaturePrices.sebuSocialMediaPrice : 0;
    const tableEntryPrice: number = this.productModel.bookedFeaturePrices.sebuTableEntryPrice ? this.productModel.bookedFeaturePrices.sebuTableEntryPrice : 0;
    const refreshDatesPrice: number = this.productModel.refreshOption ? this.calculateAmountOfRefreshes() * this.productModel.bookedFeaturePrices.sebuRefreshDatePrice : 0;
    const prices: BookedFeaturePrices = this.productModel.bookedFeaturePrices;

    this.priceSum = productPrice + topJobPrice + tableEntryPrice + refreshDatesPrice + socialMediaPrice;
    this.discount = CouponDiscountHelper.calculateDiscount(prices.couponAmount, prices.couponPercent, this.priceSum);
    this.priceSum = this.priceSum - (this.discount || 0);

    this.vatRate = this.productModel.bookedFeaturePrices.sebuVatRate || 0;
    // round vat rate to 2 digits and also always round up
    this.vatPrice = Number((this.priceSum * this.vatRate * 100).toFixed(2)) / 100;
    this.totalPrice = this.priceSum + this.vatPrice ;
  }

  public calculateAmountOfRefreshes(): number {
    return RefreshDatesHelper.calculatePeriodicRefreshDates(
      this.productModel.refreshOption, new Date(), this.productModel.duration).length;
  }
}
