import { isValidNumber } from 'libphonenumber-js';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FileExtension } from '../../wizard/models/file-extension';
import { FileExtensionHelper } from './file-extension-helper';
import { DateHelper } from './date.helper';
import { AttachmentFileType } from '../attachment-upload/file-types.model';
import { Attachment } from '../../api/client';


export class CustomValidators {

  // If this matches, the tested string contains invalid characters
  // Note that you can use "regex.test(null)" which converts null to string "null" which does not match => false
  // This is the Windows-1252 character set (https://de.wikipedia.org/wiki/ISO_8859-1#Windows-1252)
  // eslint-disable-next-line
  public static readonly invalidCharactersRegex: RegExp =
    // eslint-disable-next-line
    new RegExp(/[^ \u00A0\t\r\n!"#$%&'()*+,-./0-9:;<=>?@A-Za-z\[\\\]^_`{|}~€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•÷×–—˜™š›œžŸ¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏàáâãäåæçèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿ]/);

  // Regex partially copied from https://gist.github.com/dperini/729294
  // Also used in SRUI
  private static readonly urlHostRegExp: RegExp =
    new RegExp('^(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')$', 'i');

  // validates that a string contains no invalid characters (for Windows-1252)
  public static hasInvalidCharacters(value: string): boolean {
    if (value && value !== '') {
      return CustomValidators.invalidCharactersRegex.test(value);
    }
    return false;
  }

  public static phoneNumber(formControl: UntypedFormControl): ValidationErrors {
    return (CustomValidators.isValidNumber(formControl.value) && (isValidNumber(formControl.value, 'DE') || isValidNumber(formControl.value))) ? null : {
      validPhoneNumber: {
        valid: false
      }
    };
  }

  private static isValidNumber(phoneNumber: string): boolean {
    // same regex as backend(.net) uses
    // eslint-disable-next-line
    const validationForPhone: RegExp = new RegExp(/^(\+\s?)?((?!\+.*)\(\+?\d+([\s\-\.]?\d+)?\)|\d+)([\s\-\.]?(\(\d+([\s\-\.]?\d+)?\)|\d+))*(\s?(x|ext\.?)\s?\d+)?$/);

    if (phoneNumber === null) {
      return false;
    }

    return validationForPhone.test(phoneNumber);
  }

  // Validator function that returns errors if the value of the url form control does not start with http or https
  public static url(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    // Check protocol
    const urlLower: string = control.value.toLowerCase();
    const linkStartsWithHttp: boolean = urlLower.startsWith('http://') || urlLower.startsWith('https://');
    if (!linkStartsWithHttp) {
      return {invalidLink: true};
    }
    // Check url structure
    let url: URL;
    try {
      url = new URL(control.value);
    } catch (err) {
      return {invalidHost: true};
    }
    // Check hostname
    const hostIsValid: boolean = CustomValidators.urlHostRegExp.test(url.hostname);
    if (!hostIsValid) {
      return {invalidHost: true};
    }

    return null;
  }

  /**
   * Validator function which returns errors if an email is given in a text field and is invalid
   * @param {FormControl} control
   * @returns {ValidationErrors}
   */
  public static email(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    return Validators.email(control) === null ? null : {
      invalidEmail: true
    };
  }

  /**
   * Email validator that prevents introducing any char not supported by the Stellenanzeigen email services
   * @param {FormControl} formControl
   * @returns {ValidationErrors}
   */
  public static sareEmail(formControl: UntypedFormControl): ValidationErrors | null {
    const v1EmailValidator: ValidatorFn = (control: UntypedFormControl) => {
      // eslint-disable-next-line
      const v1EmailPattern: RegExp =
        // eslint-disable-next-line
        new RegExp(/^[a-zA-Z0-9]{1}[a-zA-Z0-9+\._-]*@[a-zA-Z0-9][a-zA-Z0-9_\.-]*[a-zA-Z0-9]\.[a-zA-Z]*$/, 'i');
      const validV1Email: boolean = Validators.pattern(v1EmailPattern)(control) === null;
      return validV1Email ? null : {
        invalidEmail: true
      };
    };
    return Validators.compose([CustomValidators.charset, CustomValidators.email, v1EmailValidator])(formControl);
  }

  public static fileSize(maxFileSize: number): ValidatorFn {
    return (formControl) => {
      if (!formControl.value) {
        return null;
      }
      const file: File =  formControl.value as File;
      let fileSize: number = file.size;
      if (!fileSize){
        const attachment: Attachment = formControl.value as Attachment;
        fileSize = attachment.bytes.length;
      }
      return fileSize <= maxFileSize ? null : {
        exceededFileSize: {
          valid: false
        }
      };
    };
  }

  public static fileExtension(allowedFileTypes: FileExtension[]): ValidatorFn {
    return (formControl) => {
      const file: File = formControl.value;
      if (!file) {
        return null;
      }
      const valid: boolean = FileExtensionHelper.includesFileType(allowedFileTypes, file.name);
      return valid ? null : {
        invalidFileType: {
          valid: false
        }
      };
    };
  }

  public static attachmentFileExtension(allowedFileTypes: AttachmentFileType): ValidatorFn {
    return (formControl) => {
      const file: File = formControl.value;
      if (!file) {
        return null;
      }
      return AttachmentFileType.includes(file.name, allowedFileTypes) ? null : {invalidFileType: {valid: false}};
    };
  }

  public static requiredNotBlank(formControl: AbstractControl): ValidationErrors | null {
    const value: string = formControl.value;
    return Validators.required(new UntypedFormControl(value ? value.trim() : undefined));
  }

  public static charsetAttachment(control: UntypedFormControl): ValidationErrors | null {
    return CustomValidators.validateAttachmentForIllegalCharacters(control, CustomValidators.invalidCharactersRegex);
  }

  /**
   * Validator function that returns errors if the value of the form control is a text that contains characters not
   * allowed in Windows-1252
   * @param {FormControl} control
   * @returns {ValidationErrors}
   */
  public static charset(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value || typeof control.value !== 'string') {
      return null;
    }

    const result: RegExpExecArray | null = CustomValidators.invalidCharactersRegex.exec(control.value as string);
    if (result) {
      return {
        invalidChars: {
          valid: false,
          char: result[0]
        }
      };
    }
    return null;
  }

  public static dateBetween(startDate: Date = new Date(), duration = 'P0D'): ValidatorFn {
    return (formControl) => {
      const date: Date = formControl.value;
      // refresh date has to be between start date and end date, as well as not in the past. The start date itself cannot be chosen as refreshDate.
      // If no startDate is given, the startDate is set to today by the server, so we validate against today.
      const minDate: Date = DateHelper.getRefreshMinDate(startDate);
      const maxDate: Date = DateHelper.calculateEndDateFromDuration(startDate, duration);
      if (!date || !minDate || !maxDate || ((minDate <= new Date(date)) && (maxDate >= new Date(date)))) {
        return null;
      }
      return {
        dateBetween: {
          valid: false,
          minDate: minDate,
          maxDate: maxDate
        }
      };
    };
  }

  public static noDuplicateDates(dates: (Date | string)[] = []): ValidatorFn {
    return (formControl) => {
      const currentDate: Date = formControl.value;
      if (!currentDate) {
        return null;
      }

      const dateTimes: number[] = dates.filter(d => !!d)
        .map(d => new Date(d).getTime())
        .filter(d => d === new Date(currentDate).getTime());

      return dateTimes && dateTimes.length > 1 ? {
        duplicateDates: {
          valid: false
        }
      } : null;
    };
  }

  private static validateAttachmentForIllegalCharacters(control: UntypedFormControl, regex: RegExp): ValidationErrors | null {
    if (!control.value || !control.value.name || typeof control.value.name !== 'string') {
      return null;
    }
    return this.validateStringForIllegalCharacters(control.value.name as string, regex);
  }

  private static validateStringForIllegalCharacters(value: string, regex: RegExp): ValidationErrors | null {
    const result: RegExpExecArray | null = regex.exec(value);
    if (result) {
      return {
        invalidChars: {
          valid: false,
          index: result.index + 1,
          char: result[0]
        }
      };
    }
    return null;
  }
}
