// @ts-ignore
import { IEnvironment } from '../environment.type';

export const environment: IEnvironment  = {
  features: {
    radioProductSelection: false
  },
  linearStepper: true,
  production: true,
  environmentName: 'SA StagingFeature',
  pathToSVGs: './assets/images/',
  apiEndpoint: 'https://feature-master.whitelabelportal.de/anzeigen-selbstbuchung',
  sentryDSN: 'https://4fde6ab360e84c4f82946c16f4428a37@sentry.statec.io/72',
  stripeKey: 'pk_test_8VobgjZb5JLmCrGdpXn9Dyi100xhixcbsr',
};
