import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { OfferOrderRefreshOptionEnum } from '../../../api/client';
import { RefreshDatesHelper } from '../../../shared/helper/refresh-dates-helper';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { OrderPrice } from '../../../wizard/models/wizard-form-models/order-price.model';

@Component({
  selector: 'sebu-refresh-option-input',
  templateUrl: './refresh-option-input.component.html',
  styleUrls: ['./refresh-option-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RefreshOptionInputComponent),
      multi: true
    }
  ]
})
export class RefreshOptionInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input('duration')
  public set duration(value: string) {
    this._duration = value;
    this.calculateRefreshOptions();
  }

  public get duration(): string {
    return this._duration;
  }

  @Input()
  public priceModel: OrderPrice;

  public formGroup: FormGroup;
  public displayRefreshTooltips: boolean;
  public showAsteriscPriceHint: boolean;
  public allOptions: RefreshOptionInfo[] = [{
    value: OfferOrderRefreshOptionEnum.Nach15Tagen,
    tooltipText: 'refreshOption15DaysTooltip',
    name: 'refreshOption15DaysTitle',
    asteriscPriceHint: ' *'
  },
    {
      value: OfferOrderRefreshOptionEnum.Alle30Tage,
      tooltipText: 'refreshOption30DaysTooltip',
      name: 'refreshOption30DaysTitle',
      asteriscPriceHint: ' */**'
    }];
  public availableOptions: RefreshOptionInfo[] = [];

  private componentDestroyed$: Subject<void> = new Subject<void>();
  private _duration: string;

  // eslint-disable-next-line
  public writeValue(option: OfferOrderRefreshOptionEnum): void {
  }

  // eslint-disable-next-line
  public propagateChange = (option: OfferOrderRefreshOptionEnum) => {
  }

  // eslint-disable-next-line
  public registerOnTouched(fn: (_: OfferOrderRefreshOptionEnum) => {}): void {
  }

  public registerOnChange(fn: (option: OfferOrderRefreshOptionEnum) => {}): void {
    this.propagateChange = fn;
  }

  constructor(private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.showAsteriscPriceHint = TenantSettingsHelper.getSettings().showAsteriscPriceHint;
    this.displayRefreshTooltips = TenantSettingsHelper.getSettings().tooltips.refreshOption;
    this.formGroup = this.formBuilder.group({
      selectedOption: null
    });

    this.formGroup.valueChanges.pipe(map((value) => value.selectedOption), distinctUntilChanged()).subscribe((value) => this.propagateChange(value));
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }


  public uncheckWhenChecked(checked: boolean): void {
    setTimeout(() => {
      if (checked) {
        this.formGroup.reset();
      }
    });
  }

  private calculateRefreshOptions(): void {
    const amountOfRefreshDates: number = RefreshDatesHelper.calculatePeriodicRefreshDates(
      OfferOrderRefreshOptionEnum.Alle30Tage, new Date(), this.duration).length;
    setTimeout(() => {
      this.availableOptions = amountOfRefreshDates === 0 ? [] : amountOfRefreshDates < 2 ?
        [...this.allOptions].filter(option => option.value === OfferOrderRefreshOptionEnum.Nach15Tagen) : [...this.allOptions];
      if (this.formGroup) {
        this.formGroup.reset();
      }
    });
  }
}

class RefreshOptionInfo {
  value: OfferOrderRefreshOptionEnum;
  tooltipText: string;
  name: string;
  asteriscPriceHint: string;
}
