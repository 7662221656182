import { SebuOfferOrderCreate } from '../../api/client';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { ProductConfiguration } from '../../wizard/models/product-configuration/product-configuration';
import { UiStateModel } from '../../wizard/models/ui-state.model';
import { WizardFormModel } from '../../wizard/models/wizard-form-models/wizard-form.model';

export class  ConfirmationPageState {
  public uiState: UiStateModel;
  public productConfiguration: ProductConfiguration;
  public offerOrderData: SebuOfferOrderCreate;
  public formModels: WizardFormModel;

  constructor() {
    this.uiState = {
      orderButton: {
        text: (TenantTextPipe.transform('buttonOrder') as string),
        isLoadingOrHasAnError: false
      }
    };
    this.productConfiguration = undefined;
    this.offerOrderData = undefined;
    this.formModels = undefined;
  }
}
