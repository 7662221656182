import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';
import { FileExtension } from '../../wizard/models/file-extension';

export  class AttachmentControlConfiguration extends ControlConfiguration{
  public allowedFileTypes: FileExtension[];
  public deletable: boolean;
  public iconOnly: boolean;

  constructor(options: DownloadControlConfigurationArgs) {
    super(ControlType.ATTACHMENT, options);
    this.allowedFileTypes = options.allowedFileTypes || [];
    this.deletable = options.deletable || false;
    this.iconOnly = options.iconOnly || false;
  }
}

export interface DownloadControlConfigurationArgs extends ControlConfigurationArgs {
  allowedFileTypes: FileExtension[];
  heading?: string;
  buttonText?: string;
  deletable?: boolean;
  iconOnly?: boolean;
}
