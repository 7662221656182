import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationPageData } from '../../../confirmation-page/models/confirmation-page-data.model';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration'; // eslint-disable-line
import { CategoryHelper } from '../../../form-components/helper/category.helper';
import { AttachmentHelper } from '../../../shared/helper/attachment.helper';

import { AddonType, AvailableAddon } from '../../../wizard/models/available-addon.model';
import { AvailableProductDuration } from '../../../wizard/models/available-product-duration.model';
import { AvailableProduct } from '../../../wizard/models/available-product.model';
import {
  PortalOnlineOnlyProductConfiguration
} from '../../../wizard/models/product-configuration/portal-online-only-product-configuration';
import {
  PremiumOnlineOnlyProductConfiguration
} from '../../../wizard/models/product-configuration/premium-online-only-product-configuration';
import { ProductConfiguration } from '../../../wizard/models/product-configuration/product-configuration';
import { ProductRegistry, TemplatedProducts } from '../../../wizard/models/product-configuration/product-registry';
import {
  RegioOnlineOnlyProductConfiguration
} from '../../../wizard/models/product-configuration/regio-online-only-product-configuration';
import { CompanyFormModel } from '../../../wizard/models/wizard-form-models/company-form.model';
import { OfferOrderFormModel } from '../../../wizard/models/wizard-form-models/offer-order-form.model';
import { OrderPrice } from '../../../wizard/models/wizard-form-models/order-price.model';
import { ProductFormModel } from '../../../wizard/models/wizard-form-models/product-form.model';
import { StandardLayoutFormModel } from '../../../wizard/models/wizard-form-models/standard-layout-form.model';
import { SummaryFormModel } from '../../../wizard/models/wizard-form-models/summary-form.model';
import { WizardFormModel } from '../../../wizard/models/wizard-form-models/wizard-form.model';
import { ErrorHelper } from '../error/error.helper';
import {
  Attachment,
  CompanyData,
  ProductEnum,
  SebuOfferOrderCreate,
  SebuProduct,
  SebuResellerData
} from '../../../api/client';
import {
  RegioOnlineOnlyAzubiProductConfiguration
} from '../../../wizard/models/product-configuration/regio-online-only-azubi-product-configuration';
import {
  PortalOnlineOnlyAzubiProductConfiguration
} from '../../../wizard/models/product-configuration/portal-online-only-azubi-product-configuration';
import { LocationDisplay } from 'app/form-components/components/location-input/location-input.model';
import {
  PremiumOnlineOnlyAzubiProductConfiguration
} from '../../../wizard/models/product-configuration/premium-online-only-azubi-product-configuration';
import { RefreshDatesHelper } from '../../../shared/helper/refresh-dates-helper';
import { CouponDiscountHelper } from '../../../wizard/components/summary/coupon/coupon-discount.helper';
import {
  RegioOnlineOnlyMinijobsProductConfiguration
} from '../../../wizard/models/product-configuration/regio-online-only-minijobs-product-configuration';

export class ProductMapperHelper {

  /**
   * Returns the corresponding configuration for a product
   * @returns {ControlConfiguration[]} which holds the configurations of the selected product
   */
  static mapProductToConfiguration(product: ProductEnum): ProductConfiguration {
    switch (product) {
      case ProductEnum.OnlineOnlyPdfUndSonstigesFormat:
        return new PremiumOnlineOnlyProductConfiguration();
      case ProductEnum.OnlineOnlyAzubi:
        return new PremiumOnlineOnlyAzubiProductConfiguration();
      case ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt:
        return new PortalOnlineOnlyProductConfiguration();
      case ProductEnum.RegioOnlineOnlyStandardlayout:
        return new RegioOnlineOnlyProductConfiguration();
      case ProductEnum.RegioOnlineOnlyAzubi:
        return new RegioOnlineOnlyAzubiProductConfiguration();
      case ProductEnum.RegioOnlineOnlyMinijobs:
        return new RegioOnlineOnlyMinijobsProductConfiguration();
      case ProductEnum.PortalOnlineOnlyAzubi:
        return new PortalOnlineOnlyAzubiProductConfiguration();
      default:
        ErrorHelper.throwError(`No product configuration defined for ${product}`);
        return null;
    }
  }

  static mapResellerDataToAvailableProducts(resellerData: SebuResellerData): AvailableProduct[] {
    const products: AvailableProduct[] = [];

    // map each sebu product
    resellerData.products.forEach((product: SebuProduct) => {
      const availableProduct: AvailableProduct = new AvailableProduct();

      availableProduct.productEnum = product.productId;
      availableProduct.productName = product.resellerProductName;

      // calculate duration with prices
      const durations: AvailableProductDuration[] = [];
      for (const duration in product.priceByDuration) {
        // eslint-disable-next-line
        if (product.priceByDuration.hasOwnProperty(duration)) {
          const d: AvailableProductDuration = new AvailableProductDuration(duration, product.priceByDuration[duration], product.productId, product.resellerProductName);
          durations.push(d);
        }
      }
      availableProduct.availableDurations = this.sortDurationsByLength(durations);

      // add addon for each allowed additional feature
      if (product.isTableEntryAllowed && _.isNumber(resellerData.tableEntryPrice)) {
        availableProduct.availableProductAddons = [new AvailableAddon(AddonType.TableEntry, resellerData.tableEntryPrice)];
      }
      if (_.isNumber(product.refreshDatePrice)) {
        availableProduct.availableProductAddons.push(new AvailableAddon(AddonType.Refresh, product.refreshDatePrice));
      }
      if (_.isNumber(resellerData.topJobPrice)) {
        availableProduct.availableProductAddons.push(new AvailableAddon(AddonType.TopJob, resellerData.topJobPrice));
      }
      if(product.isSocialMediaAllowed && _.isNumber(resellerData.socialMediaPrice)) {
        availableProduct.availableProductAddons.push(new AvailableAddon(AddonType.SocialMedia, resellerData.socialMediaPrice));
      }

      // add VAT rate
      availableProduct.vatRate = resellerData.vatRate;

      // add product configuration from registry
      const productConfiguration: ProductConfiguration = ProductRegistry[product.productId];
      if (!productConfiguration) {
        ErrorHelper.throwError(`${product.productId} is not registered inside registry`);
      }
      availableProduct.icon = productConfiguration.iconName;

      products.push(availableProduct);
    });

    return products;
  }

  static sortDurationsByLength(durations: AvailableProductDuration[]): AvailableProductDuration[] {
    return durations.sort((duration1, duration2) => {
      const duration1InDays: number = moment.duration(duration1.value.duration).asDays();
      const duration2InDays: number = moment.duration(duration2.value.duration).asDays();
      return duration1InDays - duration2InDays;
    });
  }

  static mapCompanyDataToCompanyFormModel(companyData: CompanyData): CompanyFormModel {
    const companyFormModel: CompanyFormModel = {
      companyName: companyData.name || '',
      telephoneNumber: companyData.phoneNumber || '',
      email: companyData.email || '',
      homepage: companyData.homepage || '',
      street: companyData.street || '',
      postOffice: companyData.postOfficeBox || '',
      city: companyData.city,
      postcode: companyData.postcode || '',
      country: companyData.country || '',
      salutation: companyData.salutation || '',
      title: companyData.title || '',
      firstName: companyData.firstName || '',
      lastName: companyData.lastName || ''
    };
    return companyFormModel;
  }

  static mapJobDataToOfferOrderFormModel(sebuOfferOrderCreate: ConfirmationPageData): OfferOrderFormModel {
    const createData: SebuOfferOrderCreate = sebuOfferOrderCreate.createData;
    return {
      offerOrderAttachmentLink: AttachmentHelper.getAttachmentLink(sebuOfferOrderCreate.createData.attachment),
      offerOrderAttachment: createData.attachment,
      alternativeCompany: createData.company.alternativeCompany || '',
      applicationEmail: createData.job.applicationEmail || '',
      applicationUrl: createData.job.applicationUrl || '',
      title: createData.job.title || '',
      comment: createData.comment,
      location: createData.job.locationType ?
        new LocationDisplay(createData.job.locationType, createData.job.location, createData.job.location) :
        undefined,
      categories: createData.job.categoryGroups.length > 0 ?
        CategoryHelper.findSubCategoriesWithGroupIds(sebuOfferOrderCreate.allCategories, createData.job.categoryGroups) :
        []
    };
  }

  static mapProductDataToProductFormModel(sebuOfferOrder: SebuOfferOrderCreate, productName: string): ProductFormModel {
    return new ProductFormModel(
      sebuOfferOrder.product || undefined,
      sebuOfferOrder.duration || '',
      productName || ''
    );
  }

  static mapProductDataToSummaryFormModel(sebuOfferOrder: SebuOfferOrderCreate): SummaryFormModel {
    return {
      summaryProduct: {
        publicationDate: sebuOfferOrder.publicationDate,
        topJob: sebuOfferOrder.bookedFeaturePrices.sebuTopJobPrice != null,
        socialMedia: sebuOfferOrder.bookedFeaturePrices.sebuSocialMediaPrice != null,
        tableEntry: sebuOfferOrder.bookedFeaturePrices.sebuTableEntryPrice != null,
        refreshOption: sebuOfferOrder.refreshOption
      }
    };
  }


  static mapProductToStandardLayoutFormModel(sebuOfferOrder: SebuOfferOrderCreate): StandardLayoutFormModel {
    const standardLayoutFormModel: StandardLayoutFormModel = {
      companyName: sebuOfferOrder.company.alternativeCompany,
      introductionText: sebuOfferOrder.details.introductionText,
      yourAssignmentsHeadline: sebuOfferOrder.details.yourAssignmentsHeadline,
      yourAssignmentsText: sebuOfferOrder.details.yourAssignmentsText,
      yourProfileHeadline: sebuOfferOrder.details.yourProfileHeadline,
      yourProfileText: sebuOfferOrder.details.yourProfileText,
      weOfferYouHeadline: sebuOfferOrder.details.weOfferYouHeadline,
      weOfferYouText: sebuOfferOrder.details.weOfferYouText,
      companyDescription: sebuOfferOrder.details.companyDescription,
      jobApplyHeadline: sebuOfferOrder.details.jobApplyHeadline,
      jobApplyText: sebuOfferOrder.details.jobApplyText,
      jobApplyContactHeadline: sebuOfferOrder.details.jobApplyContactHeadline,
      jobApplyContact: sebuOfferOrder.details.jobApplyContact,
      logo: sebuOfferOrder.details.logo,
      logoLink: AttachmentHelper.getAttachmentLink(sebuOfferOrder.details.logo),
      attachment: sebuOfferOrder.details.attachment,
      attachmentHeadline: sebuOfferOrder.details.attachmentDisplayText,
      attachmentLink: AttachmentHelper.getAttachmentLink(sebuOfferOrder.details.attachment),
      color: sebuOfferOrder.details.offerOrderClassicRenderStyle ? sebuOfferOrder.details.offerOrderClassicRenderStyle.color : ''
    };
    return standardLayoutFormModel;
  }

  static mapWizardFormModelsToSebuOfferOrderCreateModel(formModel: WizardFormModel,
                                                        attachment: Attachment,
                                                        priceModel: OrderPrice): SebuOfferOrderCreate {
    const offerOrderForm: OfferOrderFormModel = formModel.offerOrder;
    const productForm: ProductFormModel = formModel.product;
    const companyForm: CompanyFormModel = formModel.company;
    const summaryForm: SummaryFormModel = formModel.summary;
    const standardLayoutForm: StandardLayoutFormModel = formModel.standardLayout;

    const productPrice: number = priceModel.durationPrice || 0;
    const bookedTopJobPrice: number = summaryForm.summaryProduct.topJob ? priceModel.topJobPrice : null;
    const bookedSocialMediaPrice: number = summaryForm.summaryProduct.socialMedia ? priceModel.socialMediaPrice : null;
    const bookedTableEntryPrice: number = summaryForm.summaryProduct.tableEntry ? priceModel.tableEntryPrice : null;
    const bookedRefreshDatePrice: number = summaryForm.summaryProduct.refreshOption ? priceModel.refreshPrice : null;

    const amountOfRefreshes: number = RefreshDatesHelper.calculatePeriodicRefreshDates(summaryForm.summaryProduct.refreshOption, new Date(), productForm.duration).length;
    const bookedRefreshDatesPrice: number = bookedRefreshDatePrice * amountOfRefreshes;

    // Discount must be applied to the total price before the vat calculation takes place
    let totalBookedPrice: number = productPrice + bookedTopJobPrice + bookedRefreshDatesPrice + bookedTableEntryPrice + bookedSocialMediaPrice;
    const discount: number | null = CouponDiscountHelper.calculateDiscountFromCoupon(summaryForm.summaryProduct.coupon, totalBookedPrice);
    totalBookedPrice = totalBookedPrice - (discount || 0);

    // apply vat
    if (priceModel.vatRate) {
      totalBookedPrice += Number((priceModel.vatRate * totalBookedPrice * 100).toFixed(2)) * 0.01;
    }

    const sebuOfferOrder: SebuOfferOrderCreate = {
      product: productForm.productEnum,
      publicationDate: summaryForm.summaryProduct.publicationDate,
      duration: productForm.duration,
      refreshOption: summaryForm.summaryProduct.refreshOption,
      company: {
        name: companyForm.companyName || null,
        firstName: companyForm.firstName || null,
        lastName: companyForm.lastName || null,
        street: companyForm.street || null,
        postcode: companyForm.postcode || null,
        city: companyForm.city || null,
        email: companyForm.email || null,
        phoneNumber: companyForm.telephoneNumber || null,
        salutation: companyForm.salutation || null,
        title: companyForm.title || null,
        homepage: companyForm.homepage,
        alternativeCompany: TemplatedProducts.includes(productForm.productEnum) ?
          standardLayoutForm.companyName || null : offerOrderForm.alternativeCompany || null,
        postOfficeBox: companyForm.postOffice || null,
        country: companyForm.country || null
      },
      job: {
        title: offerOrderForm.title || null,
        applicationEmail: offerOrderForm.applicationEmail || null,
        applicationUrl: offerOrderForm.applicationUrl || null,
        location: offerOrderForm.location ? offerOrderForm.location.value || null : null,
        locationType: offerOrderForm.location ? offerOrderForm.location.type || null : null,
        categoryGroups: CategoryHelper.getCategoryGroupIds(offerOrderForm.categories)
      },
      comment: offerOrderForm.comment || null,
      attachment: !TemplatedProducts.includes(productForm.productEnum) ? attachment : null,
      bookedFeaturePrices: {
        sebuProductPrice: priceModel.durationPrice,
        sebuTopJobPrice: bookedTopJobPrice == null ? undefined : bookedTopJobPrice,
        sebuSocialMediaPrice: bookedSocialMediaPrice == null ? undefined : bookedSocialMediaPrice,
        sebuTableEntryPrice: bookedTableEntryPrice == null ? undefined : bookedTableEntryPrice,
        // just the price per refresh date is given here, the backend expects no price if not booked and a correct total price
        sebuRefreshDatePrice: bookedRefreshDatePrice == null ? undefined : bookedRefreshDatePrice,
        sebuTotalPrice: totalBookedPrice,
        sebuVatRate: priceModel.vatRate,
        couponCode: summaryForm.summaryProduct.coupon && summaryForm.summaryProduct.coupon.code,
        couponName: summaryForm.summaryProduct.coupon && summaryForm.summaryProduct.coupon.name,
        couponAmount: summaryForm.summaryProduct.coupon &&  summaryForm.summaryProduct.coupon.amount,
        couponPercent: summaryForm.summaryProduct.coupon &&  summaryForm.summaryProduct.coupon.percent
      },
      details: standardLayoutForm ? {
        offerOrderClassicRenderStyle: {
          color: standardLayoutForm.color || null
        },
        attachment: attachment ? attachment : null,
        attachmentDisplayText: standardLayoutForm.attachmentHeadline || null,
        logo: standardLayoutForm.logo ? {
          bytes: standardLayoutForm.logo.bytes,
          name: standardLayoutForm.logo.name
        } : null,
        companyDescription: standardLayoutForm.companyDescription || null,
        introductionText: standardLayoutForm.introductionText || null,
        yourAssignmentsHeadline: standardLayoutForm.yourAssignmentsHeadline || null,
        yourAssignmentsText: standardLayoutForm.yourAssignmentsText || null,
        yourProfileHeadline: standardLayoutForm.yourProfileHeadline || null,
        yourProfileText: standardLayoutForm.yourProfileText || null,
        weOfferYouHeadline: standardLayoutForm.weOfferYouHeadline || null,
        weOfferYouText: standardLayoutForm.weOfferYouText || null,
        jobApplyHeadline: standardLayoutForm.jobApplyHeadline || null,
        jobApplyText: standardLayoutForm.jobApplyText || null,
        jobApplyContactHeadline: standardLayoutForm.jobApplyContactHeadline || null,
        jobApplyContact: standardLayoutForm.jobApplyContact || null
      } : null
    };
    return sebuOfferOrder;
  }

}
