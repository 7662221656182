import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({name: 'replaceErrorsForUpload'})
export class ReplaceErrorsForUploadPipe implements PipeTransform {
    transform(errors: ValidationErrors): ValidationErrors {
        if (errors && 'required' in errors) {
            errors['required_upload'] = errors['required'];
            delete errors['required'];
        }
        return errors;
    }
}
