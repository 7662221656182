import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { OfferService, ServicesService } from '../../api/client';
import * as OfferOrderActions from '../../confirmation-page/ngrx/actions';
import { UserNotifyService } from '../../core/services/user-notify/user-notify.service';
import { TenantTextPipe } from '../../tenant-texts/tenant-text.pipe';
import { ConfirmationPageData } from '../models/confirmation-page-data.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocationDisplay } from '../../form-components/components/location-input/location-input.model';
import { LocationDisplayHelper } from '../../form-components/helper/location-display.helper';
import { ResellerCachingService } from '../../core/services/reseller-caching-service/reseller-caching.service';
import { CategoriesCachingService } from '../../core/services/categories-caching-service/categories-caching.service';
import { LoadOfferOrderData } from '../../confirmation-page/ngrx/actions';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationPageEffects {
  constructor(public actions$: Actions,
              private offerService: OfferService,
              private userNotifyService: UserNotifyService,
              private sazService: ServicesService,
              private categoriesCachingService: CategoriesCachingService,
              private resellerCachingService: ResellerCachingService) {
  }

  @Effect()
  loadOfferOrderConfirmationPage: Observable<OfferOrderActions.LoadOfferOrderDataSuccess> = this.actions$.pipe(
    ofType(OfferOrderActions.LOAD_OFFER_ORDER_DATA),
    switchMap((action: LoadOfferOrderData) => combineLatest(
      this.offerService.getOfferOrderSummary(action.payload.date, action.payload.token)
        .pipe(catchError((error) => {
          this.userNotifyService.error(TenantTextPipe.transform('linkExpiredError') as string);
          return throwError(error);
        })),
      this.categoriesCachingService.getCategories(),
      this.resellerCachingService.getReseller()
    )),
    switchMap(([createData, allCategories, resellerData]) => createData.job.location ?
      this.sazService.cities(createData.job.location).pipe(
        switchMap(result => {
          if (createData.job.locationType === 'Ort') {
            const displayLocation: LocationDisplay = LocationDisplayHelper.toCityOrZipLocationModel(result[0]);
            createData.job.location = displayLocation.valueToDisplay;
          }
          return of(new ConfirmationPageData(createData, allCategories, resellerData));
        }), catchError((error) => {
          this.userNotifyService.error(TenantTextPipe.transform('error') as string);
          return throwError(error);
        }))
      : of(new ConfirmationPageData(createData, allCategories, resellerData))
    ),
    map((result: ConfirmationPageData) => new OfferOrderActions.LoadOfferOrderDataSuccess(result)));
}
