import { SebuOfferOrderActions } from '../../api/client';
import { PayloadAction } from '../../core/models/payload-action.model';
import { DeactivatePayload } from '../models/deactivate-payload';
import { StatusPageData } from '../models/status-page-data.model';

export const LOAD_OFFER_ORDER_DATA_AND_ACTIONS = '[Status Page] load offer order & actions';
export const DELETE_OFFER_ORDER = '[Status Page] delete offer order';
export const DEACTIVATE_OFFER_ORDER = '[Status Page] deactivate offer order';
export const ACTIVATE_OFFER_ORDER = '[Status Page] activate offer order';


export const LOAD_OFFER_ORDER_ACTIONS_SUCCESS = '[Status Page] load offer order actions success';
export const LOAD_OFFER_ORDER_DATA_SUCCESS = '[Status Page] load offer order success';

export const DELETE_OFFER_ORDER_SUCCESS = '[Status Page] delete offer order success';
export const ACTIVATE_OFFER_ORDER_SUCCESS = '[Status Page] activate offer order success';
export const DEACTIVATE_OFFER_ORDER_SUCCESS = '[Status Page] deactivate offer order success';

export const SHOW_STATUS_PAGE_ERRORS = '[Status Page] an error occured';



export class LoadOfferOrderDataAndActions implements PayloadAction {
  readonly type: string = LOAD_OFFER_ORDER_DATA_AND_ACTIONS;
  constructor(public payload: string) {}
}

export class DeleteOfferOrder implements  PayloadAction {
  readonly type: string = DELETE_OFFER_ORDER;
  constructor(public payload: string) {}
}

export class DeactivateOfferOrder implements PayloadAction {
  readonly type: string = DEACTIVATE_OFFER_ORDER;
  constructor(public payload: DeactivatePayload) {}
}
export class ActivateOfferOrder implements  PayloadAction {
  readonly type: string = ACTIVATE_OFFER_ORDER;
  constructor(public payload: string) {}
}

/*---- SUCCESS ---*/
export class ActivateOfferOrderSuccess implements  PayloadAction {
  readonly type: string = ACTIVATE_OFFER_ORDER_SUCCESS;
  constructor(public payload: string) {}
}

export class DeactivateOfferOrderSuccess implements  PayloadAction {
  readonly type: string = DEACTIVATE_OFFER_ORDER_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadOfferOrderDataSuccess implements PayloadAction {
  readonly type: string = LOAD_OFFER_ORDER_DATA_SUCCESS;
  constructor(public payload: StatusPageData) {}
}

export class LoadOfferOrderActionsSuccess implements PayloadAction {
  readonly type: string = LOAD_OFFER_ORDER_ACTIONS_SUCCESS;
  constructor(public payload: SebuOfferOrderActions) {}
}

export class DeleteOfferOrderSuccess implements PayloadAction {
  readonly type: string = DELETE_OFFER_ORDER_SUCCESS;
}


/*---- ERRORS ---*/

export class ShowStatusPageErrors implements PayloadAction {
  readonly type: string = SHOW_STATUS_PAGE_ERRORS;
  constructor(public payload: string) {}
}



export type Actions =
  LoadOfferOrderDataAndActions
  | DeleteOfferOrder
  | ActivateOfferOrder
  | DeactivateOfferOrder
  | LoadOfferOrderDataSuccess
  | LoadOfferOrderActionsSuccess
  | ShowStatusPageErrors
  | ActivateOfferOrderSuccess
  | DeactivateOfferOrderSuccess;
