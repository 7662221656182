<input
  formControlName="fileUpload"
  #fileUpload
  type="file"
  [hidden]='true'
  (change)="uploadFile($event, false)"
  accept="{{ allowedFileTypes }}"
  multiple="true"/>

<input
  formControlName="fileReplace"
  #fileReplace
  type="file"
  [hidden]='true'
  (change)="replaceFile($event)"
  accept="{{ allowedFileTypes }}"
  multiple="true"/>

<div class="action-dialog zip-upload-dialog">
  <div fxLayout="row">
    <div fxFlex="90">
      <h2 matDialogTitle>Anhänge verwalten</h2>
    </div>
    <div fxLayoutAlign="end start" fxFlex>
      <button mat-button class="button-close" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout="column">
    <div class="text" cdkFocusInitial><p>Bitte laden Sie eine oder mehrere Dateien des Formats {{ allowedFileTypeHint }} hoch. </p></div>
    <div class="text"><p>Sie können max. 15 MB an Anhängen hochladen</p></div>
    <div *ngIf="warningMessage" style="margin: 0.2em;" fxLayout="row" class="validation-message">
      <div><mat-icon [color]="'warn'">warning</mat-icon></div>
      <div>{{ warningMessage }}</div>
    </div>
    <div class="attachment-items-block">
      <div class="attachment-item-row" fxLayout="row" fxLayoutAlign="space-between" *ngFor="let file of attachmentFiles$ | keyvalue">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div><mat-icon color="primary">attach_file</mat-icon></div>
          <div class="filename"><p>{{file.key}}</p></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <div><p>{{(file.value.buffer.byteLength/MegaByteToByteConst).toFixed(2)}} MB</p></div>
          <button mat-icon-button type="button" (click)="removeFromAttachmentFiles(file.key)"
                  [disabled]="formDisabled">
            <mat-icon color="primary">{{ 'delete' }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <button class="add-attachment-button" mat-raised-button fxFlex="" (click)="fileUpload.click()">
        <span fxLayoutAlign="center center">
          <mat-icon class="attach-icon">attach_file</mat-icon>
          <span>{{attachmentFiles$.size === 0 ? "Anhang hinzufügen" : "weitere Anhänge hinzufügen"}}</span>
        </span>
      </button>
    </div>
    <div fxLayout="row">
      <div fxLayoutAlign="center" fxFlex="">
        <div><p>Noch verfügbar: {{(15 - (attachmentSizeInBytes/MegaByteToByteConst)).toFixed(2)}} MB</p></div>
      </div>
    </div>
    <div fxLayout="row">
        <button mat-raised-button fxLayoutAlign="center" class="save-attachment-button" fxFlex="" color="accent" [disabled]="!isAttachmentChanged || formDisabled" (click)="save()">
          <span fxLayoutAlign="center center" fxLayoutGap="5">
            <mat-icon>save</mat-icon>
            <span>{{attachment && attachment.bytes ? "Ändern" : "Hochladen"}}</span>
          </span>
        </button>
    </div>
  </div>
</div>
