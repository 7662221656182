import { ControlConfiguration, ControlConfigurationArgs } from './control-configuration';
import { ControlType } from './control-type.enum';

export class FormGroupConfiguration extends ControlConfiguration {
  controls: ControlConfiguration[];
  formGroupName: string;

  constructor(options: FormGroupConfigurationArgs) {
    super(ControlType.FORM_GROUP, options);
    this.controls = options.controls;
    this.formGroupName = options.formGroupName;
  }
}

export interface FormGroupConfigurationArgs extends ControlConfigurationArgs {
  controls: ControlConfiguration[];
  formGroupName: string;
}
