import { Component, Input, OnInit } from '@angular/core';
import { TenantTextHelper } from '../../../../../tenant-texts/tenant-text.helper';
import { ProductEnum } from '../../../../../api/client';
import { ErrorHelper } from '../../../../../core/utils/error/error.helper';
import { TenantSettingsHelper } from '../../../../../tenant-settings/tenant-settings.helper';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'sebu-product-card-description',
  templateUrl: './product-card-description.component.html',
  styleUrls: ['./product-card-description.component.scss']
})
export class ProductCardDescriptionComponent implements OnInit{


  @Input()
  product: ProductEnum;

  public productEnum: typeof ProductEnum = ProductEnum;

  public tenantTextsPlaceholder: string [] = [
    'ProductProductCardPlaceholder1',
    'ProductProductCardPlaceholder2',
    'ProductProductCardPlaceholder3',
    'ProductProductCardPlaceholder4'
  ];

  public checkIconPath: string;

  ngOnInit(): void {
    if (TenantSettingsHelper.getSettings().customCheckIconName) {
      this.checkIconPath = environment.pathToSVGs + TenantSettingsHelper.getSettings().customCheckIconName;
    }
  }

  public hasValue(key: string): boolean {
    const tenantText: string = this.getTenantTextVariable(key);
    return TenantTextHelper.hasValue(tenantText);
  }

  public getTenantTextVariable(key: string): string {
    return this.getProductName(this.product) + key;
  }

  private getProductName(product: ProductEnum): string {
    switch (product) {
      case ProductEnum.RegioOnlineOnlyStandardlayout:
        return 'regioOnlineOnly';
      case ProductEnum.RegioOnlineOnlyAzubi:
        return 'regioOnlineOnlyAzubi';
      case ProductEnum.RegioOnlineOnlyMinijobs:
        return 'regioOnlineOnlyMinijobs';
      case ProductEnum.OnlineOnlyPdfUndSonstigesFormat:
        return 'premiumOnlineOnly';
      case ProductEnum.OnlineOnlyAzubi:
        return 'premiumOnlineOnlyAzubi';
      case ProductEnum.PortalOnlineOnlyVonStellenanzeigenDeErstellt:
        return 'portalOnlineOnly';
      case ProductEnum.PortalOnlineOnlyAzubi:
        return 'portalOnlineOnlyAzubi';
      default:
        ErrorHelper.throwError(`No tenant text defined for ${{ product }}.`);
    }
  }


}
