import * as _ from 'lodash';
import { ArrayHelper } from '../../../shared/helper/array.helper';
import { TenantSettingsHelper } from '../../../tenant-settings/tenant-settings.helper';
import { AvailableProduct } from '../../models/available-product.model';
import { AvailableProductDuration } from '../../models/available-product-duration.model';

export class ProductArrayHelper {

  /**
   * Fills the property ProductEnum inside all the available durations of all the provided products.
   * It returns a modified copy of the original provided array of products
   * @param {AvailableProduct[]} products
   * @returns {AvailableProduct[]} the modified products
   */
  public static fillProductEnumInDurations( products: AvailableProduct[] ): AvailableProduct[] {
    const productsToSort: AvailableProduct[] =  _.cloneDeep(products); // To avoid modifying the original value by reference
    productsToSort.forEach((product: AvailableProduct) => product.availableDurations
      .forEach((duration: AvailableProductDuration) => duration.value.productEnum = product.productEnum));
    return productsToSort;
  }

  /**
   * Sorting function which sorts the available products according to what configured in TenantSettings.productSortOrder
   * If one product isn't defined in the sortOrder array, will be placed leftmost.
   * @param {AvailableProduct} a
   * @param {AvailableProduct} b
   * @returns {number}
   */
  public static productsByWeightSorter( a: AvailableProduct, b: AvailableProduct ): number {
    return ArrayHelper.sorterByCustomOrder(TenantSettingsHelper.getSettings().productSortOrder)(a.productEnum, b.productEnum);
  }
}
