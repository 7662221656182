import { NgModule } from '@angular/core';
import { SebuFormComponentsModule } from '../../../form-components/sebu-form-components.module';
import { SharedModule } from '../../../shared/shared.module';
import { UiComponentsModule } from '../../../ui-components/ui-components.module';
import { CompanySummaryComponent } from './company-summary/company-summary.component';
import { HeaderWithButtonComponent } from './header-with-button/header-with-button.component';
import { OfferSummaryComponent } from './offer-summary/offer-summary.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { SummaryComponent } from './summary.component';
import { ProductSelectionModule } from '../product-selection/product-selection.module';
import { ReactiveFormsModule } from '@angular/forms';
import { StandardLayoutSummaryComponent } from './standard-layout-summary/standard-layout-summary.component';
import { CouponComponent } from './coupon/coupon.component';

@NgModule({
  imports: [
    SharedModule,
    ProductSelectionModule,
    ReactiveFormsModule,
    SebuFormComponentsModule,
    UiComponentsModule
  ],
  declarations: [
    HeaderWithButtonComponent,
    SummaryComponent,
    ProductSummaryComponent,
    CompanySummaryComponent,
    OfferSummaryComponent,
    StandardLayoutSummaryComponent,
    CouponComponent,
  ],
  exports: [
    SummaryComponent,
    ProductSummaryComponent,
    CompanySummaryComponent,
    OfferSummaryComponent,
    HeaderWithButtonComponent,
    StandardLayoutSummaryComponent
  ]
})
export class SummaryModule {}
