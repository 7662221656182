
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sebu-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public title: string;
  public message: string;
  public buttonConfirm: string;
  public buttonCancel: string;
  public buttonConfirmColor: string;
  public buttonCancelColor: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }
}
