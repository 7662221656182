import { CategoryData, SebuOfferOrderStatus, SebuResellerData } from '../../api/client';

export class StatusPageData {
  statusData: SebuOfferOrderStatus;
  allCategories?: CategoryData[];
  productName?: string;

  constructor(statusData: SebuOfferOrderStatus,
              allCategories?: CategoryData[],
              reseller?: SebuResellerData) {
    this.statusData = statusData;
    this.allCategories = allCategories;

    // Search reseller products for name
    this.productName = reseller.products.find(p => p.productId === statusData.product).resellerProductName;
  }
}
