import { Validators } from '@angular/forms';
import { ProductEnum } from '../../../api/client';
import { CategoriesControlConfiguration } from '../../../dynamic-forms/models/categories-control-configuration';
import { ControlConfiguration } from '../../../dynamic-forms/models/control-configuration';
import { FormControlNameEnum } from '../../../dynamic-forms/models/form-control-name.enum';
import { InputControlConfiguration } from '../../../dynamic-forms/models/input-control-configuration';
import { LocationControlConfiguration } from '../../../dynamic-forms/models/location-control-configuration';
import { TextareaControlConfiguration } from '../../../dynamic-forms/models/textarea-control-configuration';
import { CustomValidators } from '../../../shared/helper/custom-validation.helper';
import { TenantTextPipe } from '../../../tenant-texts/tenant-text.pipe';
import { ProductConfiguration, ProductDescription } from './product-configuration';


export class RegioOnlineOnlyProductConfiguration implements ProductConfiguration {
  public iconName = 'regio-online-only.svg';
  public productEnum: ProductEnum = ProductEnum.RegioOnlineOnlyStandardlayout;
  public description: ProductDescription = {
    descriptionHeading: 'regioOnlineOnlyDescriptionHeading',
    description: 'regioOnlineOnlyDescription',
    descriptionSubHeadline: 'regioOnlineOnlyDescriptionSubHeadline',
    descriptionWhatYouGet: 'regioOnlineOnlyDescriptionWhatYouGet',
    descriptionWhatYouGetSubText:'regioOnlineOnlyDescriptionWhatYouGetSubText'
  };
  public formConfiguration: Array<ControlConfiguration> = [
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.title,
      placeholder: TenantTextPipe.transform('offerOrderTitle') as string,
      inputType: 'text',
      validators: [CustomValidators.requiredNotBlank, CustomValidators.charset],
      maxLength: 255,
      displayWidth: 100
    }),
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.applicationEmail,
      placeholder: TenantTextPipe.transform('applicationEmail') as string,
      inputType: 'text',
      validators: [CustomValidators.sareEmail],
      maxLength: 100,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new InputControlConfiguration({
      formControlName: FormControlNameEnum.applicationUrl,
      placeholder: TenantTextPipe.transform('applicationUrl') as string,
      inputType: 'url',
      validators: [CustomValidators.charset, CustomValidators.url],
      maxLength: 2083,
      displayWidth: 49,
      displayMinWidth: '200px'
    }),
    new LocationControlConfiguration({
      formControlName: FormControlNameEnum.location,
      validators: [Validators.required],
      displayWidth: 49,
      displayMinWidth: '200px'
    }),

    new CategoriesControlConfiguration({
      formControlName: FormControlNameEnum.categories,
      displayWidth: 100,
      validators: [Validators.required]
    }),
    new TextareaControlConfiguration({
      formControlName: FormControlNameEnum.comment,
      placeholder: TenantTextPipe.transform('comment') as string,
      validators: [CustomValidators.charset],
      inputType: 'text',
      displayWidth: 100
    }),
  ];
}

